import { useContext, useEffect, useState } from "react";
import { CourseContext } from "./Courses";
import { MenuContext } from "../DashBoard";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../components/Select";
import styled from "styled-components";
import CategoriesCss from '../../../css/Categories.module.css';
import ResultCss from '../../../css/Result.module.css';
import { Button } from "../../../components/Button";
import { DisplayFile, UploadedFileOnly } from "../../../components/UploadedFiles";
import { addCourseResource, addCourseSection, deleteCourseResource, deleteCourseSection, editCourseResource, editCourseSection } from "../../../redux/reducers/resultReducer";
import { IoTrashOutline } from "react-icons/io5";
import restApi from "../../../helperFunctions/Api2";
import { FaClipboardList, FaPlus, FaRegTrashAlt } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";

export default function ManageCourses () {
    const { setTitle } = useContext(CourseContext);
    const { setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);
    const dispatch = useDispatch();
    const coursess = useSelector((state) => state.results.courses);
    const [courses, setCourses] = useState(coursess);
    const [selCourse, setSelCourse] = useState(null);


    function addSection () {
        const section = { title: '', summary: '', course_id: selCourse.id, resources: [] };

        setShowLoading(true);
        restApi.PostApi('/learning/add-course-section', section, true).then(result => {
            if (result.isSuccessful) {
                result.data = { ...section, ...result.data, resources: [] };
                dispatch(addCourseSection(result.data));
            } else {
                setModalText(result.message);
                setModalToggle(true);
            }
        }).catch(err => console.log(err)).finally(() => setShowLoading(false));
    }
    function delSection (section) {
        // console.log(section);

        setShowLoading(true);
        restApi.PostApi('/learning/delete-course-section', { id: section.id }, true).then(result => {
            if (result.isSuccessful) {
                dispatch(deleteCourseSection(section));
            }
            setModalText(result.message);
            setModalToggle(true);
        }).catch(err => console.log(err)).finally(() => setShowLoading(false));
    }
    useEffect(() => {
        setTitle('Manage Courses');
    }, [setTitle]);

    useEffect(() => {
        // setCourses(coursess.map(course => ({
        //     ...course,
        //     resources: [...files]
        // })), [coursess]);
        // console.log(coursess);
        if (selCourse && selCourse.id) {
            setSelCourse(coursess.find(course => course.id === selCourse.id));
        }
        setCourses(coursess);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coursess]);
    // useEffect(() => {
    //     console.log(selCourse);
    // }, [selCourse]);

    return <Resource className={CategoriesCss.form}>
        <label className={CategoriesCss.label}>Select Course
            <Select options={courses} label="title" value="id" onChange={setSelCourse} defaultValue={selCourse} />
        </label>
        {selCourse && selCourse.id ? <div style={{ width: '100%' }} >
            {selCourse.sections && selCourse.sections.map((section, index) => <Section key={index} section={section} delSection={delSection} />)}
            <button className={ResultCss.addColumnBtn} onClick={() => addSection()}><FaPlus />New Section</button>
        </div> : <p style={{ textAlign: 'center', width: '100%' }}>Please Select a Course</p>}
    </Resource>;

}
const Section = ({ section, delSection }) => {
    const [editing, setEditing] = useState(false);
    const [data, setData] = useState(section);
    const { setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);
    const dispatch = useDispatch();

    function editSection (section,) {
        const data = { course_id: section.course_id, id: section.id, title: section.title, summary: section.summary };
        // console.log(section, data);


        setShowLoading(true);
        restApi.PostApi('/learning/edit-course-section', data, true).then(result => {
            if (result.isSuccessful) {
                setEditing(false);
                dispatch(editCourseSection(section));
            }
            setModalText(result.message);
            setModalToggle(true);
        }).catch(err => console.log(err)).finally(() => setShowLoading(false));
    }


    return (
        <div className={ResultCss.section}>
            <span className={ResultCss.deleteBtn + ` ${ResultCss.deleteSection}`} onClick={() => delSection(section)}><FaRegTrashAlt />Delete Section</span>
            <label className={CategoriesCss.label} >
                <span>Title</span>
                <input placeholder='Heading' type='text' value={data.title} onInput={() => setEditing(true)} onChange={e => setData({ ...data, title: e.target.value })} className={CategoriesCss.input} />
            </label>
            <label className={CategoriesCss.label} >
                <span>Summary</span>
                <input placeholder='description' type='text' value={data.summary} onInput={() => setEditing(true)} onChange={e => setData({ ...data, summary: e.target.value })} className={CategoriesCss.input} />
            </label>
            <ManageResources section={section} />

            {editing && <Button style={{ margin: '0 auto', padding: '5px 10px' }} onClick={() => editSection(data)} >Save Changes</Button>}

        </div>
    );
};

function ManageResources ({ section }) {
    // const assignments = useSelector(state => state.learning.assignments);
    // const notes = useSelector(state => state.learning.notes);
    // .sort((a, b) => b.id - a.id)
    const { setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);
    const dispatch = useDispatch();

    /* function onDrag (index, e) {
        e.dataTransfer.setData('text/plain', index);
    }
    function onDrop (toIndex, e) {
        e.preventDefault();
        const fromIndex = parseInt(e.dataTransfer.getData('text'));
        const arr = section.resources;
        let newarray;

        if (fromIndex > toIndex) {
            newarray = [...arr.slice(0, toIndex), arr[fromIndex], ...arr.slice(toIndex, fromIndex), ...arr.slice(fromIndex + 1, arr.length)];
        } else {
            newarray = [...arr.slice(0, fromIndex), ...arr.slice(fromIndex + 1, toIndex + 1), arr[fromIndex], ...arr.slice(toIndex + 1, arr.length)];
        }

        // setSelCourse({ ...selCourse, resources: newarray });
        e.dataTransfer.clearData();
    } */
    function handleDelete (res) {
        const data = { id: res.id, course_id: section.course_id, section_id: section.id };

        setShowLoading(true);
        restApi.PostApi('/learning/delete-course-resource', data, true).then(result => {
            if (result.isSuccessful) {
                dispatch(deleteCourseResource(data));
            }
            setModalText(result.message);
            setModalToggle(true);
        }).catch(err => console.log(err)).finally(() => setShowLoading(false));
    }
    function handleEdit (res) {
        setModalToggle(true);
        setModalText(<AddFile setShowLoading={setShowLoading} setModalText={setModalText} resource={res} />);
    }

    return <div className="container">
        {/* {Boolean(section.resources && section.resources.length) && <small>drag to reorder items</small>} */}
        <div className="file-atc-cont">
            {Boolean(section.resources && section.resources.length) && section.resources.map((res, index) => <div className="file-atc-box" key={index}>
                {/* <div className="index">{index + 1}</div>
                <div className="file-cont" key={res.id} draggable onDragStart={e => onDrag(index, e)} onDrop={e => onDrop(index, e)} onDragOver={e => { e.preventDefault(); }} > */}
                <div className="file-cont" key={res.id} >
                    {res.resource_type[0] !== '$' ?
                        <DisplayFile fileurl={res.resource} /> : <OtherTypes res={res} />
                    }
                    <span className="icon" title='edit' onClick={() => handleEdit(res)}><BiEditAlt /></span>
                    <span className="icon" title='delete' onClick={() => handleDelete(res)}><IoTrashOutline /></span>
                </div>
            </div>)}

        </div>
        {section.id &&
            <button className={ResultCss.addColumnBtn} onClick={() => {
                setModalToggle(true); setModalText(<AddFile resource={
                    { section_id: section.id, course_id: section.course_id, resource: '', resource_type: types[1].type, resource_order: section.resources.length }
                } setShowLoading={setShowLoading} setModalText={setModalText} />);
            }} ><FaPlus />Add Resource</button>}
    </div>;
}

const Resource = styled.div`
.container{
    width: 98.5%;
    padding: 10px;
    margin: 5px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
}
.file-atc-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.file-atc-cont >.file-atc-box{
    width: 100%;
    display: flex;
    gap: 5px;
    ${'' /* cursor: grab; */}
    cursor: pointer;
    box-shadow: inset 0px 0px 6px -0px var(--border-color);
    align-items: stretch;
}
.index{
    position: relative;
    left: 5px;
    display: flex;
    align-items: center;
    cursor: default;
    font-size: 20px;
    font-weight: bold;
}
.file-cont{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 5px;
    .icon{
        padding: 5px;
        cursor: pointer;
        height: fit-content;
        :hover{
            color: var(--secondary-color);
        }
    }
}
.other-res{
display: flex;
align-items: center;
color: #475F7B;
padding: 5px;
border-radius: 5px;
width: 100%;

.file-image {
    width: 100px;
    height: 85px;
    background-size: cover;
    border-radius: 5px;
    margin-right: 5px;
    background-color: #eaecf1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    padding: 3px;
}
.file-detail {
    flex: 1;
    width: calc(100% - 210px);
    text-align: left;
    font-size: 12px;
}
.file-detail h6 {
    word-break: break-all;
    font-size: 13px;
    font-weight: 500;
    margin: 0 0 8px;
}
.file-detail p {
    color: #8194aa;
    margin: 0;
}
}
.file-atc-box{ cursor: default !important; }

`;
const types = [
    { type: 'audio', name: 'Audio' },
    { type: 'application|text|doc', name: 'Document' },
    { type: 'image', name: 'Image' },
    { type: 'video', name: 'Video' },
    { type: '$assignment', name: 'Assignment' },
    { type: '$notes', name: 'Notes' },
    { type: '$link', name: 'Links' },
];

const AddFile = ({ setShowLoading, setModalText, resource }) => {
    // const [file, setFile] = useState('');
    const [type, setType] = useState(types.find(typeobj => typeobj.type === resource.resource_type));
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();
    const assignments = useSelector(state => state.learning.assignments);
    const notes = useSelector(state => state.learning.notes);

    const handleFile = () => {
        if (!resource.resource || resource.resource === '{}') {
            setMessage('Please add a resource');
            return false;
        }
        // console.log(resource);
        setShowLoading(true);
        restApi.PostApi(resource.id ? '/learning/edit-course-resource' : '/learning/add-course-resource', resource, true)
            .then(result => {
                // console.log(result);
                setModalText(result.message);
                if (result.isSuccessful) {
                    dispatch(resource.id ? editCourseResource({ ...resource, ...result.data }) : addCourseResource(result.data));
                }
            })
            .catch(error => {
                setModalText(error.message);
            })
            .finally(() => {
                setShowLoading(false);
            });

    };
    function handleLinkInput (target, value) {
        let fileObj = {};
        if (resource.resource)
            fileObj = { ...JSON.parse(resource.resource) };
        handleChange({ resource: JSON.stringify({ ...fileObj, [target]: value }) });
    }
    function handleChange (changesObj) {
        setModalText(<AddFile setShowLoading={setShowLoading} setModalText={setModalText} resource={{ ...resource, ...changesObj }} />);
    }
    useEffect(() => {
        type.type === '$link' ?
            handleChange({ resource: JSON.stringify({ title: '', url: '' }), resource_type: type.type }) :
            type.type[0] === '$' ?
                handleChange({ resource: JSON.stringify({}), resource_type: type.type }) :
                handleChange({ resource: '', resource_type: type.type });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    useEffect(() => {
        // console.log(resource);
        setModalText(<AddFile setShowLoading={setShowLoading} setModalText={setModalText} resource={resource} />);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resource]);

    return <div>
        <strong>{message}</strong>
        <label className={CategoriesCss.label}>
            <span style={{ float: 'left' }}>Resource Type</span>
            <Select options={types} value="type" label="name" defaultValue={type} onChange={setType} />
        </label>
        {type.type[0] !== '$' ? <UploadedFileOnly setShowLoading={setShowLoading} onCopy={(v) => handleChange({ resource: v })} value={resource.resource} title="File" type={type} /> : type.type === '$assignment' ? <label className={CategoriesCss.label}>
            <span style={{ float: 'left' }}>Assignment</span>
            <Select options={assignments} defaultValue={resource.resource[0] === '{' ? JSON.parse(resource.resource) : ''} onChange={(o) => handleChange({ resource: JSON.stringify(o) })} value={null} label="title" />
        </label> : type.type === '$notes' ? <label className={CategoriesCss.label}>
            <span style={{ float: 'left' }}>Notes</span>
            <Select options={notes} defaultValue={resource.resource[0] === '{' ? JSON.parse(resource.resource) : ''} onChange={(o) => handleChange({ resource: JSON.stringify(o) })} value={null} label="title" />
        </label> : type.type === '$link' ? <div>
            <label className={CategoriesCss.label}>
                <span style={{ float: 'left' }}>Title</span>
                <input type='text' className={CategoriesCss.input} placeholder='' defaultValue={resource.resource[0] === '{' ? JSON.parse(resource.resource).title : ''} onChange={(e) => handleLinkInput('title', e.target.value)} />
            </label>
            <label className={CategoriesCss.label}>
                <span style={{ float: 'left' }}>Link</span>
                <input type='text' className={CategoriesCss.input} placeholder='https://' defaultValue={resource.resource[0] === '{' ? JSON.parse(resource.resource).url : ''} onChange={(e) => handleLinkInput('url', e.target.value)} />
            </label>
        </div> : <></>}
        <br /><Button onClick={handleFile}>{resource.id ? 'Edit' : 'Add'} Resource</Button>
    </div>;
};

const OtherTypes = ({ res }) => {
    const resource = JSON.parse(res.resource);
    const type = res.resource_type.slice(1);


    return <div className="other-res">
        <div className="file-image"><FaClipboardList /></div>
        <div className="file-detail">
            <h6>{resource.title}</h6>
            <p>Type : {type}</p>
        </div>
    </div>;
};