import React, { useState, useEffect,useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import headerCss from './css/header.module.css';
import LogoImg from './Logo_img';
import { BsSearch, BsColumnsGap as Ham, BsXLg as Cancel, BsXCircle as CancelSrarch } from 'react-icons/bs';
import {ModalContext,ScreenContext} from './Home';



const Header = () => {
  // const [smallScreen, setSmallScreen] = useState(false);
  // const [screenSize, setScreenSize] = useState(null);
  const [showHamContent, setShowHamContent] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [scrollScreen, setScrollScreen] = useState(false);
  const [xScroll, setXScroll] = useState(null);
  const userLoc = useLocation();
  const {modalObject,setModalObject,setModalToggle} = useContext(ModalContext);
  const {smallScreen,screenSize} = useContext(ScreenContext);

  const handleShowHamContent = () => {
    setShowHamContent(!showHamContent);
  };

  const handleShowSearch = () => {
    setShowSearch(!showSearch);
  };

  const showPriceModal = () =>{
      setModalObject({...modalObject,header:"Pricing Notice",body:<><span>All services pricing are been reviewed!</span><br/><span>Therefore you can enjoy all these services for free</span></>});
      setModalToggle(true);
  }

  const showComingSoon = (arg,arg2) =>{
      setModalObject({header:arg,body:<><span style={{fontSize:'25px'}}>{arg2}</span></>});
      setModalToggle(true);
  }

  // useEffect(() => {
  //   const handleResize = () => { setScreenSize(window.innerWidth); };

  //   window.addEventListener('resize', handleResize);
  //   handleResize();
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  // useEffect(() => {
  //   if (screenSize >= 768) {
  //     setSmallScreen(false);
  //     setShowHamContent(true);

  //   }},
  //   []);
  useEffect(() => {
    if (screenSize >= 768) {
      // setSmallScreen(false);
      setShowHamContent(true);
    }
    else {
      // setSmallScreen(true);
      setShowHamContent(false);

    }
  }, [screenSize]);

  useEffect(() => {
    const handleSroll = () => { setXScroll(window.pageYOffset); };
    window.addEventListener('scroll', handleSroll);
    handleSroll();
    return () => window.removeEventListener('scroll', handleSroll);
  }, []);

  useEffect(() => {
    if (xScroll >= 10) {
      setScrollScreen(true);
    }
    else {
      setScrollScreen(false);
    }
  }, [xScroll]);




  return (
    <React.Fragment>
      <header className={headerCss.header} onClick={()=> smallScreen && showHamContent && handleShowHamContent()}>
        <nav className={scrollScreen ? headerCss.darkNav : null} style={showHamContent && smallScreen ? { backgroundColor: "rgba(35, 47, 62,0.97)" } : { backgroundColor: "rgba(35, 47, 62,0)" }}>
          <div style={smallScreen ? (showSearch ? { zIndex: "1", width: "calc(100% - 50px)" } : { zIndex: "100", width: "calc(100% - 50px)" }) : (showSearch ? { zIndex: "1", width: "0px",display:"none" } : { zIndex: "100", width: "0px" ,display: "none"})} >
            {smallScreen ?
              <React.Fragment>
                {showHamContent ?
                  <Cancel className={headerCss.ham} onClick={handleShowHamContent} />
                  : <Ham className={headerCss.ham} onClick={handleShowHamContent} />
                }
                <Link to="/"><LogoImg smallScreen /></Link>
              </React.Fragment> : ""}
          </div>
          <div style={showSearch ? {  width: '100%',zIndex: "2",position:'fixed',height:'100%' } : showHamContent&&smallScreen?{ zIndex: "2", width: '100%',position:'fixed',height:'100%' }:{zIndex: "2", width: '100%',position:'relative',height:'auto'} } className={showHamContent&&smallScreen?headerCss.secondDiv:''}>
            {showHamContent ? <ul style={smallScreen?{position:'relative',height:'100%',width:'100%',backgroundColor:'rgba(35, 47, 62,0.99)'}:{}}>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/services" >Services</Link></li>
              <li><Link to='/pricing'>Pricing</Link></li>
             
              {!smallScreen ? <li><Link to="/"><LogoImg /></Link></li> : null}
              <li><Link to='/support#faq'>Doc & Support</Link></li>
              <li><Link to="/account/sign-up">Register</Link></li>
              <li><Link to="/account/sign-in">Log In</Link></li>
              {/* <li><span><BsSearch/></span></li> */}
            </ul>
              : null
            }
          </div>
         {/* <div className={headerCss.search_container} style={showSearch ? { zIndex: "100", width: "100%", backgroundColor: "rgba(35, 47, 62,0.97)" } : { zIndex: "100", backgroundColor: "transparent" }}>
            <div>
              {showSearch ?

                <React.Fragment>
                  <input type="text" placeholder="Search for service" className={headerCss.search_input} /><a href="/" className={headerCss.goBtn}>Go</a>
                </React.Fragment>

                : null}
              <React.Fragment >
                {showSearch ? <CancelSrarch className={headerCss.search_icon} onClick={handleShowSearch} />
                  : <BsSearch className={headerCss.search_icon} onClick={handleShowSearch} />}
              </React.Fragment>


            </div>
            <div>
              <div >

              </div>
            </div>
          </div>*/}

        </nav>
      </header>

    </React.Fragment>
  );
};

export default Header;