import { useContext, useEffect, useState } from "react";
import css from './css/view-cbt.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { convertDatetoString } from "../../../helperFunctions/Api2";
import { CbtModalContext } from "./cbt";
import Api from '../../../helperFunctions/Api2';
import { deleteCbt } from "../../../redux/reducers/cbtReducer";
import Loading from '../../../components/loading';
import { useNavigate } from "react-router-dom";
import { EditComponent } from "../../../components/EditComponent";
import styled from "styled-components";
import PageNumber from '../../../components/PageNumber';
import { TbExternalLink as ExternalLinkIcon } from "react-icons/tb";
import { BiReset } from "react-icons/bi";

const NO_OF_ITEMS_PER_PAGE = 30;

const ViewCbt = ({ setTitle }) => {
    const allCbts = useSelector(store => store.cbt && store.cbt.cbts ? Array.from(store.cbt.cbts) : []);
    const [menuIndex, setMenuIndex] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);

    useEffect(() => {
        setTitle("View CBT");
    }, [setTitle])
    useEffect(() => {
        if (allCbts.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((allCbts.length / NO_OF_ITEMS_PER_PAGE) + ((allCbts.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
        }
    }, [allCbts.length]);
    return (
        <>
            <div className={css.container}>
                <div></div>
                <div className={css.displayContainer}>
                    {allCbts && allCbts.length > 0 ?
                        <>
                            {allCbts.reverse().map((eachCbt, index) =>
                                index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
                                <EachCbt key={eachCbt.id} eachCbt={eachCbt} index={index} onChange={setMenuIndex} menuIndex={menuIndex} setShowLoading={setShowLoading} />)}
                        </> :
                        <>
                            <p style={{textAlign: 'center'}}>No CBT created yet!</p>
                        </>}
                </div>
                {allCbts.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

            </div>
            <Loading shouldShow={showLoading} />
        </>

    )
}
export default ViewCbt;


export const EachCbt = ({ eachCbt, menuIndex, onChange, index, setShowLoading }) => {

    // const [tempShow,setTempShow] = useState(menuIndex == index?true:false);
    const categories = useSelector(store => store.questions.categories);
    //console.log(categories);
    const { setModalTogglee, setModalObject, setEditObject } = useContext(CbtModalContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const paymentLinks = useSelector(store => store.account.paymentLinks);
    const forms = useSelector(store => store.portal.forms);


    const handleDeleteBtn = () => {
        setModalObject({
            header: <>Confirmation Message</>,
            body: <>
                <p>Are you sure you want to delete this CBT. This action will delete the records associated with this CBT too.</p>

            </>,
            footer:
                <div>
                    <button onClick={() => setModalTogglee(false)}>Cancel</button>
                    <button onClick={() => { handleDelete() }}>Delete CBT</button>
                </div>
        });
        setModalTogglee(true);
    }

    const handleCategories = (cat) => {
        let arr = []
        cat.forEach(eCate => {
            let id = eCate.category_id;
            let check = categories.find((eCategory) => {
                return eCategory.id === id
            })
            if (check) {
                arr.push(check.name)
            }
        })
        return arr;
    }

    const handleDelete = () => {
        if (!Api.checkNetwork()) return false;

        setShowLoading(true)
        console.log({ id: eachCbt.id })
        Api.PostApi('/test/delete-cbt', { id: eachCbt.id }, true)
            .then(result => {
                setShowLoading(false);
                setModalObject({ header: 'Message', body: result.message, footer: '' });
                setModalTogglee(true);
                console.log(result, "Server result")
                if (result.isSuccessful) {
                    dispatch(deleteCbt({ id: eachCbt.id }));
                }
            })
            .catch(error => {
                setShowLoading(false);
                console.log(error, "Server Error");
            })
    }

    const handleEdit = () => {
        setEditObject(eachCbt);
        navigate('../create-cbt');
    }

    const handleAdminView = (str) =>{
        setShowLoading(true);
        Api.getApi(`/test/check-supervisor/${str}`,true)
        .then(result =>{
            setShowLoading(false);
            console.log(result,'result');
            if(result.isSuccessful){
                setModalObject({ header: '', body: result.message, footer: '' });
                setModalTogglee(true);
                window.open(`https://cbt.sight-eduplus.com/admin/${result.data}`,'_blank');
            }else{
                setModalObject({ header: '', body: result.message, footer: '' });
                setModalTogglee(true);
            }
            

        })
        .catch(error =>{
            setShowLoading(false);
            console.log(error,"Server Error");
        })
        
    }
    const handleReset = (id) => {
        setShowLoading(true);
        Api.PostApi(`/test/resend-cbt-details`, {id}, true)
        .then(result =>{
            setShowLoading(false);
            console.log(result,'result');
            if(result.isSuccessful){
                setModalObject({ header: '', body: result.message, footer: '' });
                setModalTogglee(true);
            }else{
                setModalObject({ header: '', body: result.message, footer: '' });
                setModalTogglee(true);
            }
        })
        .catch(error =>{
            setShowLoading(false);
            console.log(error,"Server Error");
        })
        
    }

    const More = <>{eachCbt.adminLink ?<div onClick={()=>handleAdminView
    (eachCbt.adminLink)}>
        <ExternalLinkIcon/> CBT Panel
    </div>:<></>}
    <div onClick={()=>handleReset(eachCbt.id)} title="Resend CBT details to all users" ><BiReset /> Resend CBT Details</div>
    </>
    // const handleShowMenu = () =>{
    //     if(menuIndex == index){
    //         onChange(null);
    //         setTempShow(false);
    //         return;
    //     }
    //     onChange(index);
    //     setTempShow(true);
    // }
    //console.log(eachCbt.start_time+ " "+ eachCbt.end_time);
    return (
        <div>

            <EditComponent more={More} clickEdit={handleEdit} clickDelete={handleDeleteBtn} index={index} menuIndex={menuIndex} setMenuIndex={onChange} />
            <div className={css.infoDiv}>
                <div>
                    <span className={css.value2}>Title: <span className={css.value}>{eachCbt.title}</span></span>
                    <span className={css.createdSpan}>created: {eachCbt.created_at ? convertDatetoString(eachCbt.created_at) : 'July 24 ,2023'}</span>
                </div>
                <div>
                    <span>Total Questions: </span><span className={css.value}>{eachCbt.total_questions}</span>
                </div>
                <div>
                    <span>Total Sections: </span><span className={css.value}>{eachCbt.total_categories}</span>
                </div>

                <div>
                    <span>Start Date: </span>
                    <span className={css.value}>{convertDatetoString(eachCbt.start_time, true)}</span>
                </div>
                <div>
                    <span>End Date: </span>
                    <span className={css.value}>{convertDatetoString(eachCbt.end_time, true)}</span>
                </div>
                <div>
                    <span>Total Canditates: </span>
                    <span className={css.value}>{eachCbt.users_num}</span>
                </div>
                {eachCbt.form_id && <div>
                    <span>Form: </span>
                    <span className={css.value}>{forms.find(form => form.id === eachCbt.form_id)?.title}</span>
                </div>}
                {eachCbt.payment_link_id && <div>
                    <span>Payment Link: </span>
                    <span className={css.value}>{paymentLinks[eachCbt.payment_link_id]?.title}</span>
                </div>}
           
                <CategoryWrapper>
                    <span>Question Categories: </span>

                    {handleCategories(eachCbt.categories).map((eCate, ind) =>
                        <span key={ind}>{eCate}</span>
                    )}


                </CategoryWrapper>

            </div>

        </div>
    )
}

const CategoryWrapper = styled.div`
    width:100%;
    display:flex;
    gap:10px;
    flex-wrap: wrap;
    align-items: center;
    /* flex-direction: column; */
    /* & > div{
        width:100%;
        display: flex;
        gap:10px;
    } */
    & > span:not(:first-of-type){
        border:1px solid var(--secondary-color);
        padding:5px 15px;
        border-radius: 15px;
    }

`