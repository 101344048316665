import { useState, useEffect, useContext } from 'react';
import styles from '../../../css/StaffMgmt.module.css';
import titleStyle from '../../../css/SectionTitle.module.css';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import { MenuContext } from '../DashBoard';
import HTMLReactParser from 'html-react-parser';
import { useSelector } from 'react-redux';
import { BiMenu, BiX } from 'react-icons/bi';
import defImg from '../../../images/default-img.png';
import { Button } from '../../../components/Button';
import { IoLibraryOutline } from 'react-icons/io5';
import { SiGoogleclassroom } from 'react-icons/si';
import { TbBook2 } from 'react-icons/tb';


const LearningMarket = () => {

    const { setModalToggle, setModalText, } = useContext(MenuContext);
    const [searchText, setSearchText] = useState("");
    const data = useSelector(store => store.learning.marketData);
    const [selKey, setSelKey] = useState("All");
    const [user_data, setUserData] = useState([]);
    const [categories, setCategories] = useState(user_data);
    const [navtoggle, setNavtoggle] = useState(false);

    function setData (data) {
        Object.keys(data).length > 0 ? setUserData(Object.entries(data).map(categoryArr => categoryArr[1].map(cat => ({ ...cat, catKey: categoryArr[0] }))).reduce(((previous, current) => [...previous, ...current]))) : setUserData([]);
    }
    useEffect(() => {
        data && setData(data);
        console.log(data);
    }, [data]);

    useEffect(() => {
        // console.log(user_data);
        setCategories(user_data);
    }, [user_data]);

    useEffect(() => {
        if (data && selKey !== 'All') {
            setUserData(data[selKey]);
        } else data && setData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selKey, data]);

    useEffect(() => {
        //Handle Search
        if (searchText) {
            /* const newcategories = user_data.filter(category => (
                (category.name && category.name.toLowerCase().includes(searchText.toLowerCase()))
                || (category.title && category.title.toLowerCase().includes(searchText.toLowerCase()))
                || (category.description && category.description.toLowerCase().includes(searchText.toLowerCase()))
            )); */
            const newcategories = user_data.filter(category => Object.values(category).some(value => String(value).toLowerCase().includes(searchText.toLowerCase())));
            setCategories(newcategories);
        } else setCategories(user_data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, user_data]);


    const readMore = (category) => {
        setModalText(<EachCategory category={category} key={category.id} />);
        setModalToggle(true);
    };
    return (
        <>
            <div className={`${styles.baseView} full-port`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <h1 className={`${styles.title}`}>Learning Marketplace</h1>
                <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                            <Search>
                                <FaSearch />
                                <input placeholder="Search" type='text' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                            </Search>
                        </h3>
                    </div>
                    <Container>
                        <ul className={`navbar ${navtoggle && 'show'}`} onClick={() => setNavtoggle(!navtoggle)}>
                            <div className='navtoggle'>{navtoggle ? <BiX /> : <BiMenu />}</div>
                            <li className={`navitem ${selKey === 'All' && 'active'}`} onClick={() => setSelKey('All')}>All</li>
                            {data && Object.keys(data).map(item => <li className={`navitem ${selKey === item && 'active'}`} onClick={() => setSelKey(item)} key={item}>{item}</li>)}
                        </ul>
                        <div className='container'>
                            {categories.length ? categories.map((category, ind) => <EachCategory category={category} key={ind} readMore={readMore} />) : 'None found'}
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
};
const Search = styled.div`
padding: 4px 9px;
border: 1px solid var(--border-color);
background-color: white;
max-width: 100%;
width: 100%;
border-radius: 5px;
transition: .2s;
display: flex;
align-items: center;

input{
    padding-left: 10px;
    width: 100%;
    border: none;
    height: auto;
}
svg{
    font-size: 20px;
    color: var(--grey-text-color);
}
`;

const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
margin-top: -18px;

.container{
display: flex;
flex-wrap: wrap;
align-items: stretch;
//justify-content: space-evenly;
width: 100%;
gap: 20px;
padding: 10px;
}

.navbar{
    display: flex;
    justify-content: center;
    gap: 2px;
    list-style: none;
    margin: 0 0 20px;
    padding: 0;
    position: relative;
    width: 100%;
}

.navitem{
    padding: 13px 25px;
    width: fit-content;
    border-radius: 8px 8px 0 0;
    color: var(--textDark);
    cursor: pointer;

    &.active, &:hover {
        background-color: #f1f1f1;
        color: var(--primary-color);
    }
}
.navtoggle {
    position: absolute;
    right: 12px;
    top: 2px;
    font-size: 30px;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    display: none;
    cursor: pointer;
}

@media only screen and (max-width: 420px) {
    .navbar {
        flex-direction: column;
        background-color: #fcfcfc;
    }
    .navitem{
        width: 100%;
        border-radius: 0;
        &:not(.active){
            display: none;
        }
    }
    .navbar.show{
        border-bottom: 1px solid var(--border-color);
        >.navitem{display: unset;}
    }
    .navtoggle{
        display: inline;
    }
}
`;
const Category = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
    border-radius: 5px;
    box-shadow: -2px -2px 3px rgba(240,240,240,0.5),
    2px 2px 3px rgba(70,70,70,0.12);
    width: 100%;
    max-width:300px;
    min-width:180px;
    text-align: left;
    padding: 0px;
    position: relative;
    font-size: 14px;
    @media screen and (max-width: 500px) {
        width: 90%;
        max-width: 100%;
    }
    * {
        margin: 0em;
        transition: 0.4s;
        line-height: 0.9;
    }

    &:hover{
        box-shadow: rgb(116 178 182 / 50%) 0px 0px 8px, rgba(70, 70, 70, 0.12) 2px 2px 3px;
    }
    h1{
        font-size: 25px;
    }
    img {
        margin: 0;
        width: 100%;
        height: 100%;
        font-size: 0px;
    }
    video{
        width: 90%;
        margin: auto;
        display: block;
    }
    i {
        overflow-wrap: break-word;
        margin: 0em;
        font-style: normal;
        color: var(--primary-color);
    }
    .note{
        display: flex;
        flex-direction: column;
    }
    .notetext {
        width: 100%;
        background-color: #fff;
        color: #000;
        border-radius: 6px;
        border: 1px solid var(--border-color);
        ${'' /* box-shadow: rgb(116 178 182 / 50%) 0px 0px 8px, rgba(70, 70, 70, 0.12) 2px 2px 3px; */}
        padding: 4px;
    }
    .tag{
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    .content{
        padding: 7px 15px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .icon{
        position: absolute;
        right: 2px;
        top: 2px;
        font-size: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 0.6;
        color: var(--light-mode-text-color);
    }
    .button{
        padding: 3px 5px;
        border-radius: 3px;
        margin: 3px 0;
        :disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }
    &.fullscreen{
    max-width: 100%;

    }
`;

const EachCategory = ({ category, readMore }) => {
    return <Category className={!readMore ? 'fullscreen' : ''}>
        <div className='tag'>
            <img src={category.image || defImg} onError={e => e.target.src = defImg} alt={'icon'} />
            <div className='icon'>
                {category.catKey === 'courses' ? <IoLibraryOutline />
                    : category.catKey === 'notes' ? <TbBook2 />
                        : category.catKey === 'liveclass' ? <SiGoogleclassroom /> : <></>}
                <small style={{ display: 'block', fontSize: '12px' }}>{category.catKey}</small>
            </div>
        </div>
        <div className='content'>
            <h1>{category.title}</h1>
            <div><i>Author:</i> {category.author}</div>
            <b>{`${category.payment.amount} ${category.payment.currency}`}</b>
            {!readMore && <>
                {category.duration && <p><i>Duration: </i>{category.duration} Mins</p>}
                {category.note && <div className='note'>
                    <i>Note:</i> <div className='notetext'>{readMore && category.note ? (category.note.length < 300 ? HTMLReactParser(category.note) : HTMLReactParser(category.note).slice(0, 300)) : HTMLReactParser(category.note)}</div>
                </div>}
                {category.code && <p><i>Code:</i> {category.code}</p>}
                {category.unit && <p><i>Unit:</i> {category.unit}</p>}
                {category.preview_url && <video src={category.preview_url} onError={e => e.target.style.display = 'none'}>Video Not Available</video>}
            </>}
            <Button className='button'>Get Now</Button>
        {readMore && <span onClick={() => readMore(category)} style={{ color: 'var(--secondary-color)', cursor: 'pointer', }}>see more...</span>}
        </div>
    </Category>;
};

export default LearningMarket;


