import { useContext, useEffect, useState } from "react";
import styles from '../../../css/Categories.module.css';
import Select from "../../../components/Select";
import { useDispatch, useSelector } from "react-redux";
import { MenuContext } from "../DashBoard";
import PageNumber from '../../../components/PageNumber';
import restApi, { convertDatetoString } from "../../../helperFunctions/Api2";
import SideOption from "../../../components/SideOption";
import { deleteInvites } from "../../../redux/reducers/questionReducer";


const ViewInvites = ({ setTitle }) => {
    const { selected, changeSelected, setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);
    const institutions = useSelector(state => state.questions.user_institutions);
    const dispatch = useDispatch();

    const [selInst, setSelInst] = useState({});
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);

    const handleDelete = (user) => {
        if (window.confirm('Are you sure you want to delete this invitation?')) {
            // console.log(user)
            setShowLoading(true);
            restApi.PostApi('/institution/delete-invitation', user, true)
                .then(result => {
                    if (result.isSuccessful) {
                        dispatch(deleteInvites(user));
                        setModalText('Deleted Invitation Succesfully');
                    }
                    else {
                        setModalText(result.message);
                    }
                })
                .catch(() => {
                    setModalText('Not Successful, Please try again');
                }).finally(() => {
                    setModalToggle(true);
                    setShowLoading(false);
            });
        }
    };

    /*useEffect(() => {
        setTitle('View Invites');
        setModalText('Refresh page to view new invitations');
        setModalToggle(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTitle]);*/
    useEffect(() => {
        let a = selInst ? institutions.filter(institution => institution.id === selInst.id)[0] : {};
        a && setSelInst(a);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [institutions]);

    useEffect(() => {
        if (selInst.id) {
            setUsers(selInst.invitations || []);
        }
    }, [selInst]);

    useEffect(() => {
        if (users.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((users.length / NO_OF_ITEMS_PER_PAGE) + ((users.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0));
        }
    }, [users.length]);

    return <div className={styles.form}>
            <label className={styles.label}>
                <span>Select institution</span>
                <Select onChange={setSelInst} options={institutions} className={styles.fields} label='name' value='id' defaultValue={selInst.id && selInst} />
            </label>
        <div className={styles.viewQuestionContainer}>
            {selInst.id ? users && users.length > 0 ? users.map((user, index) =>
                <EachUser user={user} key={'user' + index} index={index} selected={selected} changeSelected={changeSelected} currentPage={currentPage} handleDelete={handleDelete} />)
                : "No pending invite available!": "Please Select an Institution"}
        </div>
        {users.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

    </div>;
};
const NO_OF_ITEMS_PER_PAGE = 30;
export default ViewInvites;

function EachUser ({ user, index, selected, changeSelected, currentPage, handleDelete
}) {
    const [showUser, setShowUser] = useState(false)

    useEffect(() => {
        if (index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE)) {
            setShowUser(true);
        }
    }, [index, currentPage])
    return <>{showUser && <div className={styles.eachQuestion} >
        <div>
            <div>Email:<span style={{color: "var(--primary-color)"}}> {user.email}</span>
            </div>
            <div>Created:<span style={{color: "var(--primary-color)"}}> {convertDatetoString(user.created_at)}</span>
            </div>
        </div>
        <SideOption element={user} index={index} changeSelected={changeSelected} handleDelete={handleDelete} showOption={selected === index} />
    </div>}</> 
    
}