import { useEffect, useRef, useState, useContext } from 'react';
import style from './../../../css/ManualAdd.module.css';
import styles from './../../../css/CreateInst.module.css';
import { BsCheck2Circle, BsCircle, BsEye, BsEyeSlash } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../../components/Select/index';
import { addMultipleUsers, addUser, editUser } from '../../../redux/reducers/questionReducer';
import { EditContext } from './StaffMgmt';
import Api from "../../../helperFunctions/Api2";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Modal from '../../../components/modal';
import Loading from '../../../components/loading';
import Base64FileInput, { base64ToFile } from '../../../helperFunctions/base64';
import * as XLSX from 'xlsx';
import { FaTrashAlt } from 'react-icons/fa';

const cell = { key: '', value: '' };
const AddUser = ({ setTitle }) => {
    const [resetPassword, setResetPassword] = useState(false);
    const [sendCredential, setSendCredential] = useState(false);
    useEffect(() => {
        setTitle('Add User');
    }, [setTitle]);

    const institutions = useSelector(state => state.questions.user_institutions);
    const [currentUserCategoryArr, setCurrentUserCatergoryArr] = useState([]);

    const
        imgInput = useRef(),
        imgView = useRef(),
        firstName = useRef(),
        lastName = useRef(),
        middleName = useRef(),
        gender = useRef(),
        [email, setEmail] = useState(''),
        [phoneNumber, setPhoneNumber] = useState(''),
        login = useRef()
        ;

    const message2 = `Please completely fill out all required fields`;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { editingUser, editUserData, clearEdit } = useContext(EditContext);

    const [manual, set_manual] = useState(true);
    // const [inputs, setInputs] = useState({});
    const [valPhone, setValPhone] = useState(false);
    const [valEmail, setValEmail] = useState(false);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [selInst, setSelInst] = useState(null);
    const [selCategory, setSelCategory] = useState([]);
    const [defValue, setDefValue] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [userId, setUserId] = useState('');
    const [file, setFile] = useState(null);
    const [dob, setDob] = useState('');
    const [customFields, setCustomFields] = useState([JSON.parse(JSON.stringify(cell))]);

    const clearFields = () => {
        imgInput.current.value = '';
        imgView.current.style.backgroundImage = '';
        firstName.current.value = '';
        lastName.current.value = '';
        middleName.current.value = '';
        gender.current.value = '';
        if (login.current && login.current.value) login.current.value = '';
        setEmail('');
        setPhoneNumber('');
        setValEmail(false);
        setDefValue([]);
        setSelInst({});
        setSelCategory([]);
        setUserId('');
        setCustomFields([JSON.parse(JSON.stringify(cell))]);
        setDob('');
    };

    useEffect(() => {
        if (editingUser) {
            // console.log(editUserData)
            firstName.current.value = editUserData.firstName;
            lastName.current.value = editUserData.lastName;
            middleName.current.value = editUserData.middleName || '';
            gender.current.value = editUserData.gender;
            let inst = institutions.filter(inst => inst.id === editUserData.institution_id)[0];
            setSelInst(inst);
            setDefValue(Object.values(inst.categories).filter(cat => editUserData.categories.includes(cat.id)));
            setSelCategory(defValue);
            // login.current.value = editUserData.login;
            imgView.current.style.backgroundImage = editUserData.image ? `url(http://api.sight-eduplus.com/upload/subuser/${editUserData.image})` : ``;
            setEmail(editUserData.email);
            setPhoneNumber(editUserData.phoneNumber);
            // setValPhone(true);
            // setValEmail(true);
            // setResetPassword(editUserData.resetPassword)
            // setSendCredential(editUserData.sendCredential)
            setUserId(editUserData.user_id);
            editUserData.custom_fields && setCustomFields(Object.entries(editUserData.custom_fields).map(([key, value]) => ({key, value})));
            setDob(editUserData.dob || '');

        } else {
            manual && clearFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editingUser, editUserData, institutions]);

    const addImage = () => {
        // imgView.current.style.backgroundImage = `url(${URL.createObjectURL(imgInput.current.files[0])})`;
        imgView.current.style.backgroundImage = `url(${URL.createObjectURL(base64ToFile(imgInput.current.files[0]))})`;
    };

    const onInputChange = (name, value) => {
        // setInputs(inputs => ({ ...inputs, [name]: value }));

        if (name === 'email')
            // eslint-disable-next-line no-useless-escape
            (/^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/.test(value)) ?
                setValEmail(true) : setValEmail(false);

        if (name === 'phoneNumber') {
            (/^\+*\d{1,3}\d{9,10}$/.test(value)) ?
                setValPhone(true) : setValPhone(false);
        }
    };

    const handleCancel = () => {
        clearEdit();
        navigate('../users');
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(selCategory, selInst);

        if (firstName.current.value === '' ||
            lastName.current.value === '' ||
            gender.current.value === 'undefined' ||
            !gender.current.value ||
            selInst.id === 'undefined' ||
            !selInst ||
            !selCategory ||
            selCategory.length < 1 ||
            !valEmail ||
            !valPhone ||
            !dob

        ) {
            // alert('Forbidden!');
            setModalText(message2);
            setModalToggle(true);
            return;
        }
        if (!Api.checkNetwork()) return;
        setShowLoading(true);

        // Prepare Custom Fields
        const custom_fields = {}
        for (const field of customFields) {
            custom_fields[field.key.trim()] = field.value.trim();
        }

        if (editingUser) {
            // if (!Api.checkNetwork()) return;
            // setShowLoading(true);
            const users = new FormData();
            const categoriesId = selCategory.map(cat => cat.id);
            users.append("firstName", firstName.current.value);
            users.append("lastName", lastName.current.value);
            users.append("middleName", middleName.current.value);
            users.append("gender", gender.current.value);
            //users.append("institution_id", selInst.id);
            //users.append("categories", JSON.stringify(categoriesId));
            users.append("file", imgInput.current.files[0]);
            users.append("email", email);
            users.append("phoneNumber", phoneNumber);
            //users.append("reset_password",resetPassword ? 1:0);
            //users.append("send_user_credentials",sendCredential?1:0);
            users.append('custom_fields', JSON.stringify(custom_fields));
            users.append('dob', dob);
            users.append('user_id', userId);
            users.append('id', editUserData.id);
            Api.PostFormData('/institution/edit-user', users, true)
                .then(result => {
                    setModalText(result.message);
                    setModalToggle(true);
                    if (result.isSuccessful) {

                        const user = {
                            ...editUserData,
                            firstName: firstName.current.value,
                            lastName: lastName.current.value,
                            middleName: middleName.current.value,
                            gender: gender.current.value,
                            institution_id: selInst.id,
                            categories: selCategory.map(cat => cat.id),

                            // file: imgInput.current.files[0],
                            email,
                            phoneNumber,
                            // resetPassword:resetPassword,
                            // sendCredential:sendCredential,
                            custom_fields,
                            dob,
                            user_id: userId,
                        };
                        if (result.data.image)
                            user.image = result.data.image;
                        dispatch(editUser(user));
                        setTimeout(() => navigate('../users'), 5000);
                        clearFields();
                        clearEdit();
                    }
                })
                .finally(() => {
                    setShowLoading(false);
                });
        }
        else {
            const users = new FormData();
            const categoriesId = selCategory.map(cat => cat.id);
            users.append("firstName", firstName.current.value);
            users.append("lastName", lastName.current.value);
            users.append("middleName", middleName.current.value);
            users.append("gender", gender.current.value);
            users.append("institution_id", selInst.institution_id);
            users.append("categories", JSON.stringify(categoriesId));
            users.append("password", login.current.value);
            users.append("file", imgInput.current.files[0]);
            users.append("email", email);
            users.append("phoneNumber", phoneNumber);
            users.append("reset_password", resetPassword ? 1 : 0);
            users.append("send_user_credentials", sendCredential ? 1 : 0);
            users.append('custom_fields', JSON.stringify(custom_fields));
            users.append('dob', dob);
            users.append('user_id', userId);
            Api.PostFormData('/institution/add-user', users, true)
                .then(result => {
                    console.log(result);
                    setModalText(result.message);
                    setModalToggle(true);
                    if (result.isSuccessful) {
                        result.data.institution_id = selInst.institution_id;
                        result.data.categories = categoriesId;
                        dispatch(addUser(result.data));
                        clearFields();
                    }

                })
                .catch(error => {
                    setModalText('Unable to add User, please try again');
                    setModalToggle(true);
                })
                .finally(() => {
                    setShowLoading(false);
                });
            // alert('Staff Information Accepted!');
            // Begin process;
        }

    };
    const handleMultipleAdd = (e) => {
        e.preventDefault();
        if (!file || !selInst || selCategory.length < 1 || !selCategory) {
            setModalText(message2);
            setModalToggle(true);
            return;
        }
        let payload = {
            institution_id: selInst.institution_id,
            users: [],
            categories: [],
            send_user_credentials: sendCredential ? 1 : 0
        };
        const reader = new FileReader();
        reader.onload = (e) => {
            const workbook = XLSX.read(e.target.result, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            let json = XLSX.utils.sheet_to_json(worksheet);
            payload.users = json.map(user => {
                user = Object.fromEntries(
                    Object.entries(user).map(([k, v]) => [k.trim().toUpperCase(), v.trim()])
                );
                let customFields = { ...user };
                payload.categories.push(selCategory.map(cat => cat.id));

                delete customFields["USER ID"];
                delete customFields["FIRST NAME"];
                delete customFields["MIDDLE NAME"];
                delete customFields["LAST NAME"];
                delete customFields["GENDER"];
                delete customFields["PASSWORD"];
                delete customFields["EMAIL"];
                delete customFields["PHONE NUMBER"];
                delete customFields["DOB"];
                delete customFields["RESET PASSWORD"];
                return {
                    user_id: user["USER ID"],
                    firstName: user["FIRST NAME"],
                    middleName: user["MIDDLE NAME"],
                    lastName: user["LAST NAME"],
                    gender: user["GENDER"],
                    password: user["PASSWORD"],
                    email: user["EMAIL"],
                    phoneNumber: user["PHONE NUMBER"],
                    dob: user["DOB"],
                    reset_password: user["RESET PASSWORD"],
                    custom_fields: JSON.stringify(Object.fromEntries(
                        Object.entries(customFields).map(([k, v]) => [k.toLowerCase, v])
                    ))
                };
            });
            setShowLoading(true);
            Api.PostApi('/institution/add-multiple-users', payload, true)
                .then(result => {
                    // console.log(result)
                    setModalText(result.message);
                    setModalToggle(true);
                    if (result.isSuccessful) {
                        dispatch(addMultipleUsers({ users: Object.values(result.data), categories: payload.categories[0], institution_id: payload.institution_id }));
                    }

                })
                .catch(error => {
                    setModalText('Unable to add Users, please try again');
                    setModalToggle(true);
                })
                .finally(() => {
                    setModalToggle(true);
                    setShowLoading(false);
                });
        };
        reader.readAsBinaryString(file);
    };
    const handleInput = (value, key, rowIndex) => {
        value = key === 'key' ? value.toLowerCase() : value;
        let data = [...customFields];
        data[rowIndex][key] = value;
        setCustomFields(data);
    };
    const addRow = () => {
        let data = customFields;


        data = [
            ...data.slice(0, customFields.length),
            JSON.parse(JSON.stringify(cell)),
            ...data.slice(customFields.length),
        ];
        setCustomFields(data);
    };
    const deleteRow = (row) => {
        let data = customFields;
        if (data.length <= 1)
            return;
        data = data.filter((ro, index) => index !== row);
        setCustomFields(data);
    };
    function allowDrop (ev) {
        ev.preventDefault();
    }

    function drag (row, ev) {
        ev.dataTransfer.setData("text", row);
    }

    function drop (row, ev) {
        ev.preventDefault();
        const from = parseInt(ev.dataTransfer.getData("text"));

        let body = [...customFields].filter((r, ri) => ri !== from);
        body = [...body.slice(0, row), { ...customFields[from] }, ...body.slice(row)];
        setCustomFields(body);
    }
    useEffect(() => {
        if (selInst) {
            const cat = [];
            for (let x in selInst.categories) cat.push(selInst.categories[x]);
            setCurrentUserCatergoryArr(Object.values(cat));
        }

    }, [selInst]);
    // useEffect(() => {
    //     const categoriesId = selCategory&& selCategory.map(cat => cat.id);
    //     console.log(categoriesId);
    // }, [selCategory])
    useEffect(() => {
        onInputChange('phoneNumber', phoneNumber);
    }, [phoneNumber]);
    useEffect(() => {
        onInputChange('email', email);
    }, [email]);

    return (
        <>
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <Loading shouldShow={showLoading} />
            <div className={style.mainView}>
                {!editingUser && <div className={`${style.switchDiv}`} >
                    <button className={style.switch} onClick={() => set_manual(!manual)}>{manual ? 'Add Users From Excel Document' : 'Add User Manually'}
                    </button>
                </div>}
                <div className={`${style.subView}`}>
                    {manual &&
                        <div className={`${styles.form}`}>
                            <div className={`${styles.label}`}>
                                Image(max 300kb)
                                <div className={`${style.preview}`} ref={imgView} />
                                <label htmlFor='image' className={`${styles.label}`}>
                                    <Base64FileInput placeholder='' id='image' className={`${styles.uploadBtn}`} type='file' ref={imgInput} onChange={addImage} accept="image/*" name='image' />
                                </label>
                            </div>
                            <label className={styles.label}>
                                <span className={styles.isRequired}>User institution</span>
                                {editingUser ? <Select name='institution' options={institutions} onChange={setSelInst} className={styles.fields} label='name' value='institution_id' defaultValue={selInst} disabled />
                                    : <Select name='institution' options={institutions} onChange={setSelInst} defaultValue="" className={styles.fields} label='name' value='institution_id' />}
                            </label>
                            {selInst && <label className={`${styles.label}`}>
                                <span className={styles.isRequired}>User Group</span>
                                {editingUser ? <Select name='instCategory' options={currentUserCategoryArr} multi onChange={setSelCategory} className={styles.selectFields} defaultValue={defValue} label='name' value='id' disabled />
                                    : <Select name='instCategory' options={currentUserCategoryArr} multi onChange={setSelCategory} className={styles.selectFields} label='name' value='id' defaultValue="" />}
                            </label>}
                            <label className={`${styles.label}`}>
                                <span className={styles.isRequired}>User Id</span>
                                <input placeholder='Enter user id' className={`${styles.fields}`} type='text' name='user_id' value={userId} onChange={(e) => setUserId(e.target.value)} />
                            </label>
                            <label className={`${styles.label}`}>
                                <span className={styles.isRequired}>Surname</span>
                                <input placeholder='' className={`${styles.fields}`} type='text' name='lastName' ref={lastName} />
                            </label>
                            <label className={`${styles.label}`}>
                                <span className={styles.isRequired}>First Name</span>
                                <input placeholder='' className={`${styles.fields}`} type='text' name='firstName' ref={firstName} />
                            </label>
                            <label className={`${styles.label}`}>
                                Middle Name
                                <input placeholder='' className={`${styles.fields}`} type='text' name='middleName' ref={middleName} />
                            </label>
                            <label className={`${styles.label}`}>
                                <span className={styles.isRequired}>Gender</span>
                                <select className={`${styles.fields}`} name='gender' ref={gender}>
                                    <option value='undefined'>
                                        Select Gender
                                    </option>
                                    <option value='male'>
                                        Male
                                    </option>
                                    <option value='female'>
                                        Female
                                    </option>
                                </select>
                            </label>
                            <label className={`${styles.label}`}>
                                <span className={styles.isRequired}>Email</span>
                                <div className={`${style.valDiv}`}>
                                    <input placeholder='' className={`${style.valFields}`} type='email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                    {!valEmail ?
                                        <BsCircle className={`${style.valIcons} ${style.red}`} /> :
                                        <BsCheck2Circle className={`${style.valIcons}`} />
                                    }
                                </div>
                            </label>
                            <label className={`${styles.label}`}>
                                <span className={styles.isRequired}>Phone number</span>
                                <div className={`${style.valDiv}`}>
                                    <input placeholder='' className={`${style.valFields}`} type='text' name='phoneNumber' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                    {!valPhone ?
                                        <BsCircle className={`${style.valIcons} ${style.red}`} /> :
                                        <BsCheck2Circle className={`${style.valIcons}`} />
                                    }
                                </div>
                            </label>
                            <label className={`${styles.label}`}>
                                <span className={styles.isRequired}>Date of Birth</span>
                                <input placeholder='' className={`${style.valFields}`} type='date' name='date' value={dob} onChange={(e) => setDob(e.target.value)} />
                            </label>
                            {!editingUser && <>
                                <label className={`${styles.label}`}>
                                    <span className={styles.isRequired}>User Password</span>
                                    <div className={`${style.valDiv}`}>
                                        <input placeholder='' className={`${style.valFields}`} type={showPassword ? 'text' : 'password'} name='login' ref={login} />
                                        {!showPassword ?
                                            <BsEyeSlash className={`${style.valIcons} ${style.black}`} onClick={() => setShowPassword(!showPassword)} /> :
                                            <BsEye className={`${style.valIcons}`} onClick={() => setShowPassword(!showPassword)} />
                                        }
                                    </div>
                                </label>
                            </>}
                            {!editingUser && <>
                                <Container>
                                    <CheckLabel>
                                        <input type='checkbox' checked={resetPassword} onChange={() => setResetPassword(!resetPassword)} />
                                        <span>Reset password on first log in</span>
                                    </CheckLabel>
                                    <CheckLabel className='second'>
                                        <input type='checkbox' checked={sendCredential} onChange={() => setSendCredential(!sendCredential)} />
                                        <span>Send credential to user</span>
                                    </CheckLabel>
                                </Container>
                            </>}
                            <TableContainer>
                                <label>Add Custom Fields <small style={{ fontSize: '12px', color: 'var(--secondary-color)' }}>(All keys must be unique and in lower case string)</small></label>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>KEY</th>
                                            <th>VALUE</th>
                                        </tr>
                                    </thead>
                                    <tbody>{customFields.map((row, ri) =>
                                        <tr key={ri}>
                                            <td onDrop={(ev) => drop(ri, ev)} onDragOver={allowDrop}>
                                                <div className='optdiv' draggable onDragStart={(ev) => drag(ri, ev)} >
                                                    <button title='Delete row' className='optbtn button' onClick={() => deleteRow(ri)}><FaTrashAlt /></button>
                                                    <div></div>
                                                </div>
                                                <input value={row.key} onChange={(e) => handleInput(e.target.value, 'key', ri)} />
                                            </td>
                                            <td onDrop={(ev) => drop(ri, ev, 'row')} onDragOver={allowDrop}>
                                                <input value={row.value} onChange={(e) => handleInput(e.target.value, 'value', ri)} />
                                            </td>
                                        </tr>
                                    )}
                                        <tr>
                                            <button title='Add row' className='button' onClick={() => addRow()}>&#10010;</button>
                                        </tr>
                                    </tbody>
                                </table>
                            </TableContainer>
                        

                            <div className={`${styles.addBtnDiv} ${styles.div} ${styles.buttonContainer}`}>
                                {editingUser && <button className={`cancelEditButton`} onClick={() => handleCancel()}>Cancel Edit</button>}
                                <button type='submit' className={styles.addBtn} onClick={(e) => { handleSubmit(e); }}>
                                    {editingUser ? 'Save Change(s)' : 'Add User'}
                                </button>
                            </div>
                        </div>}

                    {!manual &&
                        <>
                            <form className={`${style.form}`}>
                                <label className={styles.label}>
                                    <span className={styles.isRequired}>User institution</span>
                                    {editingUser ? <Select name='institution' options={institutions} onChange={setSelInst} className={styles.fields} label='name' value='institution_id' defaultValue={selInst} disabled />
                                        : <Select name='institution' options={institutions} onChange={setSelInst} defaultValue="" className={styles.fields} label='name' value='institution_id' />}
                                </label>
                                <label className={`${styles.label}`}>
                                    <span className={styles.isRequired}>User Group</span>
                                    {editingUser ? <Select name='instCategory' options={currentUserCategoryArr} multi onChange={setSelCategory} className={styles.selectFields} defaultValue={defValue} label='name' value='id' disabled />
                                        : <Select name='instCategory' options={currentUserCategoryArr} multi onChange={setSelCategory} className={styles.selectFields} label='name' value='id' defaultValue="" />}
                                </label>
                                <div className={`${styles.label}`}>
                                    <strong>Note:</strong> The document to be uploaded must have the following fields presented in the sample image.
                                    <div className={style.sampleParent}>
                                        <div className={`${style.documentSample}`} />
                                    </div>
                                    <label className={`${styles.label}`}>
                                        <input placeholder='' className={`${styles.uploadBtn} ${styles.isRequired}`} type='file' accept='.xlsx, .xls' onChange={(e) => setFile(e.target.files[0])} />
                                    </label>
                                </div>
                                <Container>
                                    <CheckLabel className='second'>
                                        <input type='checkbox' checked={sendCredential} onChange={() => setSendCredential(!sendCredential)} />
                                        <span>Send credential to users</span>
                                    </CheckLabel>
                                </Container>
                                <div className={`${styles.addBtnDiv} ${styles.div}`}>
                                    <button type='submit' className={styles.addBtn} onClick={handleMultipleAdd}>
                                        Upload Staff Document
                                    </button>
                                </div>
                            </form>
                        </>
                    }
                </div>
            </div>
        </>
    );
};

export default AddUser;

export const TableContainer = styled.div`

.button {
	display: inline-flex;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	${'' /* background-color: rgb(212, 212, 255); */}
	color: #36395A99;
	border-radius: 4px;
	border-width: 0;
	${'' /* box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset; */}
	padding: 10px, 16px;
	font-size: 18px;
	transition: box-shadow .15s, transform .15s;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	white-space: nowrap;
	will-change: box-shadow, transform;
	min-width: fit-content;

	&:focus {
		box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
	}

	&:hover {
		box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
		transform: translateY(-2px);
	}

	&:active {
		box-shadow: #D6D6E7 0 3px 7px inset;
		transform: translateY(2px);
	}
}



	/* position: absolute;
	top: 50%;
	left: 50%;
	min-height: 100vh;
	transform: translate(-50%, -50%); */
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	justify-content: center;
	overflow-x: auto;

    table {
        border-collapse: collapse;
        ${'' /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */}
        position: relative;
        overflow-y: clip;
        margin-left: 30px;

        input {
            display: flex;
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
            background-color: transparent;
            color: inherit;
            margin: 0;
            font: inherit;
            padding: 10px;
            position: relative;
            z-index: 2;
        }
    }

    th,
    td {
        color: #333;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    th:hover .clmbtn,
    td:first-of-type:hover .optbtn {
        display: inline-flex;
        z-index: 3;
    }
${'' /* 
    th {
        text-align: left;
    }

    tr {
        position: relative;
    } */}


    thead {
        ${'' /* tr{
            position: unset;
        } */}
        th {
            // background-color: var(--nav-color);
            min-width: 80px;
            position: relative;
            // color: #fff;
            padding-top: 12px;
        }
        ${'' /* th:first-of-type{
            background: linear-gradient(24deg, rgba(85, 96, 143, 0.2) 50%, var(--nav-color) 50%);
            display: flex;
            flex-direction: column;
            border: none;
            padding-right: 10px;
            padding-left: 10px;
            >input{
                padding: 0;
            }
            >input:first-of-type{
                align-self: flex-end;
                text-align: end;
            }
            >input:nth-of-type(2){
                color: #333
            }
        }
        th:not(:first-of-type) {
            position: relative;
            
            &:hover {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -9999px;
                    bottom: -9999px;
                    background-color: rgba(0, 200, 200, 0.1);
                    z-index: -0;
                }
            }
        } */}
    }

    tbody {
        td:first-of-type {
            // background-color: rgba(85, 96, 143, 0.12);
            font-weight: 700;
            // border-color: #fff;
        }

        tr:not(:last-of-type) {
            position: relative;
            &:hover {
                background-color: rgba(200, 200, 200, 0.3);
            }
        }

        ${'' /* td {
            
            &:hover {
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -9999px;
                    bottom: -9999px;
                    background-color: rgba(200, 200, 200, 0.2);
                    z-index: -0;
                }
            }
        } */}
    }

    .optdiv {
        width: calc(100% + 30px);
        height: 100%;
        position: absolute;
        left: -30px;
        display: flex;
        
        >div:last-child{
            background-color: rgba(0, 200, 200, 0.1);
            display: none;
            width: 100%;
            height: 100%;
        }
    }

    .optbtn {
        display: flex;
        gap: 2px;
        z-index: 1;
        height: 100%;
        width: fit-content;

        &::before {
            display: none;
        }
        &:hover+div:last-child{
            display: block;
        }

    }

    .clmbtn {
        position: absolute;
        right: 0;
        top: -0px;
        display: none;
        z-index: -1;
    }

    .selected {
        background-color: rgba(0, 0, 0, 0.2);
    }

`;
const Container = styled.div`
    display: flex;
    width:100%;
    gap:20px;
    justify-content: space-between;
    @media screen and (max-width:850px){
        flex-direction: column;
        justify-content: flex-start;
        gap:10px;
    }
`;
const CheckLabel = styled.label`
    display:flex;
    flex:1;
    cursor: pointer;
    @media screen and (max-width:850px){
        &.second{
            flex-direction: row;
        }
        
    }
    
`;