import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styling from '../../../css/Categories.module.css';
import PageNumber from '../../../components/PageNumber';
import { convertDatetoString } from '../../../helperFunctions/Api2';
const NO_OF_ITEMS_PER_PAGE = 30;

const View = () =>{

    const transactions= Object.values(useSelector(state => state.account.transactions));
    const services = useSelector(state => state.account.service);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);
    

    useEffect(() => {
        if (transactions.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((transactions.length / NO_OF_ITEMS_PER_PAGE) + ((transactions.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0));
        }
    }, [transactions.length]);
    return (
        <div className={styling.form}>
            <div className={styling.viewQuestionContainer}>
                {transactions.length? transactions.sort((p, n) => p.id - n.id).map((payment, ind) => {
                    const details = JSON.parse(payment.details);

                    return ind < (currentPage * NO_OF_ITEMS_PER_PAGE) && ind >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) && 
                    <div key={ind} className={styling.eachQuestion}>
                        <div style={{ display: 'flex', width: 'calc(100% - 60px)', marginBottom: 0, fontSize: '14px' }}>
                            <span className={styling.createdSpan}>{convertDatetoString(payment.created_at)}</span>
                            <div style={{ fontSize: '13px', color: 'grey' }}>Ref: {payment.ref}</div>
                            <div>Amount:
                                <span style={{ color: 'var(--primary-color)' }}> {payment.amount} {payment.currency} </span>
                                <small style={{ border: '1px solid', fontSize: '10px', padding: '0 2px', color: payment.status ? 'var(--secondary-color)' : 'gray', borderRadius: '2px', verticalAlign: 'baseline' }}>{payment.status ? 'paid' : 'not paid'}</small>
                            </div>
                            <div>Service:<span style={{ color: 'var(--primary-color)' }}>{details.bill_ids?.map(id => ' '+services.find(serv => serv.id === id)?.name)}</span></div>
                        </div>
                    </div>}
                ) : <p style={{ textAlign: 'center', width: '100%' }}>No Transactions!</p>}
            </div>
            {transactions.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

        </div>

    );
}

export default View;