import React, { useState, useEffect, createContext, useContext, useRef, useMemo } from 'react';
import styles from '../../../css/CreateInst.module.css';
import Select from '../../../components/Select';
import { useSelector, useDispatch } from 'react-redux';
import Api from '../../../helperFunctions/Api2';
import { setQuestions, setCategory, editCategory } from "../../../redux/reducers/questionReducer";
import CbtCategorySettings from './CbtCategorySettings';
import { CbtModalContext } from './cbt';
import Modal from '../../../components/modal';
import Loading from '../../../components/loading';
// import JoditEditor from 'jodit-react';
import EmailList from '../../../components/EmailList';
import Timezone from '../../../components/Timezone';
import { addCbt, editCbt } from '../../../redux/reducers/cbtReducer';
import { useNavigate } from 'react-router-dom';
import TextEditor from '../../../components/TextEditor';
import UploadedFiles from '../../../components/UploadedFiles';


export const CbtQuestionId = createContext();
const user_id = Number(localStorage.getItem('eduplus_idnty')) || 22;
const cbtTypeArr = [
    { value: '0', label: 'Standard CBT' },
    { value: '1', label: 'Live/Proctored CBT(Beta)' },
    { value: '2', label: 'Recorded CBT' },
    { value: '3', label: 'AI monitored CBT' }
];
const timeArr = [
    { value: '0', label: 'General Time' },
    { value: '1', label: 'Time per Section' },
    { value: '2', label: 'Time per Question' }
];

const audienceArr = [
    { value: '0', label: 'Email List' },
    { value: '1', label: 'Institution' },
];

const CreateCbt = ({ setTitle }) => {

    const [logoURL, setLogoURL] = useState('');
    const [cbtTitle, setCbtTitle] = useState('');
    const [cbtStartDate, setCbtStartDate] = useState('');
    const [cbtEndDate, setCbtEndDate] = useState('');
    const [cbtStartTime, setCbtStartTime] = useState('');
    const [cbtEndTime, setCbtEndTime] = useState('');
    const [totalTime, setTotalTime] = useState(null);
    const [institutionArr, setInstitutionArr] = useState([]);
    const [selInstitution, setselInstitution] = useState(null);
    const [userCategory, setUserCategory] = useState([]);
    const [questCategory, setquestCategory] = useState(null);
    const [timeType, setTimeType] = useState(null);
    const [categoryArr, setCategoryArr] = useState([]);
    const [allQuestionId, setAllQuestionId] = useState([]);
    const [allQuestionCategory, setAllQuestionCategory] = useState([]);
    const [allowGoBack, setAllowGoBack] = useState(true);
    const [userCategoryArr, setUserCategoryArr] = useState([]);
    const [currentUserCategoryId, setCurrentUserCatergoryId] = useState('');
    const [currentUserCategoryArr, setCurrentUserCatergoryArr] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [editorContent, setEditorContent] = useState('');
    const [show_answer, setShow_answer] = useState(false);
    const [defaultAudience, setDefaultAudience] = useState('');
    const [activeAudience, setActiveAudience] = useState(audienceArr[0]);
    const [emailArray, setEmailArray] = useState('');
    const [cbtTimezone, setCbtTimezone] = useState(null);
    const [resetSelect, setResetSelect] = useState(false);
    const [disableField, setDisableField] = useState(false);
    const [defaultEmailList, setDefaultEmailList] = useState('');
    const [defaultTimeZone, setDefaultTimeZone] = useState('');
    const [defaultTimingType, setDefaultTimingType] = useState('');
    const [defaultCateQuestion, setDefaultCateQuestion] = useState('');
    const [defaultInstitution, setDefaultInstitution] = useState('');
    const [defaultInstCategory, setDefaultInstCategory] = useState('');
    const [defaultCbtType, setDefaultCbtType] = useState(cbtTypeArr[0]);
    const [pass_mark, set_pass_mark] = useState("40");
    const editorRef = useRef(null);
    const dispatch = useDispatch();
    const categories = useSelector(state => state.questions.categories);
    const questions = useSelector(state => state.questions.value);
    const userInstitutions = useSelector(state => state.questions.user_institutions);
    const { setModalObject, setModalTogglee, editObject, setEditObject } = useContext(CbtModalContext);
    const userCategoryList = useSelector(state => state.questions.user_categories);
    const navigate = useNavigate();

    const paymentLinks = useSelector(store => store.account.paymentLinks);
    const [addPaymentLinks, setAddPaymentLink] = useState(false);
    const forms = useSelector(store => store.portal.forms);
    const [assignForm, setAssignForm] = useState(false);
    const [selForm, setSelForm] = useState(null);
    const paylinkId = useRef();

    const config = {
        readonly: false,
        height: 400,
    };

    const handleEditor = (event) => {
        if (event) {
            //console.log(event);
            let content = event.innerHTML;
            setEditorContent(content);
        }

    };

    const validSingleValue = (value, body) => {
        if (value === null || value === '' || typeof value === 'undefined') {
            setModalObject({ header: 'Invalid Cbt Settings', body: `PLEASE SET ${body}`, footer: '' });
            setModalTogglee(true);
            return false;
        }
        return true;
    };

    const validAllQuestionId = () => {

        if (allQuestionId.length > 0) {
            let len = allQuestionId.length;
            let message = '';
            let i;
            for (i = 0; i < len; i++) {
                if (timeType.value === '2' && allQuestionCategory[i].is_selected === '1') {
                    const element = allQuestionId[i].questions;
                    if (element.length <= 0 && allQuestionCategory[i].is_selected === 1) {
                        message = 'EACH CATEGORY MUST HAVE QUESTION(S)';
                        break;
                    }
                    else if (allQuestionId[i].questions.length > 0 && allQuestionCategory[i].is_selected == 1) {
                        let eachLen = allQuestionId[i].questions.length;
                        let j = 0;
                        for (j = 0; j < eachLen; j++) {
                            const eachElement = element[j];
                            if (timeType.value === '2' && eachElement.duration <= 0 || eachElement.duration === null || eachElement.duration === '' || typeof eachElement.duration === 'undefined') {
                                message = 'EACH QUESTIONS MUST HAVE TIME IF YOU WANT TO SET TIME PER QUESTION';
                                break;
                            }
                        }
                        if (message) {
                            break;
                        }
                    }
                }

            }
            if (message) {
                setModalObject({ header: 'Invalid Cbt Settings', body: `${message}`, footer: '' });
                setModalTogglee(true);
                return false;
            }
            return true;
        }

        setModalObject({ header: 'Invalid Cbt Settings', body: `SELECT QUESTION CATEGORY(S)`, footer: '' });
        setModalTogglee(true);
        return false;
    };

    const validAllQuestionCategory = () => {
        if (allQuestionCategory.length > 0) {
            let len = allQuestionCategory.length;
            let message = '';
            let i;
            for (i = 0; i < len; i++) {
                const element = allQuestionCategory[i];

                if (timeType.value === '1' && (element.duration <= 0 || element.duration === null || element.duration === '' || typeof element.duration === 'undefined')) {
                    message = 'EACH CATEGORY MUST HAVE TIME IF YOU WANT TIME PER SECTION';
                    break;
                }

                if (element.questions_num <= 0 || element.questions_num === null || element.questions_num === '' || typeof element.questions_num === 'undefined') {
                    message = 'EACH CATEGORY MUST HAVE A QUESTION';
                    break;
                }

                if (timeType.value === '2' && (element.duration_per_question <= 0 || element.duration_per_question === '' || element.duration_per_question === null || typeof element.duration_per_question === 'undefined')) {
                    message = 'EACH QUESTION MUST HAVE TIME IF YOU WANT TO USE TIME PER QUESTION';
                    break;
                }

            }
            if (message) {
                setModalObject({ header: 'Invalid Cbt Settings', body: `${message}`, footer: '' });
                setModalTogglee(true);
                return false;
            }
            return true;
        }

        setModalObject({ header: 'Invalid Cbt Settings', body: `SELECT QUESTION CATEGORY(S)`, footer: '' });
        setModalTogglee(true);
        return false;
    };

    const calculateTotalQuestions = () => {
        let totalQuestions = 0;
        allQuestionCategory.forEach(category => {
            totalQuestions += Number(category.questions_num);
        });
        return totalQuestions;
    };

    const fetchItems = async () => {
        Api.getApi('/question/get-user-questions', true)
            .then(response => response.json())
            .then(result => {
                //console.log(result)
                if (result && result.data && Object.keys(result.data).length > 0) {
                    dispatch(setQuestions(result.data));
                }
            })
            .catch(err => {
                window.console.log(err);
            });
    };

    const fetchItems2 = async () => {
        Api.getApi('/question/get-category', true)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                if (result && result.data && Object.keys(result.data).length > 0) {
                    dispatch(setCategory(result.data));
                }
            })
            .catch(err => {
                window.console.log(err);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validSingleValue(cbtTitle, 'TITLE')) {
            return false;
        }
        if (!validSingleValue(defaultCbtType, 'CBT TYPE')) {
            return false;
        }

        if (!validSingleValue(cbtStartDate, 'START DATE')) {
            return false;
        }

        if (!validSingleValue(cbtEndDate, 'END DATE')) {
            return false;
        }

        if (!validSingleValue(cbtStartTime, 'START TIME')) {
            return false;
        }

        if (!validSingleValue(cbtEndTime, 'END TIME')) {
            return false;
        }

        if (!validSingleValue(timeType, 'TIMING TYPE')) {
            return false;
        }

        if (timeType.value == '0' && !validSingleValue(totalTime, 'TOTAL TIME')) {
            return false;
        }

        if (!validAllQuestionCategory()) {
            return false;
        }

        if (!validAllQuestionId()) {
            return false;
        }

        if (cbtTimezone == '' || Object.keys(cbtTimezone).length == 0 || cbtTimezone == null) {
            setModalObject({ header: 'Invalid Cbt Settings', body: `PLEASE SET CBT TIMEZONE`, footer: '' });
            setModalTogglee(true);
            return false;
        }

        if (activeAudience.value == '1') {
            // if(!validSingleValue(userCategory,'USER CATEGORY')){
            //     return false;
            // }
            if (userCategory.length <= 0) {
                setModalObject({ header: 'Invalid Cbt Settings', body: `PLEASE SET USER GROUP`, footer: '' });
                setModalTogglee(true);
                return false;
            }

            if (!validSingleValue(selInstitution, 'INSTITUTION')) {
                return false;
            }
        }
        else if (activeAudience.value == '0') {
            if (emailArray.length <= 0) {
                setModalObject({ header: 'Invalid Cbt Settings', body: `PLEASE ADD EMAIL TO EMAIL LIST`, footer: '' });
                setModalTogglee(true);
                return false;
            }
        }

        const cbtObj = {
            cbt: {},
            categories: [],
            category_questions: [],
        };

        // console.log(editorRef.current.value);
        cbtObj.cbt.title = cbtTitle;
        cbtObj.cbt.logo_url = logoURL;
        cbtObj.cbt.type = Number(defaultCbtType.value);
        cbtObj.cbt.instructions = editorRef.current.value ? editorRef.current.value : '';
        cbtObj.cbt.show_answer = show_answer;
        cbtObj.cbt.timing_type = timeType.value;
        let instCateArr = [];
        if (activeAudience.value == '0') {
            cbtObj.is_institution = 0;
            cbtObj.email_list = emailArray;
        } else {
            cbtObj.is_institution = 1;
            //cbtObj.cbt.users_category_id=userCategory.id;

            userCategory.forEach(uCate => {
                instCateArr.push(uCate.id);
                //console.log(uCate);
            });
            cbtObj.users_category_id = instCateArr;
            cbtObj.cbt.institution_id = selInstitution.id;
        }
        cbtObj.cbt.timezone = Number(cbtTimezone.value);
        cbtObj.cbt.start_time = `${cbtStartDate} ${cbtStartTime}`;
        cbtObj.cbt.end_time = `${cbtEndDate} ${cbtEndTime}`;
        cbtObj.cbt.duration = timeType.value === '0' ? totalTime : null;
        cbtObj.cbt.reanswer_question = allowGoBack;
        cbtObj.cbt.total_categories = allQuestionCategory.length;
        cbtObj.cbt.total_questions = calculateTotalQuestions();
        cbtObj.cbt.pass_mark = isNaN(pass_mark) || !pass_mark ? 0 : pass_mark;
        cbtObj.categories = allQuestionCategory;
        cbtObj.category_questions = allQuestionId;

        if(selForm) cbtObj.cbt.form_id = selForm.id;

        if(addPaymentLinks) cbtObj.cbt.payment_link_id = Number(paylinkId.current.value);

        const c = {};
        c.added_by = user_id;
        c.analytics = null;
        c.categories = allQuestionCategory;
        c.created_at = null;
        c.duration = timeType.value == '0' ? totalTime : null;
        c.form_id = null;
        c.institution_id = activeAudience.value == "0" ? null : selInstitution.id;
        c.instructions = editorRef.current.value ? editorRef.current.value : '';
        c.payment_link_id = null;
        c.reanswer_question = allowGoBack;
        c.show_answer = show_answer;
        c.start_time = `${cbtStartDate} ${cbtStartTime}`;
        c.end_time = `${cbtEndDate} ${cbtEndTime}`;
        c.subuser_id = null;
        c.timezone = Number(cbtTimezone.value);
        c.timing_type = timeType.value;
        c.title = cbtTitle;
        c.logo_url = logoURL;
        c.type = Number(defaultCbtType.value);
        c.total_categories = allQuestionCategory.length;
        c.total_questions = calculateTotalQuestions();
        c.user = emailArray;
        c.users_category_id = activeAudience.value == "0" ? null : instCateArr;
        c.users_num = activeAudience.value == "0" ? emailArray.length : instCateArr.length;
        c.pass_mark = isNaN(pass_mark) || !pass_mark ? '' : pass_mark;

        //console.log(cbtObj, 'cbt object');
        // clearForm();

        // return;
        if (!Api.checkNetwork()) return false;

        setShowLoading(true);

        Api.PostApi('/test/add-cbt', cbtObj, true)
            .then(result => {
                setShowLoading(false);
                setModalObject({ body: result.message, header: 'Message' });
                setModalTogglee(true);
                console.log(result, "Server result", modalText);
                if (result.isSuccessful) {
                    dispatch(addCbt({ ...c, id: result.data.id, created_at: result.data.created_at }));
                    clearForm();
                }
            })
            .catch(error => {
                setShowLoading(false);
                console.log(error, "Server Error");
            });
    };

    const handleSubmitEdit = (e) => {
        e.preventDefault();
        if (!validSingleValue(cbtTitle, 'TITLE')) {
            return false;
        }
        if (!validSingleValue(defaultCbtType, 'CBT TYPE')) {
            return false;
        }
        if (!validSingleValue(cbtEndDate, 'END DATE')) {
            return false;
        }
        if (!validSingleValue(cbtEndTime, 'END TIME')) {
            return false;
        }
        if (timeType.value == '0' && !validSingleValue(totalTime, 'TOTAL TIME')) {
            return false;
        }
        if (activeAudience.value == '1') {
            // if(!validSingleValue(userCategory,'USER GROUP')){
            //     return false;
            // }
            if (userCategory.length <= 0) {
                setModalObject({ header: 'Invalid Cbt Settings', body: `PLEASE SET USER GROUP`, footer: '' });
                setModalTogglee(true);
                return false;
            }

            if (!validSingleValue(selInstitution, 'INSTITUTION')) {
                return false;
            }
        }
        else if (activeAudience.value == '0') {
            if (emailArray.length <= 0) {
                setModalObject({ header: 'Invalid Cbt Settings', body: `PLEASE ADD EMAIL TO EMAIL LIST`, footer: '' });
                setModalTogglee(true);
                return false;
            }
        }

        //After checking for all possible errors
        //Construction the payload to send
        let add_users = [];
        let remove_users = [];
        let temp_remove_users = [];
        let add_users_category = [];
        let remove_users_category = [];
        let editCBTObject = {};
        editCBTObject.cbt = {};
        editCBTObject.cbt.title = cbtTitle;
        editCBTObject.cbt.logo_url = logoURL;
        editCBTObject.cbt.type = Number(defaultCbtType.value);
        editCBTObject.cbt.duration = timeType.value == '0' ? totalTime : null;
        editCBTObject.cbt.id = editObject.id;
        editCBTObject.cbt.form_id = editObject.form_id;
        editCBTObject.cbt.instructions = editorRef.current.value ? editorRef.current.value : '';
        editCBTObject.cbt.payment_link_id = editObject.payment_link_id;
        editCBTObject.cbt.reanswer_question = allowGoBack;
        editCBTObject.cbt.pass_mark = isNaN(pass_mark) || !pass_mark ? '' : pass_mark;
        editCBTObject.cbt.show_answer = show_answer;
        editCBTObject.cbt.timezone = Number(cbtTimezone.value);
        editCBTObject.cbt.end_time = `${cbtEndDate} ${cbtEndTime}`;

        if(selForm) editCBTObject.cbt.form_id = selForm.id;

        if(addPaymentLinks) editCBTObject.cbt.payment_link_id = Number(paylinkId.current.value);


        if (!editObject.institution_id) {
            let usersBeforeEdit = editObject.users.map(eUser => eUser.email ? eUser.email : eUser);
            let newAfterUsers = emailArray;
            usersBeforeEdit.forEach(eBUser => {
                let checkStatus = newAfterUsers.find(eAUser => eBUser.toLowerCase() == eAUser.toLowerCase());
                if (!checkStatus) temp_remove_users.push(eBUser);
            });
            if (temp_remove_users.length > 0) {
                //console.log(editObject.users,temp_remove_users);
                temp_remove_users.forEach(email => {
                    let user = editObject.users.find(eUser => eUser.email.toLowerCase() == email.toLowerCase());
                    if (user) remove_users.push(Number(user.id));
                });
            }
            newAfterUsers.forEach(eAUser => {
                let checkStatus = usersBeforeEdit.find(eBUser => eAUser.toLowerCase() == eBUser.toLowerCase());
                if (!checkStatus) add_users.push(eAUser);
            });
        }
        else {
            const usersBeforeEdit = JSON.parse(editObject.users_category_id);
            const usersAfterEdit = userCategory.map(eCate => eCate.id);
            usersAfterEdit.forEach(aEUser => {
                let checkStatus = usersBeforeEdit.find(bEUser => bEUser == aEUser);
                if (!checkStatus) add_users_category.push(aEUser);
            });
            usersBeforeEdit.forEach(bEUser => {
                let checkStatus = usersAfterEdit.find(aEUser => aEUser == bEUser);
                if (!checkStatus) remove_users_category.push(bEUser);

            });
        }

        editCBTObject.remove_users = remove_users;
        editCBTObject.add_users = add_users;
        editCBTObject.add_users_category = add_users_category;
        editCBTObject.remove_users_category = remove_users_category;
        console.log(editCBTObject);

        if (!Api.checkNetwork()) return false;

        setShowLoading(true);

        Api.PostApi('/test/edit-cbt-details', editCBTObject, true)
            .then(result => {
                setShowLoading(false);
                setModalObject({ header: '', body: result.message, footer: '' });
                setModalTogglee(true);
                console.log(result, "Server result");
                setEditObject(null);
                if (result.isSuccessful) {
                    // dispatch(editCbt({...editCbt,end_time:`${cbtEndDate} ${cbtEndTime}`,title:cbtTitle,show_answer,reanswer_question:allowGoBack,timezone:Number(cbtTimezone.value),instructions:(editorRef.current.value? editorRef.current.value:''),duration:(timeType.value == '0'?totalTime :null)}))
                    clearForm();
                }
            })
            .catch(error => {
                setShowLoading(false);
                console.log(error, "Server Error");
            });


    };

    const clearForm = () => {
        setCbtTitle('');
        setLogoURL('');
        setDefaultCbtType(cbtTypeArr[0]);
        setEditorContent('');
        setResetSelect(!resetSelect);
        setCbtStartDate("yyyy-MM-dd");
        setCbtEndDate("yyyy-MM-dd");
        setCbtStartTime("HH:mm");
        setCbtEndTime("HH:mm");
        setTotalTime("0");
        setAllowGoBack(false);
        setShow_answer(false);
        setDisableField(false);
        set_pass_mark('40');
    };

    const handleQuestions = (category) => {
        setShowLoading(true);
        Api.getApi(`/question/open-user-question/${category.id}`, true)
            .then(result => {
                if (result.isSuccessful) {
                    result.data = { ...category, ...result.data };
                    let qs = questions.filter(q => q.category_id !== category.id);
                    dispatch(setQuestions([...qs, ...result.data.questions]));
                    dispatch(editCategory({ ...result.data, fetched: true }));
                }

            })
            .catch(error => {
                setModalObject({ header: '', body: 'Action failed, please try again or re-login', footer: '' });
                setModalTogglee(true);
            })
            .finally(() => {
                setShowLoading(false);
            });
    };

    useEffect(() => {
        if (categories && categories.length > 0) {
            setCategoryArr(categories);
            if (categoryArr.length <= 0) {
                // setCategoryArr(categories.map(element => ({value:element.id,label:element.name})));
            }
            else {
                if (questCategory.length > 0) {
                    setDefaultCateQuestion(questCategory.map(cat => categories.find(category => category.id == cat.id)));
                }
            }
        }
        // else{
        //     fetchItems2();
        // }

    }, [categories]);

    useEffect(() => {
        if (questCategory && questCategory.length > 0) {
            const unfetched = questCategory.filter(cat => !cat.fetched);
            unfetched.forEach(cat => {
                handleQuestions(cat);
            });
        }
    }, [questCategory]);
    useEffect(() => {
        if (questions.length <= 0) {
            //fetchItems();          
        }
    }, [questions]);

    useEffect(() => {
        setInstitutionArr(userInstitutions);
    }, [userInstitutions]);

    useEffect(() => {
        setUserCategoryArr(userCategoryList);
    }, [userCategoryList]);

    useEffect(() => {
        if (selInstitution && selInstitution.id && selInstitution.categories) {
            //console.log(selInstitution)
            setCurrentUserCatergoryId(selInstitution.id);
            setCurrentUserCatergoryArr(Object.values(selInstitution.categories));
        }

    }, [selInstitution]);

    useEffect(() => {
        // setAudience(audienceArr);
        setTitle('Create CBT');
    }, []);

    useEffect(() => {
        if (defaultCbtType.value === '1') {
            setDefaultTimingType(timeArr[0]);
        }
        else {
            setDefaultTimingType(timeType);
        }
    }, [defaultCbtType, timeType]);

    useEffect(() => {
        if (editObject && Object.keys(editObject).length > 0) {
            //console.log(editObject);
            setCbtTitle(editObject.title);
            setLogoURL(editObject.logo_url);
            setDefaultCbtType(cbtTypeArr[editObject.type]);
            if (editObject.pass_mark) {
                set_pass_mark(editObject.pass_mark);
            }
            if (!editObject.institution_id) {
                setDefaultAudience(audienceArr[0]);
                setDefaultEmailList(editObject.users.map(eachUser => eachUser.email));
            }
            else {
                setDefaultAudience(audienceArr[1]);
            }
            if (editObject.instructions && editorRef && editorRef.current) editorRef.current.value = editObject.instructions;
            setDefaultTimeZone(editObject.timezone);
            let sDate = new Date(editObject.start_time);
            let sDay = sDate.getDate();
            sDay = sDay <= 9 ? `0${sDay}` : sDay;
            let sMonth = sDate.getMonth() + 1;
            sMonth = sMonth <= 9 ? `0${sMonth}` : sMonth;
            let sYear = sDate.getFullYear();
            let sHour = sDate.getHours();
            sHour = sHour <= 9 ? `0${sHour}` : sHour;
            let sMin = sDate.getMinutes();
            sMin = sMin <= 9 ? `0${sMin}` : sMin;
            setCbtStartDate(`${sYear}-${sMonth}-${sDay}`);
            setCbtStartTime(`${sHour}:${sMin}:00`);

            let eDate = new Date(editObject.end_time);
            let eDay = eDate.getDate();
            eDay = eDay <= 9 ? `0${eDay}` : eDay;
            let eMonth = eDate.getMonth() + 1;
            eMonth = eMonth <= 9 ? `0${eMonth}` : eMonth;
            let eYear = eDate.getFullYear();
            let eHour = eDate.getHours();
            eHour = eHour <= 9 ? `0${eHour}` : eHour;
            let eMin = eDate.getMinutes();
            eMin = eMin <= 9 ? `0${eMin}` : eMin;

            setCbtEndDate(`${eYear}-${eMonth}-${eDay}`);
            setCbtEndTime(`${eHour}:${eMin}:00`);
            let tempTimingType = timeArr.find(eTime => eTime.value == editObject.timing_type);
            if (tempTimingType) setDefaultTimingType(tempTimingType);
            if (editObject.timing_type == '0') setTotalTime(editObject.duration);
            let tempDefaultCQuestion = editObject.categories.map(eCate => ({ id: eCate.category_id }));
            // console.log(tempDefaultCQuestion); 
            setDefaultCateQuestion(tempDefaultCQuestion);
            if (editObject.institution_id) {
                setDefaultInstitution({ id: editObject.institution_id });
                let userCateOptions = Object.values(userInstitutions.find(inst => inst.id == editObject.institution_id).categories);
                setCurrentUserCatergoryArr(userCateOptions);

            }
            setAllowGoBack(editObject.reanswer_question);
            setShow_answer(editObject.show_answer);
            
            if (paymentLinks[editObject.payment_link_id]) {
                setAddPaymentLink(true);
            }
            if (editObject.form_id) {
                setAssignForm(true);
                setSelForm(forms.find(form => form.id === editObject.form_id));
            }

            setDisableField(true);
        }
        if (editObject && Object.keys(editObject).length > 0 && institutionArr.length == 0) {
            console.log('here')
            if (editObject.institution_id) {
                setDefaultInstCategory(JSON.parse(editObject.users_category_id).map(eCate => ({ id: eCate })));
            }
        }
    }, [editObject]);

    return (
        <>
            {/* <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} /> */}
            <Loading shouldShow={showLoading} />
            <form className={`${styles.form} ${styles.form2}`}>
                <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Cbt Title</span>
                    <input name='instName' className={`${styles.fields}`} type='text' placeholder="E.g Gorilla Mathematic Assessment" value={cbtTitle} onChange={(e) => { setCbtTitle(e.target.value); }} />
                </label>
                <label className={`${styles.label}`} title="Select CBT Type">
                    <span className={styles.isRequired}>CBT Type</span>
                    <Select name='type' className={styles.selectFields} defaultValue={defaultCbtType} options={cbtTypeArr} onChange={setDefaultCbtType} reset={resetSelect} />
                </label>

                {/* <label className={`${styles.label} ${styles.editorLabel}`} title="Write down instruction for CBT">
                    <JoditEditor 
                        ref={editorRef}
                        config={config}
                        value={editorContent}
                        onBlur={handleEditor}
                        onChange={(e)=>handleEditor(e)}
                    />

                </label> */}
                <TextEditor text='CBT Instructions' setShowLoading={setShowLoading} value={editorContent} handleChange={handleEditor} reff={editorRef} labelStyle={{}} />
                <label className={`${styles.label}`} title="Select CBT audience Type">
                    <span className={styles.isRequired}>CBT audience Type</span>
                    <Select name='audience' className={styles.selectFields} defaultValue={defaultAudience} options={audienceArr} onChange={setActiveAudience} reset={resetSelect} disabled={disableField} />
                </label>

                {activeAudience.value == '1' && <>
                    <label className={`${styles.label}`} title="Select the Institution for the CBT">
                        <span className={styles.isRequired}>Select Institution</span>
                        {<Select name='instName' className={styles.selectFields} defaultValue={defaultInstitution}
                            onChange={setselInstitution} disabled={disableField}
                            options={institutionArr} value='id' label='name' reset={resetSelect} />}
                    </label>
                    <label className={`${styles.label}`} title="Select the group of users for the CBT">
                        <span className={styles.isRequired}>Select User Group</span>
                        {<Select name='userCate' className={styles.selectFields} defaultValue={defaultInstCategory}
                            onChange={setUserCategory}
                            options={currentUserCategoryArr} value='id' label='name' multi reset={resetSelect} />}
                    </label>
                </>}
                {activeAudience.value == '0' && <>
                    <label className={`${styles.label}`} title="Add list of emails">
                        <span className={styles.isRequired}>Add emails separated by comma or space</span>
                        <EmailList onChange={setEmailArray} defaultValue={defaultEmailList} reset={resetSelect} />

                    </label>
                </>}

                <label className={`${styles.label}`} title='Select time Zone'>
                    <span>Select Timezone</span>
                    <Timezone onChange={setCbtTimezone} reset={resetSelect} defaultValue={defaultTimeZone} />
                </label>

                <label className={styles.label2}>
                    <span className={styles.isRequired}>Cbt Start Date</span>
                    <input name='testStartDate' className={`${styles.fields}`} type='date' value={cbtStartDate ? cbtStartDate : ''} onChange={(e) => { setCbtStartDate(e.target.value); }} disabled={disableField} />
                </label>
                <label className={styles.label2}>
                    <span className={styles.isRequired}>Cbt End Date</span>
                    <input name='testEndDate' className={`${styles.fields}`} type='date' value={cbtEndDate} onChange={(e) => { setCbtEndDate(e.target.value); }} />
                </label>
                <label className={styles.label2}>
                    <span className={styles.isRequired}>Cbt Start Time</span>
                    <input name='testStartTime' className={`${styles.fields}`} type='time' value={cbtStartTime ? cbtStartTime : ""} onChange={(e) => { setCbtStartTime(e.target.value); }} disabled={disableField} />
                </label>
                <label className={styles.label2}>
                    <span className={styles.isRequired}>Cbt End Time</span>
                    <input name='testEndTime' className={`${styles.fields}`} type='time' value={cbtEndTime} onChange={(e) => { setCbtEndTime(e.target.value); }} />
                </label>

                <label className={`${styles.label}`} title="Select test timing type">
                    <span className={styles.isRequired}>Timing Type</span>
                    {<Select name='timingType' className={styles.selectFields} defaultValue={defaultTimingType}
                        onChange={setTimeType}
                        options={timeArr} reset={resetSelect} disabled={disableField || defaultCbtType.value === '1'} />}
                </label>
                {timeType && timeType.value == '0' && <label className={styles.label2}>
                    <span className={styles.isRequired}>{'Total Cbt Time (In minutes)'}</span>
                    <input className={`${styles.fields}`} type="number" min="0" onChange={(e) => { setTotalTime(e.target.value); }} value={totalTime ? totalTime : '0'} />
                </label>}
                {categoryArr && categoryArr.length > 0 && <label className={`${styles.label}`}>
                    <span className={styles.isRequired}>Questions Category</span>

                    <Select disabled={disableField} name='questCate' options={categoryArr} onChange={setquestCategory} className={styles.selectFields} defaultValue={defaultCateQuestion} multi value='id' label='name' reset={resetSelect} />
                </label>}

                {timeType && timeType.value !== '2' && <label className={styles.label}>
                    <span className={styles.isRequired}>{'Allow candidate to re-answer a question'}</span>
                    <input className={`${styles.fields} ${styles.fields3}`} type="checkbox" checked={allowGoBack} onChange={(e) => { setAllowGoBack(!allowGoBack); }} />
                </label>}

                <label className={styles.label}>
                    <span className={styles.isRequired}>{'Allow candidate to to see result after CBT'}</span>
                    <input className={`${styles.fields} ${styles.fields3}`} type="checkbox" checked={show_answer} onChange={(e) => { setShow_answer(!show_answer); }} />
                </label>

                {questCategory && questCategory.length > 0 && timeType && ((timeType.value == '0' && totalTime != 0) || (timeType && timeType.value != '0')) && <>
                    <CbtQuestionId.Provider value={{ allQuestionId, setAllQuestionId, allQuestionCategory, setAllQuestionCategory, editObject }}>
                        <CbtCategorySettings questions={questions} categories={categories} questCategory={questCategory} id={user_id} timeType={timeType} />
                    </CbtQuestionId.Provider>
                </>}
                <label className={styles.label}>
                    <span>Set Pass Mark</span>
                    <input type='number' className={`${styles.fields}`} min="0" max="100" value={pass_mark} placeholder='Enter pass mark' onChange={(e) => set_pass_mark(e.target.valueAsNumber)} />
                    <span style={{ color: 'var(--primary-color)' }}>NOTE: Pass mark is a percentage of the total score and default is 40</span>

                </label>

                <UploadedFiles type={'image'} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} setShowLoading={setShowLoading} onCopy={setLogoURL} />
                <label className={`${styles.label}`} title='Copy logo url from "Upload Image"'>
                    <div style={{ width: '100%' }}>
                        <span className={styles.isNotRequired}>CBT Logo URL</span>
                        <span className={styles.styledlink} onClick={() => setModalToggle(true)}>upload image</span>
                    </div>
                    <input name='logoURL' className={`${styles.fields}`} type='text' placeholder="Image URL" value={logoURL} onChange={(e) => { setLogoURL(e.target.value); }} />
                </label>

                <div className={styles.divContainer}>
                    <input style={{ maxHeight: 15 }} type="checkbox" checked={addPaymentLinks} name="require_payment" onChange={() => addPaymentLinks ? setAddPaymentLink(false) : setAddPaymentLink(true)} /><span>Add Payment Link</span>
                </div>
                {
                    addPaymentLinks && (
                        <label className={`${styles.label}`}>
                            <span className={styles.isRequired}>Select Payment Link</span>
                            <select ref={paylinkId} className={styles.selectFields} defaultValue={editObject && editObject.payment_link_id}>
                                {Object.values(paymentLinks).map((paymentLink) => (
                                    <option key={paymentLink.id} value={paymentLink.id}>{paymentLink.title}</option>
                                ))}
                            </select>
                            {/*<Select options={paymentLinks} name='Payment Link' className={styles.selectFields} onChange={setPayLink} value="id" label="title"/>*/}
                        </label>
                    )
                }
                <div className={styles.divContainer}>
                    <input style={{ maxHeight: 15 }}  type='checkbox' checked={assignForm} name="attach_form" onChange={() => setAssignForm(!assignForm)} />
                    <span>Attach Form</span>
                </div>
                {
                    assignForm &&
                    <label className={styles.label}>
                        <span className={styles.isRequired}>Form</span>
                        <Select name='form' options={forms} onChange={setSelForm} defaultValue={selForm} className={styles.fields} label='title' value='id' />
                    </label>

                }

                <div className={`${styles.addBtnDiv} ${styles.div}`}>
                    {!editObject ?
                        <button type="submit" className={styles.addBtn}
                            onClick={(event) => { handleSubmit(event); }}>Create CBT</button>
                        :
                        <span style={{ display: 'flex', gap: '30px' }}>
                            <button onClick={() => { clearForm(); setEditObject(null); navigate('../view-cbt'); }} className='cancelEditButton'>Cancel Edit</button>
                            <button type="submit" className={styles.addBtn}
                                onClick={(event) => { handleSubmitEdit(event); }}>Save Change(s)</button>
                        </span>
                    }


                </div>
            </form>
        </>
    );
};
//cbtTitle && cbtEndDate && cbtEndTime && cbtStartDate && cbtStartTime && selInstitution && userCategory && questCategory 
export default CreateCbt;