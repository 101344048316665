import React from "react";
import styles from '../../../css/StaffMgmt.module.css';
import titleStyle from '../../../css/SectionTitle.module.css';
import { Routes, Route, Link } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import { BsList } from 'react-icons/bs';
import { useState, createContext } from "react";
import Modal from '../../../components/modal';
import Add from  './addEmailGroup';
import View from './viewEmailGroup';
import AddEmail from './addEmails';
import Menu from "../../../components/Menu";
export const staffContext = createContext();
export const eventModalContext = createContext();


const ContactSettings = () => {

    const [modalObject,setModalObject] = useState({});
    const [modalToggle,setModalToggle] = useState(false);
    const [view, setView] = useState('');
    const [title, setTitle] = useState('');
    const [editEmailObj, setEditEmailObj]  = useState(null);
    const [allContact, setAllContact] = useState([]);
    

    return ( 
        <>
            <staffContext.Provider value={{ view, setView }}>
                <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <h1 className={`${styles.title}`}>Email Group</h1>
                    <div className={`${styles.subView}`}>
                        <div className={titleStyle.sectionTitle}>
                            <h3 className={titleStyle.h3}>
                                {title}
                            </h3>
                          
                            <Menu options={[
                                {label:'Create Email Group',path:'add-email-group'},
                                {label:'Add Emails To Group',path:'add-emails'},
                                {label:'View Email Group',path:'view-email-group'}
                            ]} />
                        </div>
                        <eventModalContext.Provider value={{modalObject,setModalObject,modalToggle,setModalToggle,editEmailObj,setEditEmailObj, allContact, setAllContact}} >
                            <Routes>
                                <Route path='*' element={<View setTitle={setTitle} />} />
                                <Route path='/add-email-group' element={<Add seTTitle={setTitle} />} />
                                <Route path='/view-email-group' element={<View setTitle={setTitle} />} />
                                <Route path='/add-emails' element={<AddEmail setTitle={setTitle} />} />
                                <Route path="/*" element={<PageNotFound />} /> 
                            </Routes> 
                            <Modal modalObject={modalObject} modalTogglee={modalToggle} closeModal={()=>{setModalToggle(false)}} />
                        </eventModalContext.Provider>
                        
                    </div>
                </div>
            </staffContext.Provider>
        </>
     );
}
 
export default ContactSettings;
