import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import styling from '../../../css/Categories.module.css';
import PageNumber from '../../../components/PageNumber';
import Select from "../../../components/Select";
import { convertDatetoString } from '../../../helperFunctions/Api2';
const NO_OF_ITEMS_PER_PAGE = 30;


const ViewPayments = ({ setTitle }) => {

    const paymentlinks = Object.values(useSelector(state => state.account.paymentLinks));
    const [selPaymentLink, setSelPaymentLink] = useState(null);
    const [currentPayments, setCurrentPayments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);


    useEffect(() => {
        setTitle('View Payment Links');
    }, [setTitle]);

    useEffect(() => {
        selPaymentLink && setCurrentPayments(selPaymentLink.payments ? selPaymentLink.payments : []);
    }, [selPaymentLink]);

    useEffect(() => {
        if (currentPayments.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((currentPayments.length / NO_OF_ITEMS_PER_PAGE) + ((currentPayments.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0));
        }
    }, [currentPayments.length]);
    return (
        <div className={styling.form}>
            <label className={styling.label}>
                Select Payment Link
                <Select options={paymentlinks} onChange={setSelPaymentLink} defaultValue={selPaymentLink} value="id" label="title" />
            </label>
            <div className={styling.viewQuestionContainer}>
                {currentPayments && currentPayments.length > 0 ? currentPayments.map((payment, ind) =>
                    ind < (currentPage * NO_OF_ITEMS_PER_PAGE) && ind >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
                    <div key={ind} className={styling.eachQuestion}>
                            <div style={{display: 'flex', width: 'calc(100% - 60px)', marginBottom: 0, fontSize: '14px'}}>
                                <span className={styling.createdSpan}>{convertDatetoString(payment.created_at)}</span>
                                <div style={{fontSize: '13px', color: 'grey'}}>Ref: {payment.ref}</div>
                                <div>Email: <span style={{ color: 'var(--primary-color)' }}> {payment.email} </span></div>
                                <div>Amount:
                                    <span style={{ color: 'var(--primary-color)' }}> {payment.amount_paid} {payment.currency} </span>
                                    <small style={{ border: '1px solid', fontSize: '10px', padding:'0 2px', color:payment.is_paid? 'var(--secondary-color)':'gray', borderRadius: '2px', verticalAlign: 'baseline' }}>{payment.is_paid?'paid':'not paid'}</small>
                                </div>
                                <div>Service:<span style={{color: 'var(--primary-color)'}}> {payment.service_table}</span></div>
                            </div>
                    </div>
                )
                    : !selPaymentLink?.id ? <p>Select Payment link</p>: <p>Create payment links to view Links</p>
                }
            </div>
            {currentPayments.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

        </div>

    );
};

export default ViewPayments;
;
