import React from "react";
import styles from '../../../css/StaffMgmt.module.css';
import titleStyle from '../../../css/SectionTitle.module.css';
import { Routes, Route } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import { useState, } from "react";
import Modal from '../../../components/modal';
import Menu from "../../../components/Menu";
import CreateAppointment from "./createAppointment";
import ViewAppointments from "./viewAppointments";
import AddAppointmentSession from "./AddAppointmentSession";
import ViewAppointmentSessions from "./viewAppointmentsSession";

import { eventModalContext } from "./events";


const Appointments = () => {

    const [modalObject, setModalObject] = useState({});
    const [modalToggle, setModalToggle] = useState(false);
    const [title, setTitle] = useState('');
    const [editAppointmentCon, setEditAppointmentCon] = useState(null);
    const [editSessionCon, setEditSessionCon] = useState(null);

    return (
        <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
            <h1 className={`${styles.title}`}>Appointments</h1>
            <div className={`${styles.subView}`}>
                <div className={titleStyle.sectionTitle}>
                    <h3 className={titleStyle.h3}>
                        {title}
                    </h3>

                    <Menu options={[
                        { label: 'Add Appointment', path: 'create-appointment' },
                        { label: 'View Appointments', path: 'view-appointment' },
                        { label: 'Add Appointment Session', path: 'add-session' },
                        { label: 'View Appointment Sessions', path: 'view-sessions' }
                    ]} />
                </div>
                <eventModalContext.Provider value={{ modalObject, setModalObject, modalToggle, setModalToggle, editAppointmentCon, setEditAppointmentCon, editSessionCon, setEditSessionCon }} >
                    <Routes>
                        <Route path='*' element={<ViewAppointments setTitle={setTitle} />} />
                        <Route path='/create-appointment' element={<CreateAppointment seTTitle={setTitle} />} />
                        <Route path='/view-appointment' element={<ViewAppointments setTitle={setTitle} />} />
                        <Route path='/add-session' element={<AddAppointmentSession seTTitle={setTitle} />} />
                        <Route path='/view-sessions' element={<ViewAppointmentSessions setTitle={setTitle} />} />
                        <Route path="/*" element={<PageNotFound />} />
                    </Routes>
                    <Modal modalObject={modalObject} modalTogglee={modalToggle} closeModal={() => { setModalToggle(false); }} />
                </eventModalContext.Provider>

            </div>
        </div>
    );
};

export default Appointments;
