import React,{useContext,useState,useEffect} from 'react';
import styling from '../../../css/Categories.module.css';
import Loading from '../../../components/loading';
import { useDispatch, useSelector } from 'react-redux';
import { LearningContext } from './OnlineClassindex';
import style from '../Calender/Css/event.module.css';
import HTMLReactParser from 'html-react-parser';
import {  SiGooglemeet as Meeting } from 'react-icons/si';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { EditComponent } from '../../../components/EditComponent';
import {MdNotStarted as StartIcon} from 'react-icons/md';
import restApi from '../../../helperFunctions/Api2';
import { editVirtualClass } from '../../../redux/reducers/learningReducer';
import { convertDatetoString } from '../../../helperFunctions/Api2';
import PageNumber from '../../../components/PageNumber';
const NO_OF_ITEMS_PER_PAGE = 30;



const ViewClass = () => {
  const {editClass, setEditClass, editClassObj, setEditClassObj,setView,setModalToggle,setModalObject} = useContext(LearningContext);
  const classes = useSelector(state => state.learning.virtual_class);
  const [showLoading, setShowLoading] = useState(false);  
  const [menuIndex,setMenuIndex] = useState(null);
  const navigate = useNavigate();
  const [copyText, setCopyText] = useState(-1);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(0);
//   console.log(classes);
   useEffect(()=>{
      setView("View Live Class(es)")
   },[])
   useEffect(() => {
       if (classes.length > NO_OF_ITEMS_PER_PAGE) {
           setPageNum((classes.length / NO_OF_ITEMS_PER_PAGE) + ((classes.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
       }
   }, [classes.length]);

   const handleStartClass = (eClass) =>{
      const link = eClass.link;
      if(link){
         if(!restApi.checkNetwork()) return false;
         restApi.getApi(`/learning/start-meeting/${link}`,true)
         .then(data => {
            //console.log(data,'data')
            if(data.isSuccessful){
               const url = new URL(`https://class.sight-eduplus.com/${data.data}`);
               window.open(url,'_blank');
               // dispatch(editVirtualClass({...eClass,link:null}))
            }
            else{
               setModalObject({header:"Message",body:<p>
                  {data.message}
               </p>,footer:''});
               setModalToggle(true);
            }           
         })
         .catch(err => console.log(err,'error'));
      }
   }
  
   const More =({onClick}) =><div onClick={onClick}><StartIcon /> Start/Join Class</div>;
   
   const handleBtn = (text, index)=>{
      navigator.clipboard.writeText(text);
      setCopyText(index);
  
      setTimeout(() => {
          setCopyText(-1);
      }, 3000);
   }

   return ( 
        <>
        <Loading shouldShow={showLoading} />
            <Container>
               <div></div>
               <div>
                  {classes && classes.length > 0 ?
                     classes.map((eClass,index) => 
                     index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
                     <div key={eClass.id}>
                        <EditComponent more={eClass.link?<More onClick={()=>handleStartClass(eClass)} />:''} index={index} menuIndex={menuIndex} setMenuIndex={setMenuIndex} />
                        <div>
                           <span>Class Title: </span>
                           <span>{eClass.title}</span>
                        </div>
                        <div>
                           <span>Description: </span>
                           <span>{HTMLReactParser(eClass.description)}</span>
                        </div>
                        <div>
                           <span>Start Time: </span>
                           <span>{convertDatetoString(eClass.start_time)}</span>
                        </div>
                        <div>
                           <span>Duration: </span>
                           <span>{eClass.duration} Mins</span>
                        </div>
                        {
                           !eClass.is_institution && (
                              <div>
                                 <span>Virtual Class Link: </span>
                                 <Button style={copyText === index ? {color:'white',backgroundColor:'var(--secondary-color)'}:{}} onClick={()=>handleBtn(`https://class.sight-eduplus.com/${eClass.link}?d=0`, index)} disabled={copyText==index}>{copyText==index?'Link Copied':'Copy Link'}</Button>
                              </div>
                           )
                        }
                        {/* <div class="link"><span>Action: </span>
                           <span>Click 
                              <a target="_blank" href={`https://www.class.sight-eduplus.com/${eClass.link}`}>here
                              </a> 
                              to start class
                           </span>
                        </div> */}
                     </div>)
                  : <p style={{ textAlign: 'center', width: '100%' }}>No Class Available</p> 
                  }

               </div>
               {classes.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

            </Container>
        </>
     );
}
 
export default ViewClass;

const Button = styled.button`
    color:var(--secondary-color);
    border:1px solid var(--secondary-color);
    padding:3px 6px;
    border-radius: 5px;
    width: fit-content;
    font-size:12px;
    background-color: white;
`

export const Container  = styled.div`
   width:100%;
   padding:20px 20px 40px;
   & > div:nth-of-type(2){
      display: flex;
      flex-direction: column;
      gap:20px;
   }

   & > div:nth-of-type(2) > div{
      box-shadow: var(--box-shadow);
      ${'' /* padding:20px 10px; */}
      border-radius: 8px;
      position: relative;
      gap:10px;
      display: flex;
      flex-direction:column;

   }
   & > div:nth-of-type(2) > div > div{
      flex:1;
      ${'' /* display:flex; */}
      gap:30px;
   }
   & > div:nth-of-type(2) > div > div.link{
      gap:10px;  
   }
   & > div:nth-of-type(2) > div > div > span:nth-of-type(2){
      color:var(--primary-color);
   }
   
   & a{
      color:var(--secondary-color);
   }
   
`