import React, { useContext,useState,useEffect }from 'react'
import {Link,useLocation} from 'react-router-dom';
import css from './css/servicesCss.module.css'
import {ScreenContext} from './Home';
import {EachService} from './index';
import { TiThMenu } from 'react-icons/ti';

const serviceArr = [
  //category 1 is Institution
  //category 2 is Tutor | Educator
  //category 3 is Student
  {
    name:'LEARNING SERVICE',
    categories:[1,2],
    image:'./images/learning.png',
    description: `Train students, workers with Eduplus advance E-learning tools which include online class portal, assignment, notes, lesson management and more.`,
    features:[`Schedule and conduct engaging online classes`,
    `Create and share notes with ease`,
    "Course creation and management",
    `Create, assign and score task/assignments`,
    `Micro Learning (coming soon)`,
    `Gamified Learning (coming soon)`
  ],
  },
    {
    name:'QUESTION BANK',
    categories:[1,2,3],
    image:'./images/question.png',
    description: `Sight-eduplus question bank have more than 10,000 questions across different areas for personal(interviews, scholarships & school exams) and organization(hiring, scholarships, training) testing purposes`,
    features:[`Multiple question category to choose from including ICANN, programming, WAEC, JAMB, SAT, etc`,
    `Create and sell personal questions`,
    `Questions can be read, imported for CBT or for personal test`,
    `AI(Artificial intelligence) question generation from files/links/notes (coming soon)`
  ],

  },
  {
    name:'CBT SERVICE',
    categories:[1,2,3],
    image:'./images/cbt.png',
    description: `Test students, potential employees, workers in an easy, fast, secure, efficient and cost effective way.`,
    features:[`Set exam/test for unlimited numbers of test takers`,
    `Live coverage/monitoring of your exams/test`,
    `AI(Artificial intelligence) exam monitoring`,
    `Set & collect fees for your exams`,
    `Access to more than 10,000 relevant questions`,
    `Ability to create and use personal questions`,
    `Highly customization to suit your various needs`],
  },
    {
    name:'RESULT MANAGEMENT',
    categories:[1,2],
    image:'./images/result.png',
    description: `Create and manage users (students, sub-users, staff etc) result/report/transcript/certificate easily and efficiently. Save tens or hundreds of hours in result computation.`,
    features:[`Manage results/transcript, issue certificates with ease & more efficiently`,
    `Create your own result/transcript/certificate template using EduPlus result template builder`,
    `Export result/report to multiple formats such as PDF, excel etc`,
    `Set & collect fees for result/transcript/certificate viewing`],

  },
  {
    name:'INSTITUTION MANAGEMENT',
    categories:[1,2],
    image:'./images/institution.png',
    description: `Manage your institution(s), group(s) efficiently with information, attendance, performance management and many more.`,
    features:[`Manage efficiently all kind of institutions be it tertiary institution, k-12, tutorial centers or private lessons.`,
    `Register staffs, students, parent or any category of user on your account and assign necessary permissions for easy management`,
    `Attendance management system`,
    `Timetable management system`,
    `Customise EduPlus to suit your school`,
    `Easy and convenient access to all personel data from anywhere and anytime`,
    `Host communities/membership(comming soon)`
  ],
    
  },
  {
    name:'PAYMENT SERVICE',
    categories:[1,2],
    image:'./images/payment.png',
    description: `Manage your finances including sight-eduplus billing and personal finances effectively`,
    features:[`Make and manage money in Sight-eduplus`,
    `Seemless & hassle-free payments methods`,
    `Easy and convenient access to all personel data from anywhere and anytime`],

  },
  {
    name:'PORTAL SERVICE',
    categories:[1,2],
    image:'./images/portal.png',
    description: `Create online forms and websites easily and in the most cost effective way`,
    features:[
      `Create online forms (admission, registration, etc) `,
      `Create desktop and mobile responsive websites for your business (coaching classess, educational institution and many more)`,
    `Easy to use interface across any device: mobile phone, tablet or laptop or desktop`
  ],

  },
  {
    name:'EVENT MANAGEMENT',
    categories:[1,2],
    image:'./images/calender.png',
    description: `Manage your calender and other event effectively to get best input from others.`,
    features:[`Ability to reach out to your users accross various medium`,
    `Import and manage email list`,
    `Send period and schedule notice to your users effortlessly`],
  },
  {
    name:'TEST SERVICE',
    categories:[1,2],
    image:'./images/test.png',
    description: `Test service enables users to create timed exams for themselves mimiking real life scenarios`,
    features:[`Simulate personal test exam and boost your retention and confidence in real time tests`,
    `Easy to use interface across any device: mobile phone, tablet or laptop or desktop`,
    `Personal report and analytics on learning activites`],

  }

]


const Services = () => {
  const {smallScreen} = useContext(ScreenContext);
  const [serviceList,setServiceList] = useState([]);
  const [activeHash, setActiveHash] = useState(null);
  const [toggle, setToggle] = useState(false);
  const location = useLocation();
  useEffect(()=>{
    setServiceList(serviceArr);

  },[])

  useEffect(()=>{
    let hash = null;
    if(location.hash){
      const obj={
        institution:1,
        tutor:2,
        student:3
      }
      setActiveHash(location.hash.slice(1));
      hash = obj[location.hash.slice(1)];
    }
    else{
      setActiveHash(null);
    }

    setServiceList(!hash?serviceArr:serviceArr.filter(service => service.categories.some(e => e == hash)))

  },[location])
  return (
    <React.Fragment>
        <section >
          <div className={css.bannerDiv}>
            <div></div>
            <div></div>
            <div>
              <div>
                 <h1>Start Using Eduplus Today!</h1>
                 <p>
                  Whether you are a student, a tutor, a school owner or an educator (&nbsp;Coach&nbsp;/&nbsp;Trainer&nbsp;), Eduplus have services to cater for your needs.
                 </p>
                 <div>
                 <Link to='/account/login'>Get Started</Link>
                 </div>

              </div>
              <div>
                <img src='./images/edu1.png'/>
              </div>
            </div>

          </div>
          <div className={css.servicesDiv}>
            <div>
              {smallScreen && false ?<div className={css.mobileToggle}>

              </div>:
              <div className={`${css.desktopToggle} ${toggle && css.hover}`} onClick={()=>toggle && setToggle(false)}>
                  <Link to='#' className={activeHash == null?css.activeCategory:''}>All</Link>
                  <Link to='#institution' className={activeHash === 'institution'?css.activeCategory:''}>Institution</Link>
                  <Link to="#tutor" className={activeHash === 'tutor'?css.activeCategory:''}>Tutors | Educator</Link>
                <Link to="#student" className={activeHash === 'student' ? css.activeCategory : ''}>Student</Link>
                <TiThMenu className={css.after} onClick={()=>setToggle(!toggle)} />
              </div>}
              <div className={css.serviceContent}>
                {serviceList && serviceList.length > 0 ? serviceList.map(({name,image,categories,description,features},index) => <EachService key={index} name={name} image={image} categories={categories} description={description} features={features} ind={index}  /> ):<></>}
              </div>
            </div>
            
          </div>

        </section>
    </React.Fragment>
  )
}

export default Services