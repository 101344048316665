import { useContext, useEffect, useState } from "react";
import ResultCss from '../../../css/Result.module.css';
import Css from '../../../css/Categories.module.css';
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helperFunctions/Api2";

import { FormContext } from "./Form";
import Select from "../../../components/Select";
import { MenuContext } from "../DashBoard";
import SideOption from "../../../components/SideOption";
import Loading from "../../../components/loading";
import Modal from "../../../components/modal";
import { editForm } from "../../../redux/reducers/portalReducer";
import styled from "styled-components";

const FormUsers = () => {
    const { setTitle } = useContext(FormContext);
    const forms = useSelector(store => store.portal.forms);
    const { selected, changeSelected } = useContext(MenuContext);
    const [showLoading, setShowLoading] = useState(false);
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState('');

    const [formUsers, setFormUsers] = useState([]);
    const [selForm, setSelForm] = useState({});
    const dispatch = useDispatch();

    const handleDelete = (element) => {
        window.confirm('Please confirm delete')
        if (element.id) {
            setShowLoading(true);
            Api.PostApi('/form/delete-form-user', {
                id: element.id
            }, true)
                .then(result => {
                    setShowLoading(false);
                    setModalText(result.message);
                    setModalToggle(true);
                    if (result.isSuccessful) {
                        let newusers = formUsers.filter(user => user.id !== element.id);
                        setFormUsers(newusers);
                        let newform = { ...selForm, formUsers: newusers }
                        setSelForm(newform);
                        dispatch(editForm(newform));
                    };
                })
                .catch(error => {
                    setShowLoading(false);
                    setModalText(error.message);
                    setModalToggle(true);
                })
        } else {
            setModalText('Cannot delete form: No form id');
            setModalToggle(true);
        }
    }


    useEffect(() => {
        setTitle('Form Responses')
    },)

    const selectForm = (form) => {
        setFormUsers(form.formUsers);
        setSelForm(form);
    }
    return (
        <>
            <Loading shouldShow={showLoading} />
            <Modal modalObject={{ header: '', footer: '', body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <div className={Css.form}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: "baseline" }}>
                    <label className={Css.label}>
                        <span>Select Form</span>
                        <Select options={forms} onChange={selectForm} className={Css.fields} value="id" key='id' label='title' />
                    </label>
                </div>
                <div className={Css.viewQuestionContainer}>
                    {formUsers && formUsers.length > 0 ? formUsers.map((user, index) =>
                        <EachUser form={JSON.parse(selForm.form)} user={user} key={'user' + index} index={index} selected={selected} changeSelected={changeSelected}
                            handleDelete={handleDelete} />)
                        : "No user available!"}
                </div>
            </div>
        </>
    )
}

export default FormUsers;

const EachUser = ({ form, user, index, selected, changeSelected, handleDelete }) => {
    const [showOption, setShowOption] = useState(false);
    const [seeDetails, setSeeDetails] = useState(false);
    const pages = JSON.parse(user.form)
    useEffect(() => {

        if (selected === index) {
            setShowOption(true)
        }
        else { setShowOption(false) }

    }, [selected, index])
    return (<>
        <div className={Css.eachQuestion} style={{ paddingBottom: '27px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div><span>Created at: </span><span >{user.created_at}</span></div>
            </div>
            <SideOption element={user} index={index} showOption={showOption} changeSelected={changeSelected} handleDelete={handleDelete} />
            <button onClick={() => setSeeDetails(!seeDetails)}>{!seeDetails ? 'see more...' : 'see less...'}</button>
            {seeDetails && <div className={Css.viewQuestionContainer}>
                {pages.map((page, index) => <EachPage key={index} page={page} form={form[index]} />)}
            </div>
            }
            <div></div>

        </div>
    </>)
}

const EachPage = ({ page, form }) => {
    return (<>
        <div className={Css.eachQuestion} style={{ paddingBottom: '27px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div><span>Title: </span><span >{form.title}</span></div>
            </div>
            <div className={Css.viewQuestionContainer}>
                <b>Questions</b>
                {form.questions.map((question, index) => <EachQuestion key={index} question={question} answer={page[index]} index={index} />)}
            </div>
            <div></div>
        </div>
    </>)

}
const EachQuestion = ({ question, answer, index }) => {
    console.log(question, answer);
    return (<>
        <div className={Css.eachQuestion} style={{ paddingBottom: '27px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                    <span className={ResultCss.index} style={{ color: 'var(--secondary-color)', display: 'inline-block' }}>{index + 1}</span>
                    <span > &nbsp;{question.question}</span>
                </div>
                <div><b>Answer: </b> {question.answer_type === 'file' ? <Button onClick={()=>window.open("https://api.sight-eduplus.com/upload/forms/" + answer, '_blank')}>View file</Button> :<span>{answer}</span>}</div>
            </div>

        </div>
    </>)

}

const Button = styled.button`
background: none;
color: var(--secondary-color);
border: 1px solid var(--secondary-color);
padding: 10px;
border-radius: 5px;
outline: none !important;

:hover{
    background: #99999922;
}
`