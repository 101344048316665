import { useEffect, useState, useRef, createContext } from 'react';
import { Routes, Route, Link, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  BsChevronLeft, BsChevronRight, BsCreditCard, BsDoorOpen,
  BsExclamationCircle, BsFillPersonFill, BsPersonFill, BsColumnsGap,
} from 'react-icons/bs';
import styles from './../../css/DashBoard.module.css';
import modalcss from "../../css/modal.module.css";
import Services from './Services';
import SideNav from './SideNav';
import ResultMgmt from './Result/ResultMgmt';
import QuestionBank from './QuestionC/index';
import InstitutionMgmt from './institution/InstitutionMgmt';
import ComingSoon from '../ComingSoon/ComingSoon';
import Api from "../../helperFunctions/Api2";
import { setCategory, setQuestions, setUserInstitution, setImages, setUserPrivileges, setPublicCategory } from "../../redux/reducers/questionReducer";
import { setVirtualClass, setAssignments, setNotes, setMarketData } from '../../redux/reducers/learningReducer';
import { setCbts } from '../../redux/reducers/cbtReducer';
import { setPaymentLink, setService, setTotalBills, setTransactions } from '../../redux/reducers/accountReducer';
import { setForms, setWebsites } from '../../redux/reducers/portalReducer';
import { setBiling } from '../../redux/reducers/billingReducer';
import Cbt from "./cbt/index";
import CalenderMgt from './Calender/calenderEventmgt';
import Payments from './Payments/paymentMgmt';
import Learning from './Learning/index';

import Logo from "../../images/edu-logo.png";
import Portal from './Portal/FormMgmt';
import { setTest } from '../../redux/reducers/testReducer';
import { setAppointments, setEmailGroup, setEvents } from '../../redux/reducers/eventReducer';
import { setUser } from '../../redux/reducers/accountReducer';
import { RiLockPasswordFill } from "react-icons/ri";
import { setResultSets, setTemplates, setAllUserResult, setCourses } from '../../redux/reducers/resultReducer';
import { MdContactSupport, MdSupportAgent } from 'react-icons/md';
import Modal from '../../components/modal';
import { Button } from '../../components/Button';
import Loading from '../../components/loading';
import { setFiles } from '../../redux/reducers/serviceReducer';

export const MenuContext = createContext()
let accountCount = 0, timeCount = 0; let timer;

const DashBoard = () => {
  const [showProfile, setShowProfile] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showDetail, setShowDetail] = useState(window.innerWidth >= 767 || window.innerWidth < 501);
  const [viewScreenSize, SetViewScreenSize] = useState(window.innerWidth);
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [modalToggle, setModalToggle] = useState(false);
  const [modalText, setModalText] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  //const [errcount, setErrcount] = useState(0);
  const [showSidebar, setShowSidebar] = useState(window.innerWidth > 500);
  const [selected, changeSelected] = useState(null);
  const [request, setRequest] = useState('');
  const user = useSelector((state) => state.account.username);
  const [fullScreenComponent, setFullScreenComponent] = useState(null);

  const parentRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleQuestions = (data) => {
    let result = Object.values(data.value);
    let all_questions = [];
    if (data && result.length > 0) {
      result.forEach(eData => {
        let arr = eData.questions;
        if (arr.length > 0) {
          arr.forEach(eArr => {
            all_questions.push(eArr);
          })
        }
      })
    }
    dispatch(setCategory(result));
    dispatch(setQuestions(all_questions));
  }

  const fetchItems = async () => {
    if (!Api.checkNetwork()) return;
    Api.getApi('/user/get-user-details', true)
      .then(result => {
        console.log(result, 'result');
        /*const categories = result.categories.value;
        const questions = result.questions.value; 
        const userInstitution = result.institutions.value;*/
        // const value ='value',label="label";
        // let newCategories = categories.map(element => ({...element,[value]:element.id,[label]:element.name}))
        // if(!categories.length <= 0){
        //   categories = []
        // }
        setIsAuthenticated(true);
        try {
          dispatch(setFiles(result.files.value));
          handleQuestions(result.question_categories);   
          dispatch(setUser(result.user));
          dispatch(setService(result.services.value));
          dispatch(setUserInstitution(result.institutions.value));
          dispatch(setImages(result.userImages.value));
          dispatch(setCbts(result.cbts.value.data));
          dispatch(setEvents(result.calendar.value.events.value));
          dispatch(setAppointments(Object.values(result.appointments.value)));
          dispatch(setEmailGroup(result.calendar.value.email_groups.value));
          dispatch(setPublicCategory(result.public_categories.value));
          // dispatch(setQuestions(result.questions.value));
          dispatch(setUserPrivileges(result.privileges.value));
          dispatch(setTest(result.tests.value));
          dispatch(setVirtualClass(result.virtual_class.value));
          dispatch(setAssignments(result.assignments.value));
          dispatch(setNotes(result.notes.value))
          dispatch(setBiling(result.bills.value.data));
          dispatch(setPaymentLink(result.payments.value.paymentLinks));
          dispatch(setTotalBills(result.billTransactions.value.bills));
          dispatch(setTransactions(result.billTransactions.value.transactions));
          dispatch(setForms(result.portals.value));
          // dispatch(setWebsites(result.portals.websites));
          dispatch(setResultSets(result.all_results.value.setsandGroups))
          dispatch(setAllUserResult(result.all_results.value.usersResults));
          dispatch(setTemplates(result.all_results.value.template));
          dispatch(setCourses(Object.values(result.courses.value)))
          //dispatch(setBanks(result.banks.value));
          dispatch(setWebsites(result.websites.value));
        }
        catch (e) { //console.log(e)
         }
      })
      .catch(err => {
        //setErrcount(errcount+1); //
        // console.log(10-errcount ,' times before logout');//
        // setIsAuthenticated(true) //
        localStorage.removeItem('eduplus_tkn'); //
        //navigate('/account/sign-in'); //
        const inst_id = localStorage.getItem('edu_inst_id');
        if(inst_id) navigate(`/account/sign-in/${inst_id}`);
        else navigate('/account/sign-in');
      });

    Api.getApi('/learning/get-public-learning-resource', true).then(res => {
      res.isSuccessful && dispatch(setMarketData(res.data));
    });
  };

  /*useEffect(()=>{
    if(errcount>10){
        sessionStorage.removeItem('eduplus_tkn');
        navigate('/account/login');
    }
  },[errcount])*/

  useEffect(() => {
    //   //sessionStorage.removeItem('eduplus_tkn');
    if (localStorage.getItem("eduplus_tkn") === null) {
      //navigate('/account/sign-in');//
      const inst_id = localStorage.getItem('edu_inst_id');
      if(inst_id) navigate(`/account/sign-in/${inst_id}`);
      else navigate('/account/sign-in');
    }
    else {
      fetchItems()
      window.addEventListener('click', () => {
        //dispatch(resetCount());
        //setAccountCount(0);
        accountCount = 0;
      })

      timer = setInterval(() => {
        accountCount++; timeCount++;
        if (timeCount > 35) {
          Api.getApi('/account/re-authenticate', true)
            .then(result => {
              //console.log(result, 'api result')
              if (result.isSuccessful) {
                localStorage.setItem('eduplus_tkn', result.token);
              }
            })
          if (Api.checkNetwork()) {
            timeCount = 0;
            accountCount = 0;
          }
        }
        if (accountCount > 30) {
          accountCount = 0;
          timeCount = 0;
          localStorage.removeItem('eduplus_tkn');
          const inst_id = localStorage.getItem('edu_inst_id');
          if(inst_id) navigate(`/account/sign-in/${inst_id}`);
          else navigate('/account/sign-in');
        }
      }, 60000)
    }
    return () => {
      clearInterval(timer);
      //window.removeEventListener('click')
    }
  }, []);


  const toggleDetail = () => {
    setShowDetail(!showDetail);
  };

  const handleShowNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleShowProfile = () => {
    setShowProfile(!showProfile);
  };
  const handleMenu = () => {
    showMenu && setShowMenu(false);
    selected !== null && changeSelected(null);
  }

  useEffect(() => {
    if (parentRef) {
      //console.log(viewScreenSize,showDetail);
      if (showDetail && viewScreenSize <= 1200) {
        parentRef.current.children[0].firstChild.style.width = '100%';
      }
      else if (showDetail && viewScreenSize > 1200) {
        parentRef.current.children[0].firstChild.style.width = '80%';
      }
      else if (!showDetail && viewScreenSize > 1000) {
        parentRef.current.children[0].firstChild.style.width = '80%';
      }
      else if (!showDetail && viewScreenSize <= 1000) {
        parentRef.current.children[0].firstChild.style.width = '100%';
      }

    }
  }, [location.pathname, viewScreenSize, showDetail])

  useEffect(() => {
    setShowSidebar(viewScreenSize > 500);
  }, [viewScreenSize])

  useEffect(() => {
    const handleScreenSize = () => { SetViewScreenSize(window.innerWidth) };
    handleScreenSize();
    window.addEventListener('resize', handleScreenSize);

    return () => window.removeEventListener('resize', handleScreenSize);

  }, [])

  const handleChangePassword = () => {
    if (window.confirm('Are you sure you want to change your password? Changing your password will automatically log you out')) {
      sessionStorage.removeItem('eduplus_tkn');
      navigate("/account/sign-in");
      Api.getApi('/account/change-password', true)
        .then(result => {

        })
    }
  }
  const sendRequest = (e) => {
    e.preventDefault();
    if (request === '' || request.length < 9) {
      alert('Please type a valid request')
      return false;
    }
    Api.PostApi('/institution/make-request', { request }, true)
      .then(result => {
        alert(result.message)
        setRequest('');
      })
  }

  return (
    <>
      <Loading shouldShow={showLoading} />
      <Modal modalTogglee={modalToggle} modalObject={{ header: "", footer: '', body: modalText }} closeModal={() =>{ setModalToggle(false); setModalText(''); }} />
      <Modal modalTogglee={showModal} modalObject={{ header: "Send a request for features you'll like to see", footer: '', body: '' }} closeModal={() => setShowModal(false)}>
        <textarea className={styles.textarea} value={request} onChange={(e) => setRequest(e.target.value)} />
        <Button onClick={sendRequest}>Send Request</Button>
      </Modal>
      {fullScreenComponent && <FullScreen component={fullScreenComponent} setFullScreenComponent={setFullScreenComponent} />}
      <div className="whiteblank" style={{ display: isAuthenticated ? `none` : `flex` }}>
        <div>Please Wait....</div>
      </div>
      <div className={`${styles.wrapper}`} onClick={handleMenu} style={{ display: isAuthenticated ? `block` : `none` }}>
        <div className={`${styles.main}`}>
          {/* <Header /> */}
          <div className={`${styles.sideNav}`}>
            <SideNav showDetail={showDetail} setShowDetail={setShowDetail} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
          </div>
          <div className={`${styles.contentDiv}`}>
            <nav className={`${styles.topNav}`}>
              <div className={`${styles.displayToggle}`} onClick={toggleDetail} title={showDetail ? 'Collapse the Side Bar' : 'Expand the Side Bar'}>
                {showDetail ? <BsChevronLeft /> : <BsChevronRight />}
              </div>
              <div className={styles.mobileview}>
                <span onClick={() => {
                  setShowSidebar(true);
                  if (viewScreenSize < 500) {
                    setShowDetail(true);
                  }
                }}><BsColumnsGap style={{ fontSize: 30 }} /></span>
                <img src={Logo} height="30" alt='logo' />
              </div>
              <div className={`${styles.navContent}`}>
                <div className={`${styles.profileDiv}`}>
                  <MdContactSupport className={`${styles.profileIcon}`} onClick={() => {
                    handleShowNotifications();
                    setShowProfile(false);
                  }} />
                  {showNotifications &&
                    <div className={`${styles.popUpContainer}`} >
                      <div className={`${styles.dismissalDiv}`} onClick={() => {
                        handleShowNotifications();
                        setShowProfile(false);
                      }} />
                      <div className={`${styles.navPopup}`}>
                        <Link to='/support' target='blank'>
                          <MdSupportAgent className={`${styles.navPopupLogo}`} /> <span>FAQ & Support</span>
                        </Link>
                        <Link onClick={() => setShowModal(true)}>
                          <BsExclamationCircle className={`${styles.navPopupLogo}`} /> <span>Make request</span>
                        </Link>
                      </div>
                    </div>
                  }
                </div>
                <div className={`${styles.profileDiv}`}>
                  <BsPersonFill className={`${styles.profileIcon}`} onClick={() => {
                    handleShowProfile();
                    setShowNotifications(false);
                  }} />
                  {showProfile &&
                    <div className={`${styles.popUpContainer}`} >
                      <div className={`${styles.dismissalDiv}`} onClick={() => {
                        handleShowProfile();
                        setShowNotifications(false);
                      }} />
                      <div className={`${styles.navPopup}`}>
                        <Link to=''><BsFillPersonFill className={`${styles.navPopupLogo}`} />{user.name}</Link>
                        <Link to=''><BsCreditCard className={`${styles.navPopupLogo}`} /> $0.000</Link>
                        <span onClick={() => {
                          handleChangePassword();
                          // below code moved to inside the above function
                          // sessionStorage.removeItem('eduplus_tkn');
                          // navigate("/account/sign-in");
                        }}><RiLockPasswordFill className={`${styles.navPopupLogo}`} /> Change Password</span>
                        <span style={{ cursor: 'pointer' }} onClick={() => {
                          sessionStorage.removeItem('eduplus_tkn');
                          localStorage.removeItem('eduplus_tkn');
                          //navigate("/account/sign-in");
                          const inst_id = localStorage.getItem('edu_inst_id');
                          localStorage.removeItem('edu_inst_id');
                          if(inst_id) navigate(`/account/sign-in/${inst_id}`);
                          else navigate('/account/sign-in');
                        }}><BsDoorOpen className={`${styles.navPopupLogo}`} /> Logout</span>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </nav>
            <div className={`${styles.contentView}`} style={{ display: 'flex', flexWrap: 'wrap' }} ref={parentRef} >
              <MenuContext.Provider value={{ showMenu, setShowMenu, selected, changeSelected, setShowLoading, setModalText, setModalToggle, modalToggle, setFullScreenComponent }}>
                <Routes>
                  <Route index element={<Services />} />
                  <Route path="/institution-management/*" element={<InstitutionMgmt />} />
                  <Route path="/question-bank/*" element={<QuestionBank />} />
                  <Route path="cbt/*" element={<Cbt />} />
                  <Route path="/event-management/*" element={<CalenderMgt />} />
                  <Route path="/result-management/*" element={<ResultMgmt />} />
                  <Route path="/learning/*" element={<Learning />} />
                  <Route path="/payments/*" element={<Payments />} />
                  <Route path="/portal/*" element={<Portal />} />
                  <Route path="/*" element={<ComingSoon />} />

                </Routes>
              </MenuContext.Provider>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default DashBoard;
const FullScreen = ({ component, setFullScreenComponent }) => {
  return <div className={`${modalcss.modal}`} style={{ display: 'block', padding: 0, overflow: 'unset', backgroundColor: 'white' }}>
    <div className={modalcss.modal_header} style={{
      padding: '0px 5px', position: 'absolute', right: '5px', top: '-6px', zIndex: '25',
      backgroundColor: 'rgb(204, 204, 204)', display: 'inline-block',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px'
    }}>
    <span className={modalcss.close} onClick={()=>setFullScreenComponent(null)}>&times;</span>
    </div>
    {component}
  </div>;
};