import { createContext, useState } from 'react';
import styles from './../../../css/StaffMgmt.module.css';
import titleStyle from './../../../css/SectionTitle.module.css';
import { Routes, Route,  } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import AddUsers from './AddUser';
import ViewUsers from './ViewUsers';
import Menu from '../../../components/Menu';
import AddInvite from './AddInvite';
import ViewInvites from './ViewInvites';

export const staffContext = createContext();
export const EditContext = createContext();

const StaffMgmt = () => {
  const [title, setTitle] = useState('');
  const [view, setView] = useState();
  const [editData,setEditData] = useState({});
  const [editing,setEditing] = useState(false);
  const [editUserData,setEditUserData] = useState({});
  const [editingUser,setEditingUser] = useState(false);
  const [editCategory, setEditCategory ] = useState({});

  const clearEdit=()=>{
    setEditing(false);
    setEditData({})
    setEditingUser(false);
    setEditUserData({});
    setEditCategory({})
  }

  return (
    <staffContext.Provider value={{ view, setView }}>
      <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <h1 className={`${styles.title}`}>User Management</h1>
        <div className={`${styles.subView}`}>
          <div className={titleStyle.sectionTitle}>
            <h3 className={titleStyle.h3}>
              {title}
            </h3>
            <Menu options={[
              {label:'Add User',path:'add-user',onClick:()=>clearEdit()},
              {label:'View Users',path:'users',onClick:()=>clearEdit()},
              {label:'Invite Users',path:'invite-users',onClick:()=>clearEdit()},
              {label:'View Invites',path:'view-invites',onClick:()=>clearEdit()},
            ]} />
          </div>
          <div style={{width:'100%'}} >
          <EditContext.Provider value={{editData,setEditData,editing,setEditing,clearEdit,editUserData,setEditUserData,editingUser,setEditingUser,  editCategory, setEditCategory}}>
          <Routes>
            {/* <Route path='*' element={<AddUsers setTitle={setTitle} />} /> */}
            <Route path="*" element={<ViewUsers setTitle={setTitle} />}/>
            <Route path='/add-user' element={<AddUsers setTitle={setTitle} />} />
            <Route path='/users' element={<ViewUsers setTitle={setTitle} />} />
            <Route path='/invite-users' element={<AddInvite setTitle={setTitle} />} />
            <Route path='/view-invites' element={<ViewInvites setTitle={setTitle} />} />
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
          </EditContext.Provider>
          </div>
        </div>
      </div>
    </staffContext.Provider>
  );
};

export default StaffMgmt;