import { createContext, useState } from 'react';
import styles from './css/StaffMgmt.module.css';
import titleStyle from './css/SectionTitle.module.css';
import { Routes, Route } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import {TakeTest,AnalysisPage} from './index';
import Menu from '../../../components/Menu';

export const Context = createContext();

const Test = () => {
    const [title, setTitle] = useState('');
    const [editData, setEditData] = useState(null);
    return (
        <Context.Provider value={{ editData, setEditData }}>
            <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <h1 className={`${styles.title}`}>Test</h1>
                <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3}>
                            {title}
                        </h3>
                        <Menu options={[
                            {label:'Take Test',path:'take-test'},
                            {label:'Tests Analysis',path:'analysis'}
                        ]} />
                    </div>
                    <Routes>
                        <Route path='*' element={<AnalysisPage setTitle={setTitle} />} />
                        <Route path='/take-test' element={<TakeTest setTitle={setTitle} />} />
                        <Route path='/analysis' element={<AnalysisPage setTitle={setTitle} />} />
                        <Route path="/*" element={<PageNotFound />} />
                    </Routes>
                </div>
            </div>
        </Context.Provider>
    );
};

export default Test;