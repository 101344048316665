import { useContext, useEffect, useState } from "react";
import Modal from "../../../components/modal";
import ResultCss from '../../../css/Result.module.css';
import CategoriesCss from '../../../css/Categories.module.css';
import { useDispatch, useSelector } from "react-redux";
import { MenuContext } from "../DashBoard";
import Api from "../../../helperFunctions/Api2";
import { useNavigate } from "react-router-dom";
import { deleteForm } from "../../../redux/reducers/portalReducer";
import SideOption from "../../../components/SideOption";
import Loading from "../../../components/loading";
import { FormContext } from "./Form";
import HTMLReactParser from "html-react-parser";
import { Button } from "../../../components/Button";
import PageNumber from '../../../components/PageNumber';
const NO_OF_ITEMS_PER_PAGE = 30;

const ViewForms = () => {
    const { setTitle, setEditing, setEditData } = useContext(FormContext);
    const forms = useSelector(store => store.portal.forms);
    const [modalToggle, setModalToggle] = useState(false);
    const { selected, changeSelected } = useContext(MenuContext);
    const [showLoading, setShowLoading] = useState(false);
    const [modalText, setModalText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDelete = (element) => {
        window.confirm('Please confirm delete')
        if (element.id) {
            setShowLoading(true);
            Api.PostApi('/form/delete-form', {
                id: element.id
            }, true)
                .then(result => {
                    setShowLoading(false);
                    setModalText(result.message);
                    setModalToggle(true);
                    if (result.isSuccessful) {
                        dispatch(deleteForm(element.id))
                    };
                })
                .catch(error => {
                    setShowLoading(false);
                    setModalText(error.message);
                    setModalToggle(true);
                })
        } else {
            setModalText('Cannot delete form: No form id');
            setModalToggle(true);
        }
    }
    const handleEdit = (element) => {
        if (element.id === 'default') {
            setModalText('Cannot edit Default template');
            setModalToggle(true);
        } else {

            setEditData(element);
            setEditing(true);
            navigate('../create-form');
        }
    };
    useEffect(() => {
        setTitle('View Forms')
    }, [setTitle])
    useEffect(() => {
        if (forms.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((forms.length / NO_OF_ITEMS_PER_PAGE) + ((forms.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
        }
    }, [forms.length]);
    return (
        <>
            <Loading shouldShow={showLoading} />
            <Modal modalObject={{ header: '', footer: '', body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <div className={CategoriesCss.form}>
                <div className={CategoriesCss.viewQuestionContainer} onClick={() => (selected !== null) && changeSelected(null)}>
                    {forms.length > 0 ? forms.map((form, index) =>
                        index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
                        <EachForm key={index} form={form} index={index} selected={selected} changeSelected={changeSelected} handleDelete={handleDelete} handleEdit={handleEdit} />
                    ) : 'No available forms'}
                </div>
                {forms.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}
            </div>
        </>
    )

}
export default ViewForms;

const EachForm = ({ form, index, selected, changeSelected, handleDelete, handleEdit }) => {
    const [showOption, setShowOption] = useState(false);
    const [seeMore, setSeeMore] = useState(false);
    const [copyText, setCopyText] = useState(-1);
    const paymentLinks = useSelector(store => store.account.paymentLinks);

    const handleBtn = (text, index) => {
        navigator.clipboard.writeText(text);
        setCopyText(index);

        setTimeout(() => {
            setCopyText(-1);
        }, 1500);
    }
    useEffect(() => {
        if (selected === index) {
            setShowOption(true);
        } else {
            setShowOption(false);
        }
    }, [form, index, selected])

    return (
        <div className={CategoriesCss.eachQuestion} style={{ paddingBottom: '27px' }}>
            <div style={{alignItems: "flex-start" }}>
                <div style={{ flexDirection: 'row', alignItems: "flex-start" }}><b>Title:&nbsp;</b> {form.title}</div>
                {form.payment_link_id && <div>
                    <span>Payment Link: </span>
                    <span className={CategoriesCss.value}>{paymentLinks[form.payment_link_id]?.title}</span>
                </div>}
                <Button style={copyText === index ? { padding: 5, backgroundColor: 'var(--secondary-color)', color: 'white' } : { padding: 5, backgroundColor: 'white', color: 'var(--secondary-color)' }} onClick={() => handleBtn(`https://portal.sight-eduplus.com/${form.link}?d=0`, index)} disabled={copyText === index}>{copyText === index ? 'Link Copied' : 'Copy Link'}</Button>
            </div>
            <SideOption element={form} index={index} changeSelected={changeSelected} handleDelete={handleDelete} handleEdit={handleEdit} showOption={showOption} />
            {JSON.parse(form.form).length > 0 && <button onClick={() => setSeeMore(!seeMore)}>{!seeMore ? 'show' : 'hide'} pages...</button>}
            {seeMore && <>
                {Boolean(form.add_instruction) && <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}><b>instruction:&nbsp;&nbsp;</b>{HTMLReactParser(form.instruction)}</div>}
                {JSON.parse(form.form).map((page, index) => <Page key={index} page={page} index={index} />)}
            </>}
            <div></div>
        </div>
    )
}
const Page = ({ page, index, }) => {
    const [seeMore, setSeeMore] = useState(false);

    return (
        <div className={ResultCss.eachPage}>
            <span className={ResultCss.index}>{index + 1}</span>
            <div >
                <div><b>Title:</b>&nbsp;&nbsp;{page.title}</div>
                {page.add_instruction && <div><i>instruction:</i>&nbsp;&nbsp;{page.instruction}</div>}
            </div>
            <button onClick={() => setSeeMore(!seeMore)}>{!seeMore ? 'show' : 'hide'} questions...</button>
            {seeMore && page.questions.map((question, index) => <Question key={index} question={question} index={index} />)}
        </div>
    )
}
const Question = ({ question, index, }) => {
    const [seeMore, setSeeMore] = useState(false);

    return (
        <div className={ResultCss.eachPage}>
            <span className={ResultCss.index} style={{ color: 'var(--secondary-color)' }}>{index + 1}</span>
            <div style={{ display: 'block', height: '7 0px', }}>
                <div><b>Question:</b>&nbsp;&nbsp;{question.question}</div>
                {seeMore && <>
                    <div><i>Answer Type:</i>&nbsp;&nbsp;{String(question.answer_type).replace('_', ' ')}</div>
                    {question.answer_type === 'select' && <div>
                        <b><br />Options</b>
                        {question.options.map((option, index) => (
                            <div key={index}>{index === 0 ? '(A) ' : index === 1 ? '(B) ' : index === 2 ? '(C) ' : index === 3 ? '(D) ' : index === 4 ? '(E) ' : index === 5 && '(F) '}{option}</div>
                        ))}
                        <br /></div>}
                    <div><i>Required:</i>&nbsp;&nbsp;{question.is_required ? 'True' : 'False'}</div>
                </>
                }
            </div>
            <button onClick={() => setSeeMore(!seeMore)}>{!seeMore ? 'show' : 'hide'} more...</button>
        </div>
    )
}