
import React, { useState } from "react";
import { useEffect } from "react";
import styling from '../../../css/Categories.module.css';
import eventstyle from './Css/event.module.css';
import styles from '../../../css/CreateInst.module.css';
import { useDispatch, useSelector } from "react-redux";
import Loading from '../../../components/loading';
import { eventModalContext } from "./events";
import { TiArrowSortedDown as ArrowDown } from 'react-icons/ti';
import { TiArrowSortedUp as ArrowUp } from 'react-icons/ti';
import Select from '../../../components/Select';
import { setUserEmailGroup } from "../../../redux/reducers/questionReducer";
import PageNumber from '../../../components/PageNumber';
const NO_OF_ITEMS_PER_PAGE = 30;



const ViewContact = ({ seTTitle }) => {
  const [selEmail, setSelEmail] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const Sms = useSelector(state => state.questions.user_sms);
  const SmsList = useSelector(state => state.questions.user_smsList);
  const [listArr, setListArr] = useState([]);
  const [openGrps, setOpenGrps] = useState(null);
  const [activeList, setActiveList] = useState([]);
  const [viewCon, setViewCon] = useState(false);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(0);


  const handleEditEmails = () => {

  }
  const handleDeleteEmails = () => {

  }

  const openGroups = (id) => {
    if (openGrps === id) {
      setOpenGrps(null)

    }
    else {
      setOpenGrps(id)
      setViewCon(false);
      console.log('working')
      setViewCon(true);
    }
  }
  const showList = (id) => {
    if (id) {
      let result = SmsList.filter(item => item.groupId === id)
      setListArr(result);
      console.log(result)
    }

  }

  useEffect(() => {
    setActiveList(Sms);
  }, [Sms])

  useEffect(() => {
    seTTitle('View Emails')
  }, [seTTitle])



  useEffect(() => {

  }, [selEmail]);
  useEffect(() => {
    if (activeList.length > NO_OF_ITEMS_PER_PAGE) {
      setPageNum((activeList.length / NO_OF_ITEMS_PER_PAGE) + ((activeList.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
    }
  }, [activeList.length]);
  return (

    <>
      <Loading shouldShow={showLoading} />

      <div className={`${styling.viewQuestionContainer} ${eventstyle.emailDiv}`}>
        {activeList && activeList.length > 0 ? activeList.map(({ id, groupName }, index) =>
          index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
          <>
            <div className={`${eventstyle.emails}`} key={id}>
              <div>
                {groupName}{openGrps === id ? <ArrowUp onClick={() => openGroups(id)} /> : <ArrowDown onClick={() => { openGroups(id); showList(id) }} />}
              </div>
              <div className={eventstyle.btn}>
                <span onClick={() => { handleEditEmails() }}>Edit</span>
                <span onClick={() => { handleDeleteEmails() }}>Delete</span>
              </div>
            </div>
            {viewCon && listArr && listArr.length > 0 ? listArr.map(({ contact, ind }) =>
              <div className={eventstyle.emails} key={ind} style={openGrps === id ? { display: 'flex' } : { display: 'none' }}>
                {contact}
                <div className={eventstyle.btn}>
                  <span onClick={() => { handleEditEmails() }}>Edit</span>
                  <span onClick={() => { handleDeleteEmails() }}>Delete</span>
                </div>
              </div>
            ) : ''
            }
          </>
        )
          : <p style={{ textAlign: 'center', width: '100%' }}>Create a Contact Group to view Groups</p>

        }
      </div>
      {activeList.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

    </>
  );
}

export default ViewContact;