import { createContext, useContext, useState, } from "react";
import { Link, Route, Routes } from "react-router-dom";
import styles from '../../../css/StaffMgmt.module.css';
import titleStyle from '../../../css/SectionTitle.module.css';
import { BsList } from "react-icons/bs";
import { MenuContext } from "../DashBoard";
import AddCourse from "./AddCourse";
import ViewCourses from "./ViewCourses";
import ManageCourses from "./ManageCourses";

export const CourseContext = createContext();
const Course = ()=>{
    const {showMenu, setShowMenu} = useContext(MenuContext);
    const [title, setTitle] = useState('');
    const [editing, setEditing] = useState(false);
    const [editData, setEditData] = useState(null);

    function clearEdit() {
        setEditing(false);
        setEditData(null);
    }
    return (

            <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <h1 className={`${styles.title}`}>Course</h1>
                <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3}>
                            {title}
                        </h3>
                        <div className={`${titleStyle.menuDiv}`}>
                            <span className={`${titleStyle.menuBtn}`} onClick={() => setShowMenu(!showMenu)}><BsList />Menu</span>
                            {showMenu &&
                                <div className={`${titleStyle.subMenu}`} >
                                    <ul>
                                        <li onClick={clearEdit}><Link to='add-course'>Add Course</Link></li>
                                        <li onClick={clearEdit}><Link to='view-courses'>View Courses</Link></li>
                                        <li onClick={clearEdit}><Link to='manage-courses'>Manage Courses</Link></li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                    
                    <CourseContext.Provider value={{editing, setEditing, editData, setEditData, clearEdit, setTitle}}>
                    <Routes>
                        <Route element={<AddCourse />} path={'/add-course'} />
                        <Route element={<ViewCourses />} path={'/view-courses'} />
                        <Route element={<ManageCourses />} path={'/manage-courses'} />
                        <Route element={<ViewCourses />} path={'/*'} />
                    </Routes>
                    </CourseContext.Provider>
                </div>
            </div>
    );
}
export default Course;