import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from '../../../css/CreateInst.module.css';
import { eventModalContext } from './events';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../../components/loading';
import Api, { convertDatetoInputValue } from '../../../helperFunctions/Api2';
import Select from '../../../components/Select';
import { addEvent, editEvent } from '../../../redux/reducers/eventReducer';
import { useNavigate } from 'react-router-dom';
import TextEditor from "../../../components/TextEditor";
import UploadedFiles from '../../../components/UploadedFiles';

const audienceArr = [
   { value: '0', label: "Email Group" },
   { value: "1", label: "Institution Group" },
   { value: "2", label: 'Public' }
];

const CreateEvents = ({ seTTitle }) => {
   const navigate = useNavigate();

   useEffect(() => {
      seTTitle('Create calender/events');
   }, [seTTitle]);

   const [eventTitle, setEventTitle] = useState('');
   const [eventDes, setEventDescription] = useState('');
   const [eventStartTime, setEventStartTime] = useState('');
   const [eventEndTime, setEventEndTime] = useState('');
   const [eventStartDate, setEventStartDate] = useState('');
   const [eventEndDate, setEventEndDate] = useState('');
   const [audienceType, setAudienceType] = useState(audienceArr[0]);
   const [defaultAudience, setDefaultAudience] = useState(audienceArr[0]);
   const [showLoading, setShowLoading] = useState(false);
   const [defaultInstitution, setDefaultInstitution] = useState("");
   const [defaultCategory, setDefaultCategory] = useState("");
   const [defaultGroup, setDefaultGroup] = useState("");
   const [selInstitution, setSelInstitution] = useState(null);
   const [selEmailGroup, setSelEmailGroup] = useState(null);
   const [categoryArr, setCategoryArr] = useState([]);
   const [sel_category, set_category] = useState([]);
   const [disableField, setDisableField] = useState(false);
   const [resetField, setResetField] = useState(false);
   const dispatch = useDispatch();
   const institutions = useSelector(state => state.questions.user_institutions);
   const emailGroup = useSelector(state => state.event.email_group);
   const { setModalObject, setModalToggle, editEventCon, setEditEventCon } = useContext(eventModalContext);
   const [imageModal, setImageModal] = useState(false);
   const [eventImage, setEventImage] = useState('');
   const editor = useRef('editor');

   const handleSelInstitution = (inst) => {
      let arr = [];
      if (inst && inst.categories && Object.keys(inst.categories).length > 0) {
         arr = Object.values(inst.categories);
      }
      setCategoryArr(arr);
      setSelInstitution(inst);

   };

   const validateSingleValues = (vals, msgs) => {
      let i, len = vals.length;
      let status = true;
      let msg = "";
      for (i = 0; i < len; i++) {
         const val = vals[i];
         if (val === '' || typeof val === 'undefined' || val === null) {
            status = false;
            msg = msgs[i];
            break;
         }
      }
      if (!status) {
         setModalObject({ header: "", body: `${msg}`, footer: "" });
         setModalToggle(true);
      }
      return status;
   };

   const formatObject = (obj) => {
      return {
         id: obj.id,
         subuser_id: obj.subuser_id ? obj.subuser_id : null,
         title: obj.event.title,
         description: obj.event.description,
         start_date: obj.event.start_date,
         end_date: obj.event.end_date,
         image: obj.event.image,
         categories: obj.is_institution === 1 ? [...obj.categories.map(eC => ({
            is_institution: obj.is_institution,
            category_id: eC
         }))] :
            [...obj.email_group.map(eC => ({
               is_institution: obj.is_institution,
               category_id: eC
            }))]
      };
   };

   const clearForm = () => {
      setEventTitle('');
      setEventDescription('');
      setEventStartDate('YYYY-MM-DD');
      setEventStartTime('H:mm:s');
      setEventEndDate('YYYY-MM-DD');
      setEventEndTime('H:mm:s');
      setResetField(!resetField);
      setDisableField(false);
      setEventImage('');
      if (editEventCon) {
         setEditEventCon(null)
      }
   };

   const handleSubmit = (e) => {
      e.preventDefault();

      let check = validateSingleValues([
         eventTitle,
         eventDes,
         eventStartDate,
         eventEndDate,
         eventStartTime,
         eventEndTime
      ], [
         "Please Enter Event Title",
         "Please Enter Event Description",
         "Please Enter valid Start Date",
         "Please Enter Valid End Date",
         "Please Enter Valid Start Time",
         "Please Enter Valid End Time"

      ]);
      if (!check) return false;
      if (audienceType.value === '1') {
         if (!selInstitution || !selInstitution.id) {
            setModalObject({
               header: "",
               body: "Select a valid Institution",
               footer: ""
            });
            setModalToggle(true);
            return false;
         }
         if (!sel_category || sel_category.length === 0) {
            setModalObject({
               header: "",
               body: "Select a valid Institution Group",
               footer: ""
            });
            setModalToggle(true);
            return false;
         }
      }
      else if (audienceType.value === '0') {
         if (!selEmailGroup || selEmailGroup.length === 0) {
            setModalObject({
               header: "",
               body: "Select Valid Email group",
               footer: ""
            });
            setModalToggle(true);
            return false;
         }

      }

      const eventObject = {
         event: {
            title: eventTitle,
            description: eventDes,
            start_date: `${eventStartDate} ${eventStartTime}`,
            end_date: `${eventEndDate} ${eventEndTime}`,
            image: eventImage,
         },
         categories: sel_category.length > 0 ? sel_category.map(eCate => Number(eCate.id)) : [],
         email_group: selEmailGroup.length > 0 ? selEmailGroup.map(eEmail => Number(eEmail.id)) : [],
         is_institution: audienceType.value === '1' ? 1 : 0,
         institution_id: selInstitution.id
      };

      if (!Api.checkNetwork()) return false;
      setShowLoading(true);

      Api.PostApi('/event/add-event', eventObject, true)
         .then(result => {
            console.log(eventObject, 'event');
            //console.log(result,'server result');
            setShowLoading(false);
            setModalObject({ header: '', body: result.message, footer: '' });
            setModalToggle(true);
            if (result.isSuccessful) {
               eventObject.id = result.data.id;
               eventObject.subuser_id = result.data.subuser_id;
               let newObj = formatObject(eventObject);
               // console.log(newObj);
               dispatch(addEvent(newObj));
               clearForm();

            }
            else {
               setModalObject('calender/events could not be added');
               setModalToggle(true);
            }
         })
         .catch(err => {
            setShowLoading(false);
            // alert('Action failed. please   re-log in');
         });


   };

   const handleEditBtn = (e) => {
      e.preventDefault();
      let check = validateSingleValues([
         eventTitle,
         eventDes,
         eventStartDate,
         eventEndDate,
         eventStartTime,
         eventEndTime
      ], [
         "Please Enter Event Title",
         "Please Enter Event Description",
         "Please Enter valid Start Date",
         "Please Enter Valid End Date",
         "Please Enter Valid Start Time",
         "Please Enter Valid End Time"

      ]);
      if (!check) return false;
      if (audienceType.value === '1') {
         if (!selInstitution || !selInstitution.id) {
            setModalObject({
               header: "",
               body: "Select a valid Institution",
               footer: ""
            });
            setModalToggle(true);
            return false;
         }
         if (!sel_category || sel_category.length === 0) {
            setModalObject({
               header: "",
               body: "Select a valid Institution Group",
               footer: ""
            });
            setModalToggle(true);
            return false;
         }
      }
      else if (audienceType.value === '0') {
         if (!selEmailGroup || selEmailGroup.length === 0) {
            setModalObject({
               header: "",
               body: "Select Valid Email group",
               footer: ""
            });
            setModalToggle(true);
            return false;
         }
      }
      //console.log(editEventCon);
      const eventObject = {
         event: {
            title: eventTitle,
            description: eventDes,
            start_date: `${eventStartDate} ${eventStartTime}`,
            end_date: `${eventEndDate} ${eventEndTime}`,
            image: eventImage
         },
         id: editEventCon.id,
         categories: sel_category.length > 0 ? sel_category.map(eCate => Number(eCate.id)) : [],
         email_group: selEmailGroup.length > 0 ? selEmailGroup.map(eEmail => Number(eEmail.id)) : [],
         is_institution: audienceType.value === '1' ? 1 : 0
      };

      console.log(eventObject);

      if (!Api.checkNetwork()) return false;
      setShowLoading(true);

      Api.PostApi('/event/edit-event', eventObject, true)
         .then(result => {
            //console.log(eventObject)
            console.log(result, 'server result');
            setShowLoading(false);
            setModalObject({ header: '', body: result.message, footer: '' });
            setModalToggle(true);
            if (result.isSuccessful) {
               // eventObject.id = result.data.id;
               let newObj = formatObject(eventObject);
               console.log(newObj);

               dispatch(editEvent(newObj));
               clearForm();
               navigate('../view-event');
            }
            else {
               setModalObject({ header: '', body: 'calender/events could not be edited', footer: '' });
               setModalToggle(true);
            }
         })
         .catch(err => {
            setShowLoading(false);
            // alert('Action failed. please   re-log in');
         });
   };

   const fillEditDetails = () => {
      setEventTitle(editEventCon.title);
      setEventDescription(editEventCon.description);
      const s_date = convertDatetoInputValue(editEventCon.start_date);
      const s_time = convertDatetoInputValue(editEventCon.start_date, true);
      const e_date = convertDatetoInputValue(editEventCon.end_date);
      const e_time = convertDatetoInputValue(editEventCon.end_date, true);
      console.log(
         "s-date", s_date,
         "s-time", s_time,
         "e-date", e_date,
         "e_time", e_time
      );
      setEventStartDate(s_date);
      setEventEndDate(e_date);
      setEventStartTime(s_time);
      setEventEndTime(e_time);
      let is_institution = editEventCon.categories[0].is_institution;
      let temp_category_id = editEventCon.categories[0].category_id;
      setDefaultAudience(audienceArr[is_institution]);
      setDisableField(true);
      if (is_institution === 1) {
         let tempInst = institutions.find(eInst => eInst.categories[temp_category_id]);
         if (tempInst) {
            let temp_categories = Object.values(tempInst.categories);
            setDefaultInstitution(tempInst);
            setCategoryArr(temp_categories);
            setDefaultCategory(editEventCon.categories.map(eC => ({ id: eC.category_id })));
         } else {
            setDisableField(false)
         }
      }
      else {
         let group_id = editEventCon.categories.map(eC => ({ id: eC.category_id }));
         //console.log(group_id);
         setDefaultGroup(group_id);

      }
   };

   useEffect(() => {
      if (editEventCon && editEventCon.id) {
         fillEditDetails();

      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [editEventCon]);

   const style = {
      readonly: false,
      height: 400,
      width: 680
   };

   return (
      <>
         <Loading shouldShow={showLoading} />
         <div className={`${styles.form} ${styles.form2}`} >
            <label className={`${styles.label}`}>
               <span className={styles.isRequired}>Event Title</span>
               <input name='eventName' value={eventTitle ? eventTitle : ''} className={`${styles.fields}`} type='text' placeholder="Event Title" onChange={(e) => { setEventTitle(e.target.value); }} />
            </label>
            {/*<label className={`${styles.label}`}>
                   <span>Description</span>
                   <textarea name='eventdescription' value={eventDes ? eventDes: ''} className={`${styles.fields}`} onChange={(e) => {setEventDescription(e.target.value)}} placeholder="Descript the event"></textarea>
                </label>*/}
            <TextEditor
               text={'Event Description'}
               setShowLoading={setShowLoading}
               navigate={navigate}
               value={eventDes}
               reff={editor}
               style={style}
               handleChange={setEventDescription}
            />
            <label className={`${styles.label2}`}>
               <span className={styles.isRequired}>Event Start Date</span>
               <input name='eventstartdate' value={eventStartDate ? eventStartDate : ''} className={`${styles.fields}`} type='date' onChange={(e) => { setEventStartDate(e.target.value); }} />
            </label>
            <label className={`${styles.label2}`}>
               <span className={styles.isRequired}>Event End Date</span>
               <input name='eventenddate' type='date' value={eventEndDate ? eventEndDate : ''} className={`${styles.fields}`} onChange={(e) => { setEventEndDate(e.target.value); }} />
            </label>
            <label className={`${styles.label2}`}>
               <span className={styles.isRequired}>Event Start Time</span>
               <input name='eventstarttime' value={eventStartTime ? eventStartTime : ''} className={`${styles.fields}`} type='time' onChange={(e) => { setEventStartTime(e.target.value); }} />
            </label>
            <label className={`${styles.label2}`}>
               <span className={styles.isRequired}>Event End Time</span>
               <input name='eventendtime' value={eventEndTime ? eventEndTime : ''} className={`${styles.fields}`} type='time' onChange={(e) => { setEventEndTime(e.target.value); }} />
            </label>

            <label className={styles.label}>
               <span className={styles.isRequired}>Audience Type</span>
               <Select options={audienceArr} onChange={setAudienceType} defaultValue={defaultAudience} placeholder="Select Audience Type" disabled={disableField} reset={resetField} />
            </label>

            {
               audienceType && audienceType.value === '1' ?
                  <>
                     <label className={styles.label}>
                        <span className={styles.isRequired}>Select Institution</span>
                        <Select options={institutions} onChange={handleSelInstitution} defaultValue={defaultInstitution} placeholder="Select Institution" value='id' disabled={disableField} label="name" reset={resetField} />
                     </label>
                     <label className={styles.label}>
                        <span className={styles.isRequired}>Select Institution Group</span>
                        <Select options={categoryArr} onChange={set_category} defaultValue={defaultCategory} placeholder="Select Group" value="id" label="name" multi reset={resetField} />
                     </label>
                  </>
                  : audienceType && audienceType.value === '0' &&

                  <label className={styles.label}>
                     <span className={styles.isRequired}>Select Email Group</span>
                     <Select options={emailGroup} onChange={setSelEmailGroup} defaultValue={defaultGroup} value="id" label="title" placeholder="Select Group" multi reset={resetField} />
                  </label>
            }

            <UploadedFiles type={"image"} modalTogglee={imageModal} closeModal={() => setImageModal(false)} setShowLoading={setShowLoading} onCopy={setEventImage} />
            <label className={`${styles.label}`} title='Copy logo url from "Upload Image"'>
               <div style={{ width: '100%' }}>
                  <span className={styles.isNotRequired}>Event Image URL</span>
                  <span className={styles.styledlink} onClick={() => setImageModal(true)}>upload image</span>
               </div>
               <input name='logoURL' className={`${styles.fields}`} type='text' placeholder="Image URL" value={eventImage} onChange={(e) => { setEventImage(e.target.value); }} />
            </label>


            {!editEventCon ?
               <div className={`${styles.addBtnDiv} ${styles.div}`}>
                  <button type="submit" className={styles.addBtn}
                     onClick={(event) => { handleSubmit(event); }}>Add Event</button>
               </div>
               :
               <div className={`${styles.addBtnDiv} ${styles.div}`}>
                  <button onClick={(e) => { clearForm(e); navigate('../view-event'); }} className='cancelEditButton'>Cancel Edit</button>
                  <button type="submit" className={styles.addBtn}
                     onClick={(event) => { handleEditBtn(event); }}>Save Change(s)</button>
               </div>
            }
         </div>
      </>
   );
};

export default CreateEvents;