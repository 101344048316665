import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResultCss from '../../../css/Result.module.css';
import styles from '../../../css/CreateInst.module.css';
import CategoriesCss from '../../../css/Categories.module.css';
import Api from "../../../helperFunctions/Api2";
import { addCourse, editCourse } from "../../../redux/reducers/resultReducer";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { CourseContext } from "./Courses";
import { MenuContext } from "../DashBoard";
import { UploadedFileOnly } from "../../../components/UploadedFiles";
import Select from "../../../components/Select";
import { userCategory } from "./addNote";

const userCategories = [
    { id: 0, name: 'Private' },
    { id: 1, name: 'Institution' },
    { id: 2, name: 'Institution & Public' },
    { id: 3, name: 'Public' }
];
export default function AddCourse() {
    const { setTitle, editing, editData, clearEdit } = useContext(CourseContext);
    const { setShowLoading, setModalText, setModalToggle } = useContext(MenuContext);
    const dispatch = useDispatch();
    const [title, setRSetTitle] = useState('');
    const [description, setDescription] = useState('');
    const [code, setCode] = useState('');
    const [thumbnail, setThumbnail] = useState('');
    const [file, setFile] = useState('');
    const [unit, setUnit] = useState(1);
    const [edit, setEdit] = useState(false);
    const [audienceType, setAudienceType] = useState(userCategories[0]);
    const navigate = useNavigate();
    const paymentLinks = useSelector(store => store.account.paymentLinks);
    const forms = useSelector(store => store.portal.forms);
    const [notePaymentLinks, setNotePaymentLinks] = useState([]);
    const [addPaymentLinks, setAddPaymentLink] = useState(false);
    const [addForm, setAddForm] = useState(false);
    const paylinkId = useRef();
    const formId = useRef();

    const clearFields = ()=>{
        setRSetTitle('');
        setDescription('');
        setCode('');
        setUnit(1);
        setThumbnail('');
        setFile('');
        setAudienceType(userCategories[0]);
        if(editing)
            clearEdit();
        setAddForm(false);
        setAddPaymentLink(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let payload = {
            title: title,
            description: description,
            code, 
            unit
        }
        if (Object.values(payload).some(e => e === '')) {
            setModalText('Please completely fill all fields');
            setModalToggle(true);
            return false;
        }
        payload = {
            ...payload,
            image: thumbnail,
            preview_video: file,
            payment_link_id: paylinkId.current.value || null,
            audience_type: audienceType.id,
            form_id: formId.current.value || null,
        }
        if (edit) payload = {...editData, ...payload}
        setShowLoading(true);
        Api.PostApi(edit?'/result/edit-course':'/result/add-course', payload, true)
            .then(result => {
                setModalText(result.message);
                console.log(result);
                if (result.isSuccessful || result.isSuccesful) {
                    clearFields();
                    dispatch(edit?editCourse(payload):addCourse(result.data))
                }
            })
            .catch(error => {
                setModalText(error.message);
            })
            .finally(() => {
                setModalToggle(true);
                setShowLoading(false);
            })
    }
    useEffect(() => {
        setTitle('Add Course');
    }, [setTitle])
    useEffect(()=>{
        if (editing) {
            setCode(editData.code);
            setDescription(editData.description);
            setRSetTitle(editData.title);
            setEdit(true);
            setThumbnail(editData.image || '');
            setFile(editData.preview_video || '');
            editData.audience_type && setAudienceType(userCategory[editData.audience_type])
            if (paymentLinks[editData.payment_link_id]) {
                setAddPaymentLink(true);
            }
            if (forms.find(obj => editData.form_id === obj.id)) {
                setAddForm(true);
            }
        }else {
            setEdit(false);
            clearFields()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editing]);

    useEffect(() => {
        //console.log(paymentLinks);
        let notePLinks = [];
        notePLinks = Object.values(paymentLinks);
        setNotePaymentLinks(notePLinks);
    }, [paymentLinks]);


    return (<>
        <div className={ResultCss.mainView}>
            <label className={CategoriesCss.label}>
                <span className={CategoriesCss.isRequired}>Title</span>
                <input value={title} onChange={(e) => setRSetTitle(e.target.value)} placeholder='Title of the course' className={CategoriesCss.input} />
            </label>
            <label className={CategoriesCss.label}>
                <span className={CategoriesCss.isRequired}>Description</span>
                <textarea style={{ height: 70, outline: 'none' }} value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Breifly describe the course' className={CategoriesCss.input} />
            </label>
            <label className={CategoriesCss.label}>
                <span className={CategoriesCss.isRequired}>Course code</span>
                <input value={code} onChange={(e) => setCode(e.target.value.toString().toUpperCase())} placeholder='short code alias of the course' className={CategoriesCss.input} type='text' />
            </label>
            <label className={CategoriesCss.label}>
                <span className={CategoriesCss.isRequired}>Course Unit</span>
                <input value={unit} onChange={(e) => setUnit(e.target.value)} min={1} placeholder='weight of each credit' className={CategoriesCss.input} type='number' />
            </label>
            <label className={`${styles.label}`}>
                <span className={styles.isRequired}>Audience Type</span>
                {<Select options={userCategories} name='learning ' className={styles.selectFields} onChange={setAudienceType} defaultValue={audienceType} value="id" label="name" />}
            </label>
            <label className={`${CategoriesCss.label}`}>
                <UploadedFileOnly type="image" title='thumbnail' setShowLoading={setShowLoading} onCopy={setThumbnail} value={thumbnail} />
            </label>
            <UploadedFileOnly setShowLoading={setShowLoading} onCopy={setFile} value={file} title="Preview Video Url" type={'video'} />
            <div className={styles.divContainer}>
                <input style={{ maxHeight: 15 }} type="checkbox" checked={addPaymentLinks} name="require_payment" onChange={() => addPaymentLinks ? setAddPaymentLink(false) : setAddPaymentLink(true)} /><span>Add Payment Link</span>
            </div>

            {
                addPaymentLinks && (
                    <label className={`${styles.label}`}>
                        <span className={styles.isRequired}>Select Payment Link</span>
                        <select ref={paylinkId} className={styles.selectFields} defaultValue={edit && editData.payment_link_id}>
                            {notePaymentLinks.map((paymentLink, index) => (
                                <option key={paymentLink.id} value={paymentLink.id}>{paymentLink.title}</option>
                            ))}
                        </select>
                        {/*<Select options={paymentLinks} name='Payment Link' className={styles.selectFields} onChange={setPayLink} value="id" label="title"/>*/}
                    </label>
                )
            }

            <div className={styles.divContainer}>
                <input style={{ maxHeight: 15 }} type="checkbox" checked={addForm} name="require_form" onChange={() => addForm ? setAddForm(false) : setAddForm(true)} /><span>Add Form</span>
            </div>

            {
                addForm && (
                    <label className={`${styles.label}`}>
                        <span className={styles.isRequired}>Select Form</span>
                        <select ref={formId} className={styles.selectFields} defaultValue={edit && editData.form_id}>
                            {
                                forms.map((form, index) => (
                                    <option value={form.id} key={index}>{form.title}</option>
                                ))
                            }
                        </select>
                    </label>
                )
            }

            <div className={CategoriesCss.buttonContainer}>
                {edit && <button onClick={() => { clearFields(); navigate('../view-course') }} className='cancelEditButton'>Cancel Edit</button>}
                <button className={CategoriesCss.viewBtn} onClick={handleSubmit}>{edit?"Edit Course":"Add Course"}</button>
            </div>
        </div>
    </>)
}