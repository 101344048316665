import React,{useEffect, useState} from 'react';
import css from './edit.module.css';
import {TfiMoreAlt as OptionsIcon} from 'react-icons/tfi';
import {FaTimes as CancelIcon} from 'react-icons/fa';
import {RiDeleteBin5Line as DeleteIcon} from 'react-icons/ri';
import {BiEditAlt as EditIcon} from 'react-icons/bi';

const Edit = (props) => {
  const index = props.index;
  const menuIndex = props.menuIndex;
  const [tempShow,setTempShow] = useState(menuIndex == index?true:false);
  const className = props.className?props.className:'';
  const style =props.style?props.style:{};
  const clickEdit = props.clickEdit?props.clickEdit:()=>{};
  const clickDelete = props.clickDelete?props.clickDelete:()=>{};
  const setMenuIndex = props.setMenuIndex;
  const More = props.More; //As Component
  const more = props.more; //As Jsx

  const handleShowMenu = ()=>{
      if(tempShow){
        setMenuIndex(index);
        setTempShow(false);
        //console.log(index,false);
      }else{
        setMenuIndex(index);
        setTempShow(true);
        //console.log(index,true);
      }
  }
  useEffect(()=>{
     if(menuIndex != index) setTempShow(false);
  },[menuIndex])
  useEffect (()=>{
    return ()=> setMenuIndex(null);
  },[])
  return (
    
    <span className={`${css.drop} ${className}`} style={style}>
        <span onClick={handleShowMenu}>
          <OptionsIcon/>
        </span>
        {
            tempShow && (menuIndex) == (index) ? <div>
            <div onClick={handleShowMenu}><CancelIcon/></div>
            {props.clickDelete && <div onClick={clickDelete}><DeleteIcon/> Delete</div>}
            {props.clickEdit && <div onClick={clickEdit} ><EditIcon/> Edit</div>}
            {more ? more: '' }
            {More ? <More/>: '' }
        </div>:<></>
        }
    </span>
    )
}

export default Edit