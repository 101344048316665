
import { createContext, useContext, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import styles from '../../../css/StaffMgmt.module.css';
import titleStyle from '../../../css/SectionTitle.module.css';
import { BsList } from "react-icons/bs";
import { MenuContext } from "../DashBoard";
import { ResultContext } from "./ResultMgmt";
import AddResultSet from "./AddResultSet";
import ViewResultSet from "./ViewResultSet";


const ResultSet = ()=>{
    const {showMenu, setShowMenu} = useContext(MenuContext);
    const {title} = useContext(ResultContext)
    return (

            <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <h1 className={`${styles.title}`}>Result Set</h1>
                <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3}>
                            {title}
                        </h3>
                        <div className={`${titleStyle.menuDiv}`}>
                            <span className={`${titleStyle.menuBtn}`} onClick={() => setShowMenu(!showMenu)}><BsList />Menu</span>
                            {showMenu &&
                                <div className={`${titleStyle.subMenu}`} >
                                    <ul>
                                        <li><Link to='add-result-set'>Add Result Set</Link></li>
                                        <li><Link to='view-result-set'>View Result Set</Link></li> 
                                    
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                    
                    
                    <Routes>
                        <Route element={<AddResultSet />} path={'/add-result-set'} />
                        <Route element={<ViewResultSet />} path={'/view-result-set'} />
                        <Route element={<ViewResultSet />} path={'/*'} />
                    </Routes>
                </div>
            </div>
    );
}
export default ResultSet;