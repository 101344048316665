import { useState, useEffect } from 'react';
import styles from './css/StaffMgmt.module.css';
import titleStyle from './css/SectionTitle.module.css';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { SiBookstack } from 'react-icons/si';
import { FaSearch } from 'react-icons/fa';
import { BsChevronDown as DownArrow, BsChevronUp as UpArrow } from 'react-icons/bs';
import { TiArrowBack } from "react-icons/ti";
import Loading from '../../../components/loading';
import Modal from '../../../components/modal';
import restApi from '../../../helperFunctions/Api2';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser'
import { saveQuestion } from '../../../redux/reducers/questionReducer';
const QuestionsMarket = () => {
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const [modalObject, setModalObject] = useState({});
    const [showLoading, setShowLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const stored_categories = useSelector(store => store.questions.public_categories);
    const [categories, setCategories] = useState(stored_categories);
    const questions = useSelector(store => store.questions.questions);
    const user = useSelector((state) => state.account.username);


    useEffect(() => {
        setCategories(stored_categories)
    }, [stored_categories])
    useEffect(() => {
        if (searchText) {
            const newcategories = categories.filter(category => (
                category.name.toLowerCase().includes(searchText.toLowerCase())
                || category.author.toLowerCase().includes(searchText.toLowerCase())
                || category.description.toLowerCase().includes(searchText.toLowerCase())
            ))
            setCategories(newcategories);
        } else setCategories(stored_categories)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText])

    const getQuestion = (category) => {
        // console.log(category, user);
        if (!category.canOpen) {
            let payload = {
                email: user.email,
                category_id: category.id
            }
            if (!restApi.checkNetwork()) {
                setModalText("You are Offline");
                setModalToggle(true);
                return false;
            }
            setShowLoading(true);
            restApi.PostApi('/payment/buy-question-bank', payload, true)
                .then(result => {
                    setShowLoading(false);
                    // console.log(result);
                    if (result.isSuccessful) {

                        setModalText("Complete payment to get the question");
                        setModalToggle(true);
                        const newWindow = window.open(result.data, '_blank', 'noopener,noreferrer')
                        if (newWindow) newWindow.opener = null;
                    }
                    else {
                        setShowLoading(false);
                        setModalText(result.message);
                        setModalToggle(true);
                    }

                })
                .catch(error => {
                    setModalText('Action failed, please try again or re-login');
                    setShowLoading(false);
                    setModalToggle(true);
                    sessionStorage.removeItem('eduplus_tkn');
                    navigate("/account/sign-in");
                });
        } else {
            if (questions[category.id]) {
                setModalObject({ data: questions[category.id], show: true });
                return
            }
            if (!restApi.checkNetwork()) {
                setModalText("You are Offline");
                setModalToggle(true);
                return false;
            }
            setShowLoading(true);
            restApi.getApi(`/question/open-user-question/${category.id}`, true)
                .then(result => {
                    setShowLoading(false);
                    if (result.isSuccessful) {
                        setModalObject({ data: result.data, show: true });
                        dispatch(saveQuestion({ id: category.id, data: result.data }))
                    }
                    else {
                        setModalText(result.message);
                        setModalToggle(true);
                    }

                })
                .catch(error => {
                    setModalText('Action failed, please try again or re-login');
                    setShowLoading(false);
                    setModalToggle(true);
                    // sessionStorage.removeItem('eduplus_tkn');
                    // navigate("/account/sign-in");
                });
        }
    }

    const readMore = (category) =>{
        setModalText(<EachCategory category={category} key={category.id} getQuestion={getQuestion} />)
        setModalToggle(true);
    }
    return (
        <>
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <Loading shouldShow={showLoading} />
            {modalObject.show && <ViewQuestion data={modalObject.data} setModalObject={setModalObject} />}
            <div className={`${styles.baseView} full-port`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                <h1 className={`${styles.title}`}>Questions Marketplace</h1>
                <div className={`${styles.subView}`}>
                    <div className={titleStyle.sectionTitle}>
                        <h3 className={titleStyle.h3} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                            {'Questions'}
                            <Search>
                                <FaSearch />
                                <input placeholder="Search" type='text' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                            </Search>
                        </h3>
                    </div>
                    <Container>
                        {categories.length ? categories.map(category => <EachCategory category={category} key={category.id} getQuestion={getQuestion} readMore={readMore}/>) : 'No Question available'}
                    </Container>
                </div>
            </div>
        </>
    );
};
const Search = styled.div`
padding: 4px 9px;
border: 1px solid var(--border-color);
background-color: white;
max-width: 100%;
border-radius: 5px;
transition: .2s;
display: flex;
align-items: center;

input{
    padding-left: 10px;
    width: 100%;
    border: none;
    height: auto;
}
svg{
    font-size: 20px;
    color: var(--grey-text-color);
}
`
const Container = styled.div`
display: flex;
grid-auto-columns: max-content;
grid-template-columns: repeat(auto-fill, 200px);
flex-wrap: wrap;
align-items: center;
//justify-content: space-evenly;
width: 100%;
gap: 20px;
padding: 10px;
`
const Category = styled.div`
    display: grid;
    grid-template-rows:20% max-content auto max-content;
    gap: 0px;
    border-radius: 10px;
    box-shadow: -2px -2px 3px rgba(240,240,240,0.5),
    2px 2px 3px rgba(70,70,70,0.12);
    width: 45%;
    max-width:250px;
    min-width:180px;
    height: 240px;
    text-align: left;
    padding: 7px;
    position: relative;
    @media screen and (max-width: 500px) {
        width: 90%;
        max-width: 100%;
    }

    :hover{
        box-shadow: rgb(116 178 182 / 50%) 0px 0px 8px, rgba(70, 70, 70, 0.12) 2px 2px 3px;
    }
    img {
        border-radius: 20%;
        object-fit: cover;
        margin: 0;
        width: 6em;
        height: 6em;
        justify-self: flex-start;
        align-self: center;
    }
    .icon{
        font-size: 2.5em;
    }
    h4, i {
        overflow-wrap: break-word;
        margin: 0em;
        font-style: normal;
        font-size: 0.9em;
        color: #222;
    }
    p {
        margin: 0em;
        color: var(--primary-color);
        font-size: 0.9em;
        position: relative;
        line-height: 1;

    }
    .tooltip {
        position: relative;
        display: inline-block;

        .tooltiptext {
            visibility: hidden;
            width: 100%;
            background-color: #fff;
            color: #000;
            border-radius: 6px;
            font-size: 12px;
            box-shadow: rgb(116 178 182 / 50%) 0px 0px 8px, rgba(70, 70, 70, 0.12) 2px 2px 3px;
            padding: 4px;
        
            /* Position the tooltip */
            position: absolute;
            z-index: 1;
            left: 0%;
            bottom: 0px;
        }
        
        :hover .tooltiptext {
            visibility: visible;
        }
        }
    .tag{
        display: flex;
        justify-content: space-between;
        font-size: small;
        b{
            border: 1px solid;
            padding: 5px;
            color: var(--secondary-color);
            border-radius: 5px;
            height: fit-content;
        }
    }
    .button{
        border: 1px solid var(--secondary-color);
        color: var(--white-color);
        background-color: var(--secondary-color);
        outline: none;
        border-radius: 8px;
        padding: 8px 0px;

        :hover{
        color: var(--secondary-color);
        background-color: transparent;
        }
        :disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }
`

const EachCategory = ({ category, getQuestion, readMore }) => {
    return <Category style={!readMore?{maxWidth: 'unset'}:{}}>
        <div className='tag'>
            <SiBookstack className='icon' />
            <b>{category.canOpen ? 'Free' : '$'+category.amount}</b>
        </div>

        <h4>{category.name}</h4>
        <div style={{ position: 'relative' }}>
            <p><i>Author:</i> {category.author}</p>
            <p><i>Total Questions:</i> {category.question_num}</p>
            <p style={{ overflow: 'hidden', maxHeight: '4em', paddingBottom: '2px', textOverflow: 'ellipsis' }}>
                <i>Description:</i> {!readMore || category.description.length < 35?category.description:category.description.slice(0,35) + '...' }
            </p>
            {readMore && <span onClick={()=>readMore(category)} style={{color: 'var(--secondary-color)', cursor:'pointer'}}>see more...</span>}
            {/* <div className='tooltip'>
                <span className='tooltiptext'>{category.description}</span>
            </div> */}
            {!readMore && <>
            <p><i>No of Questions:</i> {category.question_num}</p>
            {category.main_category && <p><i>Main category:</i> {category.main_category}</p>}
            </>}
        </div>
        <button className='button' onClick={() => getQuestion(category)} >{category.canOpen ? 'Open' : 'Get'} Question</button>
    </Category>
}

export default QuestionsMarket;

const QContainer = styled.div`

display: flex;
flex-direction: column;
gap: 7px;
width: 100%;
min-height: 100%;
position: fixed;
z-index: 10002;
left: 0;
top: 0;
bottom: 0px;
background-color: #eee;
overflow-y: scroll;
background-color: var(--highlight);

h5{
    margin: 7px 0 0;
    font-size: 1em;
}
button{
    font-size: 16px;
    border-radius: 5px;
    padding: 4px 20px;
    border: 1px solid;
    background-color: transparent;
    cursor: pointer;
}
button:disabled{
    cursor: not-allowed;
}
.title{
    width:100%;
    height:50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0 5px 10px -5px rgba(0,0,0,0.50);
    background-color: white;
    z-index: 2;
    position: fixed;
}
.foot{
    position: fixed;
    right:0;
    bottom:0;
    height:50px;
    width:100%;
    display: flex;
    justify-content: space-between;
    padding:0 30px;
    align-items: center;
    z-index: 2;
    background-color: white;
    box-shadow: 0 -3px 5px -1px rgba(0,0,0,0.15);
}
.nav{
    display: flex;
    justify-content: center;
    gap: 3%;

}
.gotoContainer{
    border:1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2px;
    border-radius: 5px;
    cursor: pointer;
}
.gotoContainer > span:nth-child(1){
    font-size: 16px;
    padding:0 2px;
    position: relative;
    display: flex;
    align-items: center;
}
.gotoContainer > span:nth-child(1) > svg{
    position: relative;
    top:2px;
}
.gotoContainer > div{
    display: grid;
    grid-template-columns: repeat(10,1fr);
    position: absolute;
    bottom: 50px;
    flex-wrap: wrap;
    gap:5px;
    background-color: white;
    width: fit-content;
    max-width:365px;
    left:-20px;
    border-radius: 8px;
    padding:10px;
    z-index: 2;
    box-shadow: 0 -5px 3px -2px rgba(0,0,0,0.2),0 2px 15px -5px rgba(0,0,0,0.3);
    
    .current{
        border:1px solid var(--primary-color);
        background-color: var(--secondary-color);
        color:var(--white-color);
    }
}
.gotoContainer > div > span{
    min-width:30px;
    height:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border-radius: 3px;
    border:1px solid var(--border-color);
    background-color: var(--white-color);
    color: black;
}
.gotoContainer > span:nth-of-type(2){
    position: absolute;
    bottom: 36px;
    color:var(--white-color);
    z-index:3;
}

.purple-btn{
    background-color: var(--white-color);
    color: var(--primary-color);
    font-size: 16px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    padding: 5px 25px;
    cursor: pointer;
}
.question{
    min-height: calc(100vh - 55px);
    text-align: justify;
    display: flex;
    gap: 5px;
    width: 100%;
    background-color: var(--border-color);
    margin: 55px 0 0;

    p{
        margin: 0;
    }
    >div{
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 10px;
        background-color: var(--highlight);
        padding: 0 12px;
        
        >div{
            padding: 10px;
            background-color: white;
            box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.35);
            border-radius: 5px;

        }
        >div:last-child{
            background: transparent;
            box-shadow: none;
            padding: 25px;
        }

        label{
            display: flex;
            align-items: center;
            gap: 5px;
            margin: 4px 0;

            >span{
                color: var(--primary-color)
            }
        }
        input{
            height: max-content;
        }
    }
    .active .ans{
        background-color: lightgreen;
    }
}
@media only screen and (max-width: 500px){
    .question{
        flex-direction: column;

        >div{
            width: 100%;

            >div:last-child{
                padding: 10px;
            }
        }
    }
}
`
const ViewQuestion = ({ data, setModalObject }) => {
    const [questionNo, setquestionNo] = useState(0)
    const [question, setquestion] = useState({})
    const [showAnswers, setshowAnswers] = useState(false)
    const [lastNumber, setLastNumber] = useState(data.questions.length);
    const [numberArr, setNumberArr] = useState([]);
    const [showNumberList, setShowNumberList] = useState(false);

    useEffect(() => {
        setquestion({})
        setquestionNo(0)
        setLastNumber(data.questions.length - 1)
    }, [data])
    useEffect(() => {
        setquestion(data.questions[questionNo])
        setshowAnswers(false)
        const arr = [];
        let i;
        for (i = 0; i <= lastNumber; i++) {
            arr[i] = i;
        }
        setNumberArr(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionNo])

    return <QContainer>
        <div className='title'>
            <button style={{ border: 'none' }} onClick={() => setModalObject({})}><TiArrowBack /> Back</button>
            <b>{data.name}</b>
        </div>
        <Question question={question} showAnswers={showAnswers} />
        <div className='foot'>
            <div className='nav'>
                <button disabled={questionNo <= 0} style={{ color: 'var(--secondary-color)', backgroundColor: 'var(--white-color)' }} onClick={() => setquestionNo(questionNo - 1)}>Prev</button>
                <div className='numdiv gotoContainer' onClick={() => setShowNumberList(!showNumberList)}>
                    <span>{questionNo + 1}&nbsp;/&nbsp;{data.questions.length}&nbsp;&nbsp;{!showNumberList ? <DownArrow /> : <UpArrow />}</span>
                    {showNumberList && <div>{
                        numberArr.length > 0 && numberArr.map(element => <span className={questionNo === element?'current':''} key={element} onClick={() => setquestionNo(element)}>{element + 1}</span>)}
                    </div>}
                    {showNumberList && <span><i className='bi-caret-down-fill'></i></span>}
                </div>
                <button disabled={questionNo >= lastNumber} style={{ color: 'var(--white-color)', backgroundColor: 'var(--secondary-color)' }} onClick={() => setquestionNo(questionNo + 1)}>Next</button>
            </div>
            <button className='purple-btn' onClick={() => setshowAnswers(true)}>View answer</button>
        </div>
    </QContainer>
}

const Question = ({ question, showAnswers }) => {
    const [options, setOptions] = useState([]);
    const [answers, setAnswers] = useState([])

    useEffect(() => {
        question.options && setOptions(JSON.parse(question.options))
        question.answers && setAnswers(JSON.parse(question.answers))
        // console.log(question)
    }, [question])
    return <div className='question'>
        <div>
            <h5>Question</h5>
            <div>{parse(String(question.question))}</div>
            <div style={{ background: 'transparent', boxShadow: 'none', padding: '25px' }}></div>

        </div>
        <div>
            <h5>Options</h5>
            <div>{options && options.map((option, index) => <label key={index}>Option {index + 1}: <span>{option}</span></label>)}</div>

            {showAnswers && <> <div><b>Answer: </b>
                {answers && answers.map((option, index) => <span>Option {option}{index !== answers.length - 1 && ', '}</span>)}
            </div></>}
            <div></div>
        </div>
    </div>
}


