import { createContext, useState } from 'react';
import styles from './../../../css/StaffMgmt.module.css';
import titleStyle from './../../../css/SectionTitle.module.css';
import { Routes, Route, } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import CreateUserCategory from './CreateUserCategory';
import ViewUserCategory from './ViewUserCategories';
import SetUserPrivileges from './userPrivileges/SetUserPrivileges';
import ViewUserPrivileges from './userPrivileges/ViewUserPrivileges';
import Menu from '../../../components/Menu';

export const EditContext = createContext();

const GroupMgmt = () => {
  const [title, setTitle] = useState('');
  const [editData, setEditData] = useState({});
  const [editing, setEditing] = useState(false);
  const [editUserData, setEditUserData] = useState({});
  const [editingUser, setEditingUser] = useState(false);
  const [editCategory, setEditCategory] = useState({});

  const clearEdit = () => {
    setEditing(false);
    setEditData({});
    setEditingUser(false);
    setEditUserData({});
    setEditCategory({});
  };

  return (
    <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
      <h1 className={`${styles.title}`}>Group Management</h1>
      <div className={`${styles.subView}`}>
        <div className={titleStyle.sectionTitle}>
          <h3 className={titleStyle.h3}>
            {title}
          </h3>
          <Menu options={[
            { label: 'Create User Group', path: 'create-category', onClick: () => clearEdit() },
            { label: 'View User Groups', path: 'view-categories', onClick: () => clearEdit() },
            { label: 'Set Group Privileges', path: 'set-users-privileges', onClick: () => clearEdit() },
            { label: 'View Privileges', path: 'view-users-privileges', onClick: () => clearEdit() }
          ]} />
        </div>
        <div style={{ width: '100%' }} >
          <EditContext.Provider value={{ editData, setEditData, editing, setEditing, clearEdit, editUserData, setEditUserData, editingUser, setEditingUser, editCategory, setEditCategory }}>
            <Routes>
              <Route path="*" element={<ViewUserCategory setTitle={setTitle} />} />
              <Route path='/create-category' element={<CreateUserCategory setTitle={setTitle} />} />
              <Route path='/view-categories' element={<ViewUserCategory setTitle={setTitle} />} setTitle={setTitle} />
              <Route path='/set-users-privileges' element={<SetUserPrivileges setTitle={setTitle} />} />
              <Route path='/view-users-privileges' element={<ViewUserPrivileges setTitle={setTitle} />} />
              <Route path="/*" element={<PageNotFound />} />
            </Routes>
          </EditContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default GroupMgmt;