import { useCallback, useState, useEffect, useRef } from "react";
import { BiMenu, BiXCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HomeCss from '../../../css/homeCss.module.css';
import HTMLReactParser from "html-react-parser";
import { convertDatetoString } from "../../../helperFunctions/Api2";
// import image from '../../../images/5172658.jpg';

const WebsiteTemplate = ({ websiteData, title, }) => {
    const [isShown, setIsShown] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [width, setWidth] = useState(0);
    const [wt, setWt] = useState({});

    useEffect(() => {
        setWt(document.getElementById("wt"));
    }, []);
    useEffect(() => {
        wt && wt.clientWidth && setWidth(wt.clientWidth);
    }, [wt, wt.clientWidth]);
    useEffect(() => {
    }, [width]);


    return (
        <Template className={width < 500 ? "width" : ""} id="wt" onScroll={(e) => setScrollPosition(e.target.scrollTop)} websiteData={websiteData}>
            {websiteData.navbar && <div className={`nav1${scrollPosition > 30 ? " sticky" : ""}`} style={{ top: scrollPosition }}>
                <Link className="navbar-brand">
                    <img src={websiteData.logo} alt='' width={40} height={40} />
                    {title}
                </Link>
                <div className="navdiv">
                    <span className="ham" onClick={() => setIsShown(!isShown)}>{
                        isShown ? <BiXCircle style={{ color: "#fff" }} /> : <BiMenu style={{ color: websiteData.secondaryColor }} />
                    }</span>
                    <div style={{ display: isShown ? "block" : "" }}>
                        <Link>Home</Link>
                        {websiteData.about && <Link to={"#about"}>About</Link>}
                        {websiteData.form && <div className="navbar-dropdown">
                            <Link to={'#forms'}>Forms</Link>
                            {websiteData.forms && <div className="dropdown">{
                                websiteData.forms.map((f) => <Link to={'#'} key={f.id} className="dropdown-content">{f.title}</Link>)}
                            </div>
                            }
                        </div>}
                        {websiteData.result && <Link to={'#results'}>Result</Link>}
                        {/* {websiteData.footer && <Link to={'#contact'}>Contact Us</Link>} */}
                        {websiteData.eduplus && <Link className='signin'>Sign-In</Link>}
                    </div>
                </div>

            </div>}
            <div className="banner" >
                <BannerBG files={websiteData.bannerFiles} type={websiteData.bannerFileType} />
                {websiteData.topBanner && <div className="mainBaner1">
                    <div className="">
                        <div>
                            <h1>{websiteData.bannerHead}</h1>
                            <p>{websiteData.bannerText}</p>
                            <br /><button>Learn More</button>
                        </div>
                    </div>
                    <div className="">
                    </div>
                </div>}
            </div>
            {websiteData.about && <div className='about'>
                <div className="imgdiv"><img alt="" src={websiteData.aboutImage/*  || image */} /></div>
                <div className="contdiv">{HTMLReactParser(websiteData.aboutText/*  || '<p><span>Lorem ipsum dolor sit amet, populo numquam no pri. Ea natum philosophia qui, impedit admodum fuisset at cum. Alii cibo eripuit an eos, et mea clita essent omittam, ex vix posse tibique percipitur. Eos duis nulla ea, erat detraxit menandri sea an, vix tale modus alienum ei.</span></p>' */)} </div>
            </div>}
            {websiteData.stat && <div className='stats'>
                <h2>Our Stats</h2>
                <div>{websiteData.statOptions.map((option, ind) =>
                    <div className="eachStat" key={ind}>
                        <div>
                            <span><i className={option.icon}></i></span><br />
                            <h1>{option.value} {option.text}</h1>
                            <p>{option.adj}</p>

                        </div>
                    </div>
                )}</div>
            </div>}
            {websiteData.offering && <div className='offerings'>
                <h1 style={{ margin: 0 }}>Our offerings</h1>
                {websiteData.offeringData.map((offering, ind) => <div key={ind} className={`${ind % 2 !== 0 ? 'rowverse' : ''}`}>
                    <div><img alt="" src={offering.image/*  || image */} /></div>
                    <div>{HTMLReactParser(offering.text/*  || '<h3>HEADING</h3><p><span>Lorem ipsum dolor sit amet, populo numquam no pri. Ea natum philosophia qui, impedit admodum fuisset at cum.</span></p>' */)}</div>
                </div>)}
            </div>}
            {websiteData.events && <div className='events'>
                <h2>Events/Appointment</h2>
                <div>{websiteData.eventsData.map((event, ind) => <div key={ind} className="eachEvent">
                    <Link>
                        <img alt={event.title} src={event.image} />
                        <span className="hdertext">{event.title}</span><br />

                        <span>{convertDatetoString(event.start_date, 1)}</span>
                        <div>to</div>
                        <span>{convertDatetoString(event.end_date, 1)}</span>
                    </Link>
                </div>)}</div>
            </div>}
            {websiteData.courses && <div className='courses'>
                <h1>Courses</h1>
                <div>{websiteData.coursesData.map((course, ind) => <div key={ind} className="eachCourse">
                    <div>
                        <img alt="" src={course.image/*  || image */} /><br />
                        <div>
                            <h3>{course.title/*  || 'Programming' */}</h3>
                            <span className="dur"> {course.duration/*  || '120H' */}</span>
                        </div>
                        <p>{course.description/*  || 'Become the best coder you can be with unlimited access to all the existing and future courses' */}</p>
                        <span>{course.price/*  || '$ 20' */}</span>
                    </div>
                </div>)}
                </div>
            </div>}
            {websiteData.messageHead && <div className='about'>
                <div className="imgdiv">
                    <img alt="" src={websiteData.messageImage/*  || image */} />
                </div>
                <div className="contdiv">{HTMLReactParser(websiteData.messageText/*  || `<h3>Message From Institution Head</h3>
                    <p> <span style="font-size: 12pt; font-family: tahoma, arial, helvetica, sans-serif;">Lorem ipsum dolor sit amet, populo numquam no pri. Ea natum philosophia qui, impedit admodum fuisset at cum. Alii cibo eripuit an eos, et mea clita essent omittam, ex vix posse tibique percipitur. Eos duis nulla ea, erat detraxit menandri sea an, vix tale modus alienum ei.</span></p>` */)}</div>
            </div>}
            {websiteData.awards && <div className='award_patnerships'>
                <h2>Awards/Patnerships</h2>
                <div>
                    {websiteData.awardsData.map((award, ind) => <div key={ind} className="eachAward">
                        <div>
                            <img alt="" src={award.image/*  || image */} />
                            <span>{award.title/*  || 'Best Institution' */}</span>
                        </div>
                    </div>)}
                </div>
            </div>}
            {websiteData.gallery && <div className="gallery">
                <h2>Gallery</h2>
                <div className="container2"><Carousel autoPlay={websiteData.gallerySlides.length > 1}
                    slides={websiteData.gallerySlides.length > 0 ? websiteData.gallerySlides : slides} activeSlideDuration={3000} /></div>
            </div>}
            {websiteData.testimony && <div className="gallery">
                <h2>Testimonials</h2>
                <div className="container2"><Carousel autoPlay={websiteData.testimonySlides.length > 1}
                    slides={websiteData.testimonySlides.length > 0 ? websiteData.testimonySlides : testimonyslides} activeSlideDuration={3000} testimony /></div>
            </div>}
            {websiteData.footer && <div className='footer'>
                <div>
                    <div>
                        <h1>
                            <img src={websiteData.logo} alt='' width={40} height={40} />
                            {title}
                        </h1>
                    </div>
                    <div>
                        <h3>Quick Links</h3>
                        <ul>
                            <li><Link>Home</Link></li>
                            <li><Link>About Us</Link></li>
                        </ul>
                    </div>
                    <div>
                        <h3>Contact Us</h3>
                        {/* <div>{HTMLReactParser(websiteData.contactInfo)}</div> */}
                        <ul>{websiteData.contactInfo.map((info, ind)=>
                            <li key={ind}>{info.key[0].toUpperCase()+info.key.slice(1)}: {info.value}</li>
                        )}</ul>
                    </div>
                </div>
                <div className="copyright">Copyright 2024 @{title}. All right reserved</div>
            </div>}
        </Template>
    );
};

export default WebsiteTemplate;
let slideObj = { end: 0, slideInt: 0, conSlide: null };

const BannerBG = ({ files, type }) => {
    const slideRef = useRef('slide');

    const startSlide = () => {
        if (slideRef.current === null || slideObj.end <= 1) {
            clearTimeout(slideObj.conSlide);
            return false;
        }
        slideRef.current.style.transform = `translateX(${-1 * slideObj.slideInt}%)`;
        slideObj.slideInt += 100;

        if (slideObj.slideInt > (slideObj.end * 100)) {
            slideObj.slideInt = 0;
        }
        slideObj.conSlide = setTimeout(() => {
            slideRef.current.style.transition = slideObj.slideInt <= 0 ? 'none' : `1s`;
            startSlide();
        }, slideObj.slideInt <= 0 ? 2000 : 5000);

    };
    useEffect(() => {
        if (files) {
            slideObj.end = Object.values(files).length;
            startSlide();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);
    useEffect(() => {
    }, [files, type]);
    return <>{files && (type.value === 'video' ? <video src={files[0]} autoPlay loop muted>Video not supprted</video> :
        type.value === 'single' ?
            <ul style={{ '--end': `-00%` }} className={HomeCss.slideUl} ref={slideRef}>
                <li style={{ backgroundImage: `url(${files[0]})` }}></li>
            </ul> :
            type.value === 'multi' ?
                <ul style={{ '--end': `-${Object.values(files).length > 1 ? Object.values(files).length : ''}00%` }} className={HomeCss.slideUl} ref={slideRef}>{Object.values(files).map((file, ind) =>
                    <li key={ind} style={{ backgroundImage: `url(${file})` }}></li>
                )}
                    <li style={{ backgroundImage: `url(${files[0]})` }}></li>
                </ul> :
                <></>
    )}
    </>;
};


const Slide = ({ imageSrc, title, subtitle, testimony }) => (
    testimony ? <>
        <p>{subtitle/*  || 'Lorem Ipsium' */}</p>
        <div>
            <img src={imageSrc/*  || image */} alt={title} />
            <span>{title/*  || 'name' */}</span>
        </div>
    </> :
        <img src={imageSrc/*  || image */} alt={title} style={{ width: '100%', height: '100%' }} />
);

const CarouselButton = ({ useTriangle, color, disabled, clickHandler, icon, isLeftIcon }) => (
    <div className={`carousel-${isLeftIcon ? 'left' : 'right'}-arrow carousel-control`}>
        <div
            className={useTriangle ? `${isLeftIcon ? 'left' : 'right'}-triangle` : ''}
            style={{
                ...(isLeftIcon
                    ? { borderLeftColor: useTriangle ? color : '' }
                    : { borderRightColor: useTriangle ? color : '' }),
            }}
        />
        <button
            className={!useTriangle ? `padding-${isLeftIcon ? 'left' : 'right'}-15` : ''}
            disabled={disabled}
            onClick={clickHandler}
        >
            {icon}
        </button>
    </div>
);

const CarouselIndicators = ({ position, nextActiveIndex, indicatorsColor, clickHandler, slides }) => (
    <ol className={`carousel-indicators ${position}`}>
        {slides.map((el, i) => (
            <li
                key={i}
                className={i === nextActiveIndex ? 'active' : ''}
                //eslint-ignore-next-line
                style={{ '--indicatorsColor': indicatorsColor }}
                onClick={() => {
                    if (slides.length !== 1 && i !== nextActiveIndex) clickHandler(i);
                }}
            />
        ))}
    </ol>
);

const Carousel = ({
    autoPlay = false,
    activeSlideDuration = 3000, //how long a slide will be displayed
    interactionMode = 'swipe', //swipe or hover
    indicatorsColor = '#ffffff',
    alignIndicators = 'center', //center, left, right
    alignCaption = 'center', //center, left, right
    useRightLeftTriangles = false,
    leftTriangleColor = '#ffffff',
    leftIcon,
    rightTriangleColor = '#ffffff',
    rightIcon,
    slides = [], testimony = false,
}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [nextActiveIndex, setNextActiveIndex] = useState(0);
    const [activeIndexClasses, setActiveIndexClasses] = useState(['active-slide']);
    const [nextActiveIndexClasses, setNextActiveIndexClasses] = useState([]);
    const [disablePrevNext, setDisablePrevNext] = useState(false);
    const [xCoordinate, setXCoordinate] = useState(null);
    // use it to set the indicator position based on the coming (alignIndicators) prop
    const [indicatorPosition, setIndicatorPosition] = useState('position-center');
    /*will be used to reset classes after animating the transition from a slide to another
          (it has to be equal to the animation duration in the css
          classes [enter-to-left, exit-to-left, enter-to-right, exit-to-right])*/
    const animationDuration = 600;
    // will be used to autoplay the carousel
    const autoSlide = useRef(null);
    // used to detect slider direction when clicking the buttons to change slides
    const direction = useRef('to-left');

    useEffect(() => {
        if (alignIndicators === 'right') {
            setIndicatorPosition('position-right');
        } else if (alignIndicators === 'left') {
            setIndicatorPosition('position-left');
        }
    }, [alignIndicators]);

    // show the next slide in the view port based on the direction
    const animateSliding = useCallback(() => {
        let newActiveIndexClasses = [];
        let newNextActiveIndexClasses = [];

        // attach the following classes if the user click the next button
        if (direction.current === 'to-left') {
            newActiveIndexClasses.push('active-slide', 'exit-to-left');
            newNextActiveIndexClasses.push('active-slide', 'next-active-slide', 'enter-to-left');
        } else {
            // attach the following classes if the user click the prev button
            newActiveIndexClasses.push('active-slide', 'exit-to-right');
            newNextActiveIndexClasses.push('active-slide', 'next-active-slide', 'enter-to-right');
        }

        setActiveIndexClasses(newActiveIndexClasses);
        setNextActiveIndexClasses(newNextActiveIndexClasses);
    }, []);

    const setActiveSlide = (nextActiveI) => {
        setActiveIndex(nextActiveI);
        setActiveIndexClasses(['active-slide']);
        setNextActiveIndexClasses([]);
        setDisablePrevNext(false);
    };

    // used to restart auto sliding when user click prev, next button or on the carousel indicator
    const restartAutoSliding = useCallback(
        (nextAcIn) => {
            setNextActiveIndex(nextAcIn);
            setDisablePrevNext(true);

            // attach the required classes to animate the transition between slides
            animateSliding();

            let startId = null;
            // reset classes and enable prev & next btns after the animation duration
            startId = setTimeout(() => {
                setActiveSlide(nextAcIn);
                clearInterval(startId);
            }, animationDuration);

            // restart auto sliding
            autoSlide.current = autoPlay
                ? setInterval(() => {
                    //stop auto sliding (so that when user click the next button we can reset the timer for auto sliding)
                    stopAutoSliding();

                    // set direction to left because slide is coming from the right side to the view port
                    direction.current = 'to-left';

                    // set the next active index
                    let nextActiveI = activeIndex + 1;

                    // if we reach the last slide reset the next active index to 0
                    if (nextActiveI === slides.length) {
                        nextActiveI = 0;
                    }

                    // restart auto sliding
                    restartAutoSliding(nextActiveI);
                }, activeSlideDuration)
                : null;
        },
        [animateSliding, activeSlideDuration, activeIndex, autoPlay, slides.length]
    );

    const nextSlide = useCallback(() => {
        //stop auto sliding (so that when user click the next button we can reset the timer for auto sliding)
        stopAutoSliding();

        // set direction to left because slide is coming from the right side to the view port
        direction.current = 'to-left';

        // set the next active index
        let nextActiveI = activeIndex + 1;

        // if we reach the last slide reset the next active index to 0
        if (nextActiveI === slides.length) {
            nextActiveI = 0;
        }

        // restart auto sliding
        restartAutoSliding(nextActiveI);
    }, [activeIndex, slides.length, restartAutoSliding]);

    const startAutoSliding = useCallback(() => {
        autoSlide.current = autoPlay ? setInterval(nextSlide, activeSlideDuration) : null;
    }, [autoPlay, activeSlideDuration, nextSlide]);

    const stopAutoSliding = () => {
        clearInterval(autoSlide.current);
    };

    useEffect(() => {
        startAutoSliding();
        return () => stopAutoSliding();
    }, [startAutoSliding]);

    // used to unify the touch and click cases
    const unify = (e) => (e.changedTouches ? e.changedTouches[0] : e);

    // get and set the x coordinate
    const getSetXCoordinate = (e) => setXCoordinate(unify(e).clientX);

    // move the slide based on the swipe direction
    const moveSlide = (e) => {
        if (xCoordinate || xCoordinate === 0) {
            const dx = unify(e).clientX - xCoordinate;
            const s = Math.sign(dx);

            if (s < 0) {
                nextSlide();
            } else if (s > 0) {
                prevSlide();
            }
        }
    };

    const prevSlide = () => {
        //stop auto sliding (so that when user click the prev button we can reset the timer for auto sliding)
        stopAutoSliding();

        // set direction to right because slide is coming from the left side to the view port
        direction.current = 'to-right';

        // set the next active index
        let nextActiveI = activeIndex - 1;

        // if we are at the first slide set the next active index to the last slide
        if (nextActiveI < 0) {
            nextActiveI = slides.length - 1;
        }

        // restart auto sliding
        restartAutoSliding(nextActiveI);
    };

    const onCarouselIndicator = (index) => {
        //stop auto sliding
        stopAutoSliding();

        // set the next active index
        let nextActiveI = index;

        // set the direction of the carousel based on the clicked indicator index
        if (nextActiveI < activeIndex) {
            direction.current = 'to-right';
        } else {
            direction.current = 'to-left';
        }

        // restart auto sliding
        restartAutoSliding(nextActiveI);
    };

    return (
        <div
            className="carousel-slider-wrapper"
            style={{
                cursor: interactionMode === 'swipe' ? 'pointer' : '',
            }}
            onMouseDown={(e) => {
                if (interactionMode !== 'swipe') {
                    return;
                }
                getSetXCoordinate(e);
            }}
            onTouchStart={(e) => {
                if (interactionMode !== 'swipe') {
                    return;
                }
                getSetXCoordinate(e);
            }}
            onMouseUp={(e) => {
                if (disablePrevNext || interactionMode !== 'swipe') {
                    return;
                }
                moveSlide(e);
            }}
            onTouchEnd={(e) => {
                if (disablePrevNext || interactionMode !== 'swipe') {
                    return;
                }
                moveSlide(e);
            }}
            onMouseMove={(e) => {
                if (interactionMode !== 'swipe') {
                    return;
                }
                e.preventDefault();
            }}
            onTouchMove={(e) => {
                if (interactionMode !== 'swipe') {
                    return;
                }
                e.preventDefault();
            }}
        >
            {/*(onMouseDown & onTouchStart) & (onMouseUp & onTouchEnd) used to detect the motion direction*/}
            {/*(onMouseMove & onTouchMove) used to prevent edge from navigating the
                 opposite motion direction (also sometimes on chrome)*/}
            {slides.map((el, i) => {
                let classes = '';

                if (i === activeIndex) {
                    classes = activeIndexClasses.join(' ');
                } else if (i === nextActiveIndex) {
                    classes = nextActiveIndexClasses.join(' ');
                }

                return (
                    <div
                        key={i}
                        className={`carousel-slide ${classes} ${interactionMode === 'swipe' ? 'swipe' : ''} ${testimony ? 'testimony' : ''}`}
                        style={{ textAlign: alignCaption }}
                        // the following events to pause the auto slide on hover
                        onMouseEnter={() => {
                            if (interactionMode !== 'hover') {
                                return;
                            }
                            stopAutoSliding();
                        }}
                        onMouseLeave={() => {
                            if (interactionMode !== 'hover') {
                                return;
                            }
                            startAutoSliding();
                        }}
                    >
                        <Slide imageSrc={el.image} title={el.title} subtitle={el.subtitle} testimony={testimony} />
                    </div>
                );
            })}
            {/* carousel controls*/}
            <CarouselButton
                useTriangle={useRightLeftTriangles}
                color={leftTriangleColor}
                disabled={disablePrevNext}
                clickHandler={() => {
                    if (slides.length !== 1) prevSlide();
                }}
                icon={
                    leftIcon ? (
                        leftIcon
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512"
                            fill={useRightLeftTriangles ? '#000000' : '#ffffff'}
                        >
                            <path d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z" />
                        </svg>
                    )
                }
                isLeftIcon
            />
            <CarouselButton
                useTriangle={useRightLeftTriangles}
                color={rightTriangleColor}
                disabled={disablePrevNext}
                clickHandler={() => {
                    if (slides.length !== 1) nextSlide();
                }}
                icon={
                    rightIcon ? (
                        rightIcon
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 256 512"
                            fill={useRightLeftTriangles ? '#000000' : '#ffffff'}
                        >
                            <path d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z" />
                        </svg>
                    )
                }
            />
            {/*carousel indicators*/}
            <CarouselIndicators
                position={indicatorPosition}
                nextActiveIndex={nextActiveIndex}
                indicatorsColor={indicatorsColor}
                clickHandler={onCarouselIndicator}
                slides={slides}
            />
        </div>
    );
};

/************ demo *************/
const slides = [
    {
        title: 'First title',
        subtitle: 'First subtitle',
        image: 'https://picsum.photos/id/1/1280/500',
    },
    {
        title: 'Second title',
        subtitle: 'Second subtitle',
        image: 'https://picsum.photos/id/234/1280/500',
    },
    {
        title: 'Third title',
        subtitle: 'Third subtitle',
        image: 'https://picsum.photos/id/790/1280/500',
    },
];
const testimonyslides = [
    {
        title: 'First title',
        subtitle: 'First ipsum dolor sit amet, populo numquam no pri. Ea natum philosophia qui, impedit admodum fuisset at cum. Alii cibo eripuit an eos, et mea clita essent omittam, ex vix posse tibique percipitur. Eos duis nulla ea, erat detraxit menandri sea an, vix tale modus alienum ei',
        image: 'https://picsum.photos/id/1/1280/500',
    },
    {
        title: 'Second title',
        subtitle: 'Second ipsum dolor sit amet, populo numquam no pri. Ea natum philosophia qui, impedit admodum fuisset at cum. Alii cibo eripuit an eos, et mea clita essent omittam, ex vix posse tibique percipitur. Eos duis nulla ea, erat detraxit menandri sea an, vix tale modus alienum ei',
        image: 'https://picsum.photos/id/234/1280/500',
    },
    {
        title: 'Third title',
        subtitle: 'Third ipsum dolor sit amet, populo numquam no pri. Ea natum philosophia qui, impedit admodum fuisset at cum. Alii cibo eripuit an eos, et mea clita essent omittam, ex vix posse tibique percipitur. Eos duis nulla ea, erat detraxit menandri sea an, vix tale modus alienum ei',
        image: 'https://picsum.photos/id/790/1280/500',
    },
];



const Template = styled.div`
--primarycolor: ${props => props.websiteData.primaryColor};
--secondarycolor: ${props => props.websiteData.secondaryColor};
width: 100%;
max-height: 100%;
overflow-y: auto;
position: absolute;

>div{
    padding-bottom: 40px;
}
.btn{background-color: var(--primarycolor);color: white;border-width: 0px;padding: 10px 30px; border-radius: 20px;outline:none !important}
.btn:hover{border: 2px solid var(--primarycolor); background-color: transparent;color: var(--primarycolor);}

.banner{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    background-color: ${props => props.websiteData.primaryColor + '83'};
    position: relative;
    overflow-x: hidden;
    
    >ul, >video{
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 0;
        transition: 1s;
    }
    >ul{
        ${'' /* animation: slide 10s linear infinite; */}
        img{
            width: 100%;
            height: 100%;
        }
    }
}
.signin{
    background-color: var(--primarycolor);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--primarycolor);
    width: max-content;
    :hover{
        background-color: transparent;
        color: var(--primarycolor);
    }
}
.about{
    padding: 10px 5% 0;
    width: 90%;
    margin: 35px auto;
    position: relative;
    box-shadow:0px 0px 20px 0px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: row-reverse;
}
.about img{
    width: 100%;
    height: 100%;
    display: block;
}
.about > .contdiv{
    padding: 0px;
    width: 100%;
    p{
        font-size: 12px;
        font-family: tahoma, arial, helvetica, sans-serif;
    }
}
.about > .imgdiv{
    box-shadow:1px 1px 20px 0px rgba(0,0,0,0.3);
    position: relative;
    width: min(40%, 400px);
    top: -30px;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    height: fit-content;
}
.innerBanner{
    /*background-image: url('./banner.png');*/
    height: 100%;
}
.nav1{
    min-height: 50px;
    padding: 10px 5%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    transition: 0.5s all;
}
.navbar-dropdown {
  position: relative;
  display: inline;
}
.navbar-dropdown:hover .dropdown {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}
.navbar-dropdown .dropdown {
  display: flex;
  flex-direction: column;
  gap: 5px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding: 20px;
  top: 100%;
  transform: translateY(50px);
  left: 0;
  background-color: #fff;
  box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  z-index: 111;
  transition: 0.4s all;
}
.navbar-dropdown .dropdown-content{
  transition: 0.5s;
  padding: 0;
  width: max-content;
  max-width: 500px;
}
.navbar-dropdown .dropdown-content:hover {
  translate: 10px;
}
.ham{
    display: none;
    font-size: 25px;
    position: relative;
    z-index: 2;
    color: var(--primarycolor);
    cursor: pointer;
}
.navdiv a{
    margin-right: 10px;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    color: var(--secondarycolor);
    line-height: 24px;
    :hover{
      color: var(--primarycolor) !important;
    }
}
.navbar-brand{
    color: var(--secondarycolor);
    font-size: 18px;
    font-weight: 500;
    padding: 0px !important;
    display: flex;
    align-items: center;
    :hover{
      color: var(--primarycolor);
    }
}
.sticky {
    background-color: #fff;
    box-shadow: 0px 15px 10px -15px #111;
    z-index: 21;
  }
.sticky a{
    color: #000;
}
.mainBaner1{
    padding: 10px 5%;
    display: flex;
    position: relative;
    min-height: 500px;
}
.mainBaner1 button{
    padding: 10px 60px; cursor: pointer;
    border-radius: 20px;
    background-color: #fff; font-size: 14px;
    border: 1px solid #fff;
    color: var(--primarycolor);
    :hover{
        color: var(--secondarycolor);
    }
}
.mainBaner1 > div:first-of-type{
    padding-top: ${props => props.websiteData.navbar ? 70 : 30}px ;
}
.mainBaner1 > div:first-of-type > div{
    background-color: ${props => props.websiteData.secondaryColor + 'cc'};
    padding: 20px; color: #fff;
}
.stats{
    text-align: center;
    /*set background image*/
}
.stats h2{color: var(--primarycolor)}
.eachStat p{
    margin-top: 0px;
}
.eachStat h1{
    margin-bottom: 0px;
    font-size: 24px;
    margin-top: 10px;
}
.eachStat span{
    font-size: 35px;
    color: var(--primarycolor)
}
.stats > div,.events > div, .award_patnerships > div{
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    width: 100%;
    gap: 2%;
}
.stats > div > div, .events > div > div{
    min-width: 180px;
    width: 23%;
    padding: 10px 15px;
}

.events{
    padding: 30px 5%;
    text-align: center;
    /*This should be background image*/
}
.events .hdertext{
    font-weight: bold;
}
.eachEvent{
    min-width: 250px !important;
    width: 45% !important;
    box-shadow: 0px 4px 10px -4px #111;
    border-radius: 5px;
    margin: 10px;
    :hover{
        box-shadow: 0px 4px 10px -4px var(--secondarycolor);
    }

    a {
        color: var(--primarycolor);
        :hover{
            color: var(--secondarycolor);
        }
    }
}
.eachEvent img{
    max-height: 250px;
    width: 100%;
}
.courses{
    width: 100%;
    padding: 30px 7% 50px;
    background-color: var(--primarycolor);
    color: #fff;
}
.courses > div{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.courses .dur{
    font-size: 14px;
}
.eachCourse{
    min-width: 210px;
    width: 25%; padding: 10px;
    >div>div:first-of-type{
        display: flex;
        gap: 5px;
        justify-content: space-between;
    }
}
.eachCourse img{
    height: 180px; width: 100%;;
}
.eachCourse p, h3{
    margin: 0;
}
.offerings{
    background-color: #f2f2f2;
    padding: 20px 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.offerings img{
    width: 100%;
    height: 100%;
}
.offerings > div > div{
    padding: 15px;
    height: 100%; width: 100%;
    :first-child{ margin-bottom: -30px;}
}
.offerings .ofText{
    padding-top: 5%;
}
.offerings > div{width: 100%; display: flex; align-items: center;}
.rowverse{flex-direction: row-reverse;}
.eachAward{
    width: 25%;
    padding: 10px;
    text-align: center;
    font-weight: 500;
    min-width: 150px;
}
.eachAward img{
    height: 180px;
    width: 100%;
}
.award_patnerships h2{
    color: var(--primarycolor); text-align: center;
}
.footer {
    background-color: #f2f2f2;
    padding: 20px 5%;
    width: 100%;
    margin: 0px;
    >div{ width: 100%; display: flex; flex-wrap: wrap;
        &:first-of-type>div{
            width: 30%;
        }
    }
}
.copyright {
    text-align: center;
}
&not(.width) {
    .offerings > div{
        height: 450px;
        width: 100%; float: left;
        margin-bottom: 20px;
    }
}
&.width{
    overflow-wrap: break-word;
    .ham{
        display: inline;
    }
    .dropdown{
        background-color: var(--secondarycolor);
    }
    .navdiv > div{
        position: absolute;
        top: 0px;
        right: 0px;
        min-width: 200px;
        background-color: var(--secondarycolor);
        display: none;
        border-radius: 15px;
        padding: 12px;.
    }
    .navdiv a{
        display: block;
        color: #fff;
    }
    .rowverse{
        flex-direction: column-reverse;
    }
    .row{
        flex-direction: column;
    }
    .eachEvent{
        width: 100% !important;
        min-width: unset !important;
    }
    .offerings > div{ flex-direction: column;}
    .eachCourse{
        min-width: 100%
    }
    .footer >div:first-of-type>div{ width: 100%; }
    
}


.gallery{
    background-color: var(--primarycolor);
    padding: 20px 5%;
    width: 100%;
    overflow: hidden;
    h2{
        font-size: 30px;
        margin-top: 20px;
        margin-bottom: 10px;color: #fff;
        text-align: center;
    }
}
.container2{
    // padding: 0px 0px 100px;
    position: relative;
}
    
.text-center {
  text-align: center;
}

hr {
  margin-top: 55px;
}

// ***********carousel styles***********

// keyframes
// move slide from left to the viewport
@keyframes animate-in-to-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

// move slide out of the viewport to the left
@keyframes animate-out-to-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

// move slide from right to the viewport
@keyframes animate-in-to-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

// move slide out of the viewport to the right
@keyframes animate-out-to-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

// classes to use the keyframs
.enter-to-left {
  animation: animate-in-to-left 600ms;
}

.exit-to-left {
  animation: animate-out-to-left 600ms;

  .carousel-caption {
    opacity: 0;
  }
}

.enter-to-right {
  animation: animate-in-to-right 600ms;
}

.exit-to-right {
  animation: animate-out-to-right 600ms;

  .carousel-caption {
    opacity: 0;
  }
}

.carousel-slider-wrapper {
  width: 100%;
  min-height: 300px;
  display: flex;
//   position: relative;
  overflow: hidden;

  & * {
    box-sizing: border-box;
  }

  .carousel-slide {
    background-color: #ffffff;
    margin: 0 auto;
    display: none;
    min-width: 100%;
    text-align: center;

    // the next class is required for the swipe effect
    &.swipe {
      user-select: none;
      pointer-events: none;
    }

    &.active-slide {
      display: block;
      background-color: #f3f3f3;

      &.next-active-slide {
        position: absolute;
        left: 0;
        top: 0;
      }

      // styles for carousel image
      img {
        width: 100%;
        display: block;
      }

      // styles for carousel caption
      .carousel-caption {
        position: absolute;
        right: 35px;
        bottom: 25px;
        left: 35px;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #ffffff;

        .carousel-caption-title,
        .carousel-caption-subtitle {
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
        }
        .carousel-caption-title {
          margin: 0 0 5px;
        }

        .carousel-caption-subtitle {
          margin: 0;
        }
      }

      // styles for testimonial
      &.testimony{
        background-color: #33333380;
        color: #fff;
        padding: 5px 10%;
        display: flex; gap: 10px;
        justify-content: center; flex-direction: column;
        >p{ text-align: left; }
        >div{
          >img{height: 60px; width: 60px;}
          display: flex; gap: 10px;
          align-items: center;
        }
      }
    }
  }

  // styles of the next and prev buttons
  .carousel-control {
    position: absolute;
    top: 0%;
    left: -0px;
    width: 65px;
    height: 100%;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0);
    filter: alpha(opacity=50);
    z-index: 0;
    background-image: linear-gradient(to right,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);
    display: flex; align-items: center;

    .left-triangle {
      width: 0;
      height: 0;
      border-top: 30px solid transparent;
      border-left: 35px solid #ffffff;
      border-bottom: 30px solid transparent;
      position: absolute;
      left: -1px;
      cursor: initial;
      transform: translateY(-50%);
    }

    .right-triangle {
      width: 0;
      height: 0;
      border-top: 30px solid transparent;
      border-right: 35px solid #ffffff;
      border-bottom: 30px solid transparent;
      position: absolute;
      right: -1px;
      cursor: initial;
      transform: translateY(-50%);
    }

    &.carousel-left-arrow {
      button {
        position: absolute;
        left: -1px;
        top: 200px;
        transform: translateY(-50%);
      }
    }

    &.carousel-right-arrow {
      right: -0px;
      left: auto;
      background-image: linear-gradient(to left,rgba(0,0,0,.5) 0,rgba(0,0,0,.0001) 100%);

      button {
        position: absolute;
        right: -1px;
        top: 200px;
        transform: translateY(-50%);
      }
    }

    button {
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
      height: 30px;

      &.padding-left-15 {
        padding-left: 15px;
      }

      &.padding-right-15 {
        padding-right: 15px;
      }

      svg {
        width: 15px;
        opacity: 0.5;
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }
      }

      i {
        opacity: 0.5;
        cursor: pointer;
        color: #000000;
        font-size: 28px;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  // styles of carousel indicators
  .carousel-indicators {
    position: absolute;
    bottom: 10px;
    z-index: 15;
    padding-left: 0;
    list-style: none;
    margin-top: 0;
    margin-bottom: 10px;

    &.position-left {
      left: 35px;
    }

    &.position-right {
      right: 35px;
    }

    &.position-center {
      left: 50%;
      transform: translateX(-50%);
    }

    li {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin: 1px;
      text-indent: -999px;
      cursor: pointer;
      background-color: #333;
      border: 1px solid var(--secondarycolor);
      border-radius: 10px;
      opacity: 1;

      &:hover {
        background-color: var(--primarycolor);
        opacity: 0.7;
      }

      &.active {
        width: 10px;
        height: 10px;
        margin: 0;
        background-color: var(--primarycolor);
        cursor: initial;
        opacity: 1;
      }
    }
  }
}

@media (min-width: 768px) {
  .carousel-slider-wrapper {
    .carousel-slide {
      &.active-slide {
        .carousel-caption {
          padding-bottom: 20px;
          left: 65px;
          right: 65px;
        }
      }
    }

    .carousel-control {
      .left-triangle {
        border-top: 50px solid transparent;
        border-left: 65px solid #ffffff;
        border-bottom: 50px solid transparent;
      }

      .right-triangle {
        border-top: 50px solid transparent;
        border-right: 65px solid #ffffff;
        border-bottom: 50px solid transparent;
      }
      button {
        height: 50px;
        svg {
          width: 25px;
        }
        i {
          font-size: 50px;
        }
      }
    }

    .carousel-indicators {
      &.position-left {
        left: 65px;
      }

      &.position-right {
        right: 65px;
      }

      li {
        width: 10px;
        height: 10px;

        &.active {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .carousel-slider-wrapper {
    .carousel-slide {
      &.exit-to-left,
      &.exit-to-right {
        .carousel-caption {
          opacity: 1;
        }
      }
    }
  }
}

`;