import React from "react";
import OnlineClass from './OnlineClassindex';
import { Routes, Route } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import Assignment from './AssignmentIndex'
import Note from './NoteIndex'
import Chat from './newChat';
import styles from '../../../css/InstitutionMgmt.module.css';
import Courses from "./Courses";
import {default as Dashboard} from './LearnDashboard';
import LearningMarket from "./LearningMarket";


const Learning = () => {
  return(
    <>
      <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Routes>
            <Route index element={<Dashboard />}/>
            <Route path="online/*" element={<OnlineClass />}/>
            <Route path="assignment/*" element={<Assignment />}/>
            <Route path="note/*" element={<Note />}/>
            <Route path='communication/*' element={<Chat />}/>
            <Route path="/*" element={<PageNotFound />} />
            <Route path="courses/*" element={<Courses />} />
            <Route path="marketplace/*" element={<LearningMarket />} />
          </Routes>
      </div>
    </>
  )
}


export default Learning;