import { createSlice } from "@reduxjs/toolkit";

/*const newSet = {
    id: '',
    name: '',
    description: '',
    year:'',
    isPaid: false,
    link: '',
    template: {}
}*/
/*const newGroup = {
    id: '',
    name: '',
    description: '',
    sets: [],
    // audience: {
    //     type:'',
    //     value: '',
    // },
    audience: false,
    audienceValue: {},
}*/
const newTemplate = {
    template:{
        //id: "",
        name: "",
        sections: {
            0: {
                id: 0,
                name: '',
                type: 'table',
                isDefault: -1,
                columnRowLen: [0],
                content: {
                    //columns:{
                        0:{
                            id: 0,
                            name:'',
                            type:'',
                            extra: '',
                            extraValue: '',
                            sumval: false
                        }
                }
            }
        }
    }
}

export const resultSlice = createSlice({
    name: 'results',
    initialState: {
        templates: {},
        currentTemplate: {...newTemplate},
        sets: {},
        groups: [],
        userResults: [],
        allUserResult: [],
        userResult:{},
        currentUserResult:{},
        courses: []
    },
    reducers: {
        //Result template methods
        changeTemplate:(state, action)=>{
            !action.payload.id? state.currentTemplate={...newTemplate} :state.currentTemplate = action.payload
        },
        addTemplate: (state, action) => {
            state.templates[action.payload.id]= action.payload;
        },
        deleteTemplate: (state, action) => {
            delete state.templates[action.payload];
        },
        setTemplates: (state, action) => {
            state.templates = action.payload;
        },
        setTemplateId: (state, action) => {
            let a = { ...state.nullTemplates[action.payload.oldId] };
            a.id = action.payload.newId;
            deleteTemplate(action.payload.oldId)
            addTemplate({ ...a });
        },
        setTemplateName: (state, action) => {
            state.currentTemplate.template.name = action.payload.name;
        },
        addSection: (state, action) => {
            state.currentTemplate.template.sections[action.payload] = {...newTemplate.template.sections[0], id: action.payload, type: 'comment'};
        },
        setDefaultResultSection: (state, action)=>{
            //state.currentTemplate.template.sections[action.payload.secId].isDefault = action.payload.secId;
            const tem = state.currentTemplate.template;
            for(let x in tem.sections){
                tem.sections[x].isDefault = action.payload.secId;
            }
            state.currentTemplate.template = tem;
        },
        deleteSection: (state, action) => {
            delete state.currentTemplate.template.sections[action.payload.sectionId]
        },
        setSectionName: (state, action) => {
            state.currentTemplate.template.sections[action.payload.sectionId].name = action.payload.name;
        },
        setSectionType: (state, action) => {
            state.currentTemplate.template.sections[action.payload.sectionId].type = action.payload.type;
            state.currentTemplate.template.sections[action.payload.sectionId].content = {};
        },
        setNewContent: (state, action) => {
            state.currentTemplate.template.sections[action.payload.sectionId].content = {...newTemplate.template.sections[0].content};
        },
        addColumn: (state, action) => {
            state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.column.id] = action.payload.column;
        },
        deleteColumn: (state, action) => {
            if(action.payload.typeName == "Column") delete state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id];
            else delete state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id];
        },
        setColumnName: (state, action) => {
            state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].name = action.payload.name;
            state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].type = "txt";
            //console.log(state.currentTemplate.template, 'currentTemp2')
        },
        setColumnType: (state, action) => {
            state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].type = action.payload.type;
            //console.log(state.currentTemplate.sections, 'currentTemp')
        },
        updateExtraData: (state, action)=>{
            let extraArr = []
            if(action.payload.pos == 0){
                if(state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extra){
                    extraArr = JSON.parse(state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extra);
                    extraArr[action.payload.index] = action.payload.text;
                    state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extra = JSON.stringify(extraArr);
                }
                else{
                    extraArr[action.payload.index] = action.payload.text;
                    state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extra = JSON.stringify(extraArr);
                }
            }
            else{
                if(state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extraValue){
                    extraArr = JSON.parse(state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extraValue);
                    extraArr[action.payload.index] = action.payload.text;
                    state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extraValue = JSON.stringify(extraArr);
                }
                else{
                    extraArr[action.payload.index] = action.payload.text;
                    state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extraValue = JSON.stringify(extraArr);
                }
            }

            //console.log(extraArr, "Extra data")
        },
        removeExtraData: (state, action)=>{
            if(state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extra){
                let extraArr = JSON.parse(state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extra);
                let extraArr2 = JSON.parse(state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extraValue);

            let len = extraArr.length;
            let newExtraArr = []; let newExtraArr2 = [];

            for(let i = 0; i < len -1; i++){
                newExtraArr.push(extraArr[i]);
                newExtraArr2.push(extraArr2[i]);
            }
            state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extra = JSON.stringify(newExtraArr);
            state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extraValue = JSON.stringify(newExtraArr2);
            }         
        },
        setSumVal: (state, action)=>{
            state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].sumval = action.payload.sumval;
        },
        setExtra: (state,action)=>{ //console.log(action.payload,"payload")
            if(action.payload.type == 'string') state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extra = action.payload.extra;
            else if(action.payload.type == 'array'){
                let extraArr = []
                if(state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extra){
                    extraArr = JSON.parse(state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extra);
                    extraArr.push(action.payload.extra);
                    state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extra = JSON.stringify(extraArr);
                }
                else{
                    extraArr.push(action.payload.extra);
                    state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extra = JSON.stringify(extraArr);
                }
            }
            // console.log(state.currentTemplate,"template")
        },
        setExtraValue: (state,action)=>{
            if(action.payload.type == 'string') state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extraValue = action.payload.value;
            else if(action.payload.type == 'array'){
                let extraArr = []
                if(state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extraValue){
                    extraArr = JSON.parse(state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extraValue);
                    extraArr.push(action.payload.value);
                    state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extraValue = JSON.stringify(extraArr);
                }
                else{
                    extraArr.push(action.payload.value);
                    state.currentTemplate.template.sections[action.payload.sectionId].content[action.payload.id].extraValue = JSON.stringify(extraArr);
                }
            }
        },
        setUserResult:(state,action)=>{
            let newUserResult = {}
            const result = action.payload.result
            for(let x in result){
                newUserResult[x] = [{}];
                for (const key in result[x].content) {
                    newUserResult[x][0][key] = ''
                }
            }
            state.userResult = newUserResult;
            state.currentUserResult = newUserResult;
            if (action.payload.editing) {
                state.userResult = result;
            }
            //console.log(newUserResult);
        },
        AddTableRow: (state,action)=>{
            let newUserRes = state.userResult;
            newUserRes[action.payload.sectionId] = [
                ...newUserRes[action.payload.sectionId],
                state.currentUserResult[action.payload.sectionId][0]
            ]
        },
        DeleteTableRow: (state, action)=>{
            const len = state.userResult[action.payload.sectionId].length;
            const newRes = [];
            for(let i = 0; i < len - 1; i++){
                newRes[i] = state.userResult[action.payload.sectionId][i];
            }

            state.userResult[action.payload.sectionId] = newRes;
        },
        /*addRow: (state, action)=>{
            state.currentTemplate.template.sections[action.payload.sectionId].content.rows[action.payload.row.id] = action.payload.row;
        },
        deleteRow: (state, action)=>{
            delete state.currentTemplate.template.sections[action.payload.sectionId].content.rows[action.payload.id];
        },
        setRowName: (state, action)=>{
            state.currentTemplate.template.sections[action.payload.sectionId].content.rows[action.payload.id].name = action.payload.name;
        },*/
        //End of result template methods

        //Result group methods
        setResultGroups:(state,action) =>{
            state.groups = action.payload
        },
        addResultGroup:(state,action) =>{
            //state.groups[action.payload.id] = action.payload
            if (action.payload.result_set_id) {
                action.payload.set_id = action.payload.result_set_id;
                delete action.payload.result_set_id;
            }
            state.sets[action.payload.set_id].groups = [action.payload, ...state.sets[action.payload.set_id].groups, ]
        },
        editResultGroup:(state,action) =>{
            if (action.payload.result_set_id) {
                action.payload.set_id = action.payload.result_set_id;
                delete action.payload.result_set_id;
            }
            state.sets[action.payload.set_id].groups = state.sets[action.payload.set_id].groups.map(x=>x.id !== action.payload.id?x : action.payload);
        },
        deleteGroup:(state,action) =>{
            state.sets[action.payload.set_id].groups = state.sets[action.payload.set_id].groups.filter(x=>x.id !== action.payload.id)
        },
        //End of result group methods

        //Result sets methods
        setResultSets:(state,action) =>{
            state.sets = action.payload
        },
        addResultSet:(state,action) =>{
            state.sets[action.payload.id] = action.payload
        },
        deleteSet:(state,action) =>{
            delete state.sets[action.payload]
        },
        //End of result set methods  

        //User results methods
        setUsersResults: (state,action)=>{
            state.userResults = action.payload
        },
        addUserResult:(state,action) =>{
            /**
             * userResult = 
             *  {
             *    '0': {
             *      heightest:{
             *          userId, number, rowId
             *      },
             *      lowest:{
             *          userId, number, rowId
             *      },
             *      average:{
             *          userId, number, rowId
             *      },
             *      totalUsers: number
             *    }
             *    groupId: [
             *      
             *    ]
             *  }
             * 
             */
            state.userResults = [
                action.payload,
                ...state.userResults,
            ]
        },
        deleteUserResult:(state,action) =>{
            state.userResults = state.userResults.filter(x=>x.id !== action.payload.id)
        },
        //End of user result methods
        deleteAllUserResult:(state,action) =>{
            state.allUserResult = state.allUserResult.filter(x=>x.id !== action.payload.id)
        },
        setAllUserResult: (state, action)=>{
            state.allUserResult = [...action.payload]
        },
        addToUsersResults: (state, action)=>{
            let resIndex = state.allUserResult.findIndex(result => result.id === action.payload.id)
            if (resIndex >= 0) {
                state.allUserResult[resIndex] = action.payload;
            }else {
                state.allUserResult = [
                    action.payload,
                    ...state.allUserResult,
                ]
            }
        },
        AddToUserResult: (state,action)=>{
            let result = state.userResult;
            if(!result[`${action.payload.sectionId}`][action.payload.row])
                result[`${action.payload.sectionId}`][action.payload.row] = {}
            
            result[`${action.payload.sectionId}`][action.payload.row][`${action.payload.column}`] = action.payload.value;
            
            state.userResult = result;
        },
        //Result course methods
        setCourses:(state,action) =>{
            state.courses = action.payload
        },
        addCourse:(state,action) =>{
            state.courses = [ action.payload, ...state.courses,]
        },
        deleteCourse:(state,action) =>{
            state.courses = state.courses.filter(x=>x.id !== action.payload.id)
        },
        editCourse:(state,action) =>{
            state.courses = state.courses.map(x=>x.id === action.payload.id? action.payload: x)
        },
        addCourseSection:(state,action) =>{
            state.courses.forEach(course => {
                if (course.id === action.payload.course_id) {
                    course.sections = !course.sections?[action.payload] : [ ...course.sections, action.payload]
                }
            })
        },
        deleteCourseSection:(state,action) =>{
            state.courses.forEach(course => {
                if (course.id === action.payload.course_id) {
                    course.sections = course.sections.filter(sec => sec.id !== action.payload.id);
                }
            })
        },
        editCourseSection:(state,action) =>{
            state.courses.forEach(course => {
                if (course.id === action.payload.course_id) {
                    course.sections.forEach(sec => {
                        if (sec.id === action.payload.id) {
                            sec = {...sec, ...action.payload}
                        }
                    })
                }
            })
        },
        addCourseResource:(state,action) =>{
            state.courses.forEach(course => {
                if (course.id === action.payload.course_id) {
                    course.sections.forEach(section => {
                        if (section.id === action.payload.section_id) {
                            section.resources = [...section.resources, action.payload]
                        }

                    })
                }
            })
        },
        deleteCourseResource:(state,action) =>{
            state.courses.forEach(course => {
                if (course.id === action.payload.course_id) {
                    course.sections.forEach(section => {
                        if (section.id === action.payload.section_id) {
                            section.resources = section.resources.filter(res => res.id !== action.payload.id);
                        }

                    });
                }
            })
        },
        editCourseResource: (state, action) => {
            state.courses.forEach(course => {
                if (course.id === action.payload.course_id) {
                    course.sections.forEach(section => {
                        if (section.id === action.payload.section_id) {
                            section.resources = section.resources.map(resource => {
                                if (resource.id === action.payload.id) {
                                    resource = action.payload
                                }
                                return resource
                            });
                        }

                    });
                }
            })
        },
        //End of result group methods

    }
})

export const {
    changeTemplate ,addTemplate, deleteTemplate, setTemplates, setTemplateName,
    addSection, deleteSection, setSectionName, setSectionType, setNewContent,
    setTotal, addColumn, deleteColumn, setColumnName, setColumnType,setExtra, setExtraValue,
    setResultSets, addResultSet, deleteSet,
    setResultGroups, addResultGroup, deleteGroup, editResultGroup,
    setUserResult, setUsersResults, addUserResult, deleteUserResult, AddTableRow, DeleteTableRow,
    AddToUserResult, setDefaultResultSection, setAllUserResult, deleteAllUserResult,
    setCourses, addCourse, deleteCourse, editCourse,
    addCourseSection, deleteCourseSection, editCourseSection,
    addCourseResource, deleteCourseResource, editCourseResource,
    updateExtraData, removeExtraData, addToUsersResults, setSumVal
} = resultSlice.actions

export default resultSlice.reducer;