import React from 'react';
import footerCss from '../../css/footerCss.module.css';
import '../../css/bootstrapIcons/bootstrap-icons.css';
import {Link} from 'react-router-dom';
import { MdEmail } from "react-icons/md";
const Footer = () => {
  return (
    <React.Fragment>
        <footer className={footerCss.footer}>
            <div>
                <ul>
                    <li>Learn About Eduplus</li>
                    <li><Link to=""> About Eduplus</Link></li>
                    <li><Link to=""> EduPlus Career</Link></li>
                    <li><Link to=""> Blog</Link></li>
                </ul>

                <ul>
                    <li>SERVICES</li>
                    <li><Link to="/services"> Services for Institutions </Link></li>
                    <li><Link to="/services"> Services for Tutors</Link></li>
                    <li><Link to="/services"> Services for Student</Link></li>
                    <li><Link to="/services"> Services for All</Link></li>
                </ul>

                <ul>
                    <li>COMPANY</li>
                    <li><Link to="/support#faq"> FAQ</Link></li>
                    <li><Link to="policies/terms-of-use"> Terms and Condition</Link></li>
                    <li><Link to="/support#doc"> Doc & Training</Link></li>
                </ul>
          
         
                <div>
                    <div className={footerCss.button}><Link to="/account/sign-up">Create EduPlus Account</Link></div>
                    <div className={footerCss.social}>
                        <a href='https://x.com/try_eduplus' target='_blank'><i className="bi-twitter-x icon"></i></a>
                        <a href="https://facebook.com/sighteduplus" target='_blank'><i className="bi-facebook icon"></i></a>
                        <a href="https://instagram.com/tryeduplus" target='_blank'><i className="bi-instagram icon"></i></a>
                        <a href="https://www.linkedin.com/company/sight-eduplus" target='_blank'><i className="bi-linkedin icon"></i></a>
                        <a href="https://youtube.com/@sight-eduplus-lv3bq?si=XWFeoGznMWLYdkoY" target='_blank'><i className="bi-youtube icon"></i></a>
                        <a href="https://www.tiktok.com/@sighteduplus" target='_blank'><i className="bi-tiktok icon"></i></a>
                    </div>
                    <div style={{color:'#fff', display:'flex'}}>
                        <span style={{fontSize: 35}}><MdEmail />&nbsp;</span><span style={{display:'inline-block',paddingTop: 7}}>contact@sight-eduplus.com</span>
                    </div>

                </div>
            </div>
            <div>
                <Link to="policies/privacy-policy">Privacy Policy  </Link> |
                <Link to="policies/terms-of-use">Terms of Use  </Link>|
                <Link to="policies/privacy-policy/cookie-notice">Cookie Notice  </Link>
                <span>&copy; &nbsp;2024 SIGHT-EDUPLUS</span> 
            </div>
        </footer>
    </React.Fragment>
  )
}

export default Footer