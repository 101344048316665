import styles from '../css/CreateInst.module.css';
import style from "../css/images.module.css";
import { useState, useRef, useEffect } from "react";
import Api from '../helperFunctions/Api2';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addFile, removeFile, } from "../redux/reducers/serviceReducer";
// import { MenuContext } from "../views/userAdmin/DashBoard";
import styled from "styled-components";
import { FaFile, FaFileAlt, FaFileArchive, FaFileAudio, FaFileExcel, FaFileImport, FaFileVideo, } from "react-icons/fa";

const UploadedFiles = ({ modalTogglee, closeModal, onCopy, type, setShowLoading = () => { } }) => {
    const [shouldFileeDivShow, setShouldFileeDivShow] = useState(false);
    const files = useSelector((state) => state.services.files);
    const fileInput = useRef();
    const dispatch = useDispatch();
    const [imgIndex, setImgIndex] = useState(-1);
    const [selFile, setSelFile] = useState(null);

    function copyFile (imgIndex, e) {
        e.preventDefault();
        if (imgIndex < 0) {
            alert("Please select a file");
        }
        else {
            var textField = document.createElement('textarea');
            textField.innerText = files.find(file => file.id === imgIndex).file_full_name;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            onCopy && onCopy(textField.innerText);
            alert('File link copied successfully');
            closeModal();
        }

    }

    return (
        <Container className={style.modal} style={{ display: modalTogglee ? "block" : "none" }}>
            <div className={style.modal_content}>
                <div className={style.modal_header}>
                    <span className={style.close} onClick={closeModal}>&times;</span>
                    <span className={style.upload_wrapper}>
                        <button>Upload New File</button>
                        <input ref={fileInput} type="file" accept="*" onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                                const reader = new FileReader();
                                reader.onload = function () {
                                    console.log(e.target.files[0].type);
                                    setSelFile({
                                        filename: e.target.files[0].name,
                                        filetype: e.target.files[0].type,
                                        fileimage: reader.result,
                                        datetime: e.target.files[0].lastModifiedDate.toLocaleString('en-IN'),
                                        filesize: filesizes(e.target.files[0].size)
                                    });
                                    setShouldFileeDivShow(true);
                                };
                                reader.readAsDataURL(e.target.files[0]);
                            }
                        }} />
                    </span>
                </div>
                <div className={style.modal_body}>
                    {shouldFileeDivShow && <div >
                        <div className="file-atc-box">
                            <div className="file-image">{handleFileIcon(selFile.filetype, selFile.fileimage)}</div>
                            <div className="file-detail">
                                <h6>{selFile.filename}</h6>
                                <p>
                                    <span>Size : {selFile.filesize}</span>
                                    <span className="">Modified Time : {selFile.datetime}</span>
                                </p>
                            </div>
                        </div>
                        <Link className={style.styledlink} onClick={() => {
                            setShowLoading(true);
                            let fileToBeUploaded = new FormData();
                            fileToBeUploaded.append('file', fileInput.current.files[0]);
                            fileToBeUploaded.append('file_type', selFile.filetype);
                            fileToBeUploaded.append('file_size', fileInput.current.files[0].size);

                            Api.PostFormData('/files/save-file', fileToBeUploaded, true)
                                .then(result => {
                                    // console.log(result);
                                    if (result.isSuccessful) {
                                        dispatch(addFile(result.data));
                                        setShouldFileeDivShow(false);
                                        alert('File saved successfully');
                                    }
                                    else {
                                        alert("Action failed");
                                    }
                                }).catch(err => console.log(err)).finally(() => {
                                    setShowLoading(false);
                                });
                        }}>save selected file</Link>
                    </div>}
                    <div className="kb-attach-box">{files.length > 0 ?
                        files.map((data, index) => {
                            const { original_file_name, file_full_name, file_size, file_type, id } = data;
                            return (
                                !type || type === 'file' || file_type.match(type) ? <div key={index} className={"file-atc-box " + style.imgDiv} onClick={() => setImgIndex(id)} onDoubleClick={e => copyFile(id, e)} style={{ border: imgIndex === id ? '2px solid var(--primary-color)' : '2px solid transparent' }}>
                                    <div className="file-image">{handleFileIcon(file_type, file_full_name)}</div>
                                    <div className="file-detail">
                                        <h6>{original_file_name}</h6>
                                        <p>Size : {filesizes(file_size)}</p>
                                    </div>
                                </div> : <div key={index} ></div>
                            );
                        })
                        : <>No file have been uploaded by you</>
                    }</div>
                </div>
                <div className={style.modal_footer}>
                    <div className={style.btnDiv}>
                        <button onClick={(e) => {
                            e.preventDefault();
                            if (imgIndex < 0) {
                                alert("Please select a file");
                            }
                            else {
                                if (window.confirm('Are you sure you want to delete this file?')) {
                                    setShowLoading(true);
                                    Api.PostApi('/files/delete-file', { id: imgIndex }, true)
                                        .then(result => {
                                            // console.log(result, "just res");
                                            alert(result.message);
                                            if (result.isSuccessful) {
                                                dispatch(removeFile({ id: imgIndex }));
                                            }
                                        })
                                        .finally(() => {
                                            setShowLoading(false);
                                        });
                                }
                            }
                        }}>Delete File</button>&nbsp;
                        <button onClick={e => copyFile(imgIndex, e)}>Copy File Link</button>
                    </div>
                </div>
            </div>
        </Container>
    );

};

const handleFileIcon = (filetype, fileimage) => {

    return (
        filetype.match(/image/i) ? <img src={fileimage} alt="" /> :
            filetype.match(/audio/i) ? <FaFileAudio /> :
                filetype.match(/video/i) ? <FaFileVideo /> :
                    filetype.match(/text/i) ? <FaFileAlt /> :
                        filetype.match(/zip/i) ? <FaFileArchive /> :
                            filetype.match(/sheet/i) ? <FaFileExcel /> :
                                filetype.match(/application/i) ? <FaFileImport /> :
                                    <FaFile />
    );
};

const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};


export default UploadedFiles;

export const DisplayFile = ({ fileurl }) => {
    const files = useSelector((state) => state.services.files);
    const file = files.find(f => f.file_full_name === fileurl);
    if (!file) {
        return <Container>File Not Found</Container>
    }
    const { original_file_name, file_full_name, file_size, file_type } = file;

    return <Container>
        <div className={"file-atc-box " + style.imgDiv}>
            <div className="file-image">{handleFileIcon(file_type, file_full_name)}</div>
            <div className="file-detail">
                <h6>{original_file_name}</h6>
                <p>Size : {filesizes(file_size)}</p>
            </div>
        </div>
    </Container>;
};
const Container = styled.div`
width: 100%;

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: #475F7B;
}

a, a:hover, a:focus {
    transition: 0.5s;
    outline: none;
}

/* ------------ */

.card {
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    border-radius: 6px;
    overflow: hidden;
    background-color: #fff;
    border: none;
    >span:first-child{ 
        margin-right: 10px;
        font-size: 35px;
    }
}
.kb-attach-box{
    display: inline-flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;
    // justify-content: space-between;
}
.file-atc-box {
    display: flex;
    align-items: center;
    // margin-bottom: 15px;
}
.file-image {
    /* width: 130px;
    min-width: 130px;
    height: 85px;
    min-height: 85px; */
    width: 100px;
    height: 85px;
    background-size: cover;
    border-radius: 5px;
    margin-right: 5px;
    background-color: #eaecf1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #475f7b;
    padding: 3px;
}
.file-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
}
.file-detail {
    flex: 1;
    width: calc(100% - 210px);
    text-align: left;
}
.file-detail h6 {
    word-break: break-all;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    margin-bottom: 8px;
}
.file-detail p {
    font-size: 12px;
    color: #8194aa;
    // line-height: initial;
    // font-weight: 400;
    // margin-bottom: 8px;
    margin: 0;
    display: flex;
    flex-direction: column;
}
.file-atc-box:last-child{
    margin-bottom: 0;
}
`;

export function UploadedFileOnly ({type = 'file', title = '', setShowLoading,  onCopy, value, labelStyle}) {
    const [fileToggle, setFileToggle] = useState(false);
    const [appointmentVideo, setAppointmentVideo] = useState('');
    const { reg , name } = typeof type === 'object' ? { reg: type.type, name: type.name } : { reg: type, name: type };
    
    useEffect(() => {
        if (value !== appointmentVideo) {
            setAppointmentVideo(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return <><UploadedFiles modalTogglee={fileToggle} closeModal={() => setFileToggle(false)} setShowLoading={setShowLoading} onCopy={(e) => { setAppointmentVideo(e); onCopy(e) }} type={reg} />
        <label className={`${styles.label}`} title={`Copy ${name} url from "Upload ${name}"`}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', ...labelStyle }}>
                {title}
                <span className={styles.styledlink} onClick={() => setFileToggle(true)}>Upload {name}</span>
            </div>
            <input className={`${styles.fields}`} type='text' placeholder={(name + " url")} value={appointmentVideo} onChange={(e) => { setAppointmentVideo(e.target.value); }} onBlur={()=>onCopy && onCopy(appointmentVideo)} />
        </label></>;
}