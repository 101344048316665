import { Link } from 'react-router-dom';
import css from './css/dashboard.module.css';
import {default as CbtChart} from './CbtChart';
import { useState } from 'react';
import VideoPlayer from '../../../components/VideoPlayer';


const CbtDashboard = () => {
  const [showVideo, setShowVideo] = useState(false);
  
  return (
    <div className={css.container}>
        <div>
          <div>CBT DASHBOARD</div>
          <div>
            <div className={css.graphContainer}>
              <CbtChart />
            </div>
            <div className={css.infoContainer}>
              <div className={css.head}>EDUPLUS CBT</div>
              <p className={css.body}>
                Eduplus CBT is the world most advanced CBT service, which enables you to set CBT for groups of people irrespective of timezone, discipline, etc.

              </p>
              <div className={css.foot}>
                <Link to="../create-cbt">Get Started</Link>
                <Link to="" onClick={()=>setShowVideo(!showVideo)}>Watch Video</Link>                
              </div>
            </div>
          </div>
          {showVideo && <VideoPlayer serviceCode={'CBT'}/>}
        </div>
    </div>
  )
}

export default CbtDashboard