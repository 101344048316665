import React from "react";
import styles from '../../../css/StaffMgmt.module.css';
import titleStyle from '../../../css/SectionTitle.module.css';
import { Routes, Route } from "react-router-dom";
import PageNotFound from '../../404/PageNotFound';
import { useState, createContext } from "react";
import Create from './createEvents';
import View from "./viewEvents";
import Modal from '../../../components/modal';
import Menu from "../../../components/Menu";

export const staffContext = createContext();
export const eventModalContext = createContext();


const Events = () => {

    const [modalObject,setModalObject] = useState({});
    const [modalToggle,setModalToggle] = useState(false);
    const [view, setView] = useState('');
    const [title, setTitle] = useState('');
    const [editEventCon, setEditEventCon]  = useState(null);    
    const [editAppointmentCon, setEditAppointmentCon]  = useState(null);    

    return ( 
        <>
            <staffContext.Provider value={{ view, setView }}>
                <div className={`${styles.baseView}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <h1 className={`${styles.title}`}>Events</h1>
                    <div className={`${styles.subView}`}>
                        <div className={titleStyle.sectionTitle}>
                            <h3 className={titleStyle.h3}>
                                {title}
                            </h3>
                     
                            <Menu options={[
                                {label:'Add Events',path:'create-event'},
                                {label:'View Events',path:'view-event'},
                            ]} />
                        </div>
                        <eventModalContext.Provider value={{ modalObject, setModalObject, modalToggle, setModalToggle, editEventCon, setEditEventCon, editAppointmentCon, setEditAppointmentCon }} >
                            <Routes>
                                <Route path='*' element={<View setTitle={setTitle} />} />
                                <Route path='/create-event' element={<Create seTTitle={setTitle} />} />
                                <Route path='/view-event' element={<View setTitle={setTitle} />} />
                                <Route path="/*" element={<PageNotFound />} /> 
                            </Routes> 
                            <Modal modalObject={modalObject} modalTogglee={modalToggle} closeModal={()=>{setModalToggle(false)}} />
                        </eventModalContext.Provider>
                        
                    </div>
                </div>
            </staffContext.Provider>
        </>
     );
}
 
export default Events;
