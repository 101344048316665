import React, { useEffect, useState } from "react";
import css from "./css/addBilling.module.css";
import { useSelector, } from "react-redux";
import Modal from '../../../components/modal';
import BillPayment from "./BillPayment";
import Loading from "../../../components/loading";
import PageNumber from '../../../components/PageNumber';
const NO_OF_ITEMS_PER_PAGE = 30;

const AddBilling = () => {
  const [modalObject, setModalObject] = useState({ header: '', body: '', footer: '' });
  const [modalTogglee, setModalTogglee] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(0);

  const handlePay = () => {
    setModalObject({ header: 'Bill Payment', body: <BillPayment setShowLoading={setShowLoading} setModalTogglee={setModalTogglee} />, footer: '' });
    setModalTogglee(true);
  }

  const [total, setTotal] = useState(0);
  const values = useSelector(store => store.billing.values);

  useEffect(() => {
    // console.log(values)
    if (values.length > 0) {
      let totalVal = 0;
      values.forEach(val => {
        totalVal += Number(val.amount);
      });
      setTotal(totalVal)
    }
    else setTotal(0)
  }, [values])
  useEffect(() => {
    if (values.length > NO_OF_ITEMS_PER_PAGE) {
      setPageNum((values.length / NO_OF_ITEMS_PER_PAGE) + ((values.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0))
    }
  }, [values.length]);
  return (
    <>
      <Loading shouldShow={showLoading} />
      <Modal modalObject={modalObject} modalTogglee={modalTogglee} closeModal={() => { setModalTogglee(false) }} />
      <div className={css.container}>
        <p>NOTE: All amount in USD</p>
        <div className={css.total}>
          <div>
            <span>Total amount due:</span>
            <span>{total}</span>
          </div>
          <div>
            <button onClick={handlePay} >PAY NOW</button>
          </div>
        </div>
        <div className={css.title} >
          <span>SERVICE NAME</span>
          <span>AMOUNT</span>
          <span>PENDING</span>
        </div>
        {values && values.length > 0 &&
          values.map((value, index) =>
            index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
            <div key={index} className={css.billList}>
              <div>
                  <span>{value.service_name}</span> <span> {value.amount}</span> <span>{value.pending_amount}</span>
              </div>

            </div>)}
        {values.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

      </div>
    </>

  );
};

export default AddBilling;
