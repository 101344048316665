import React from "react";
import styling from '../../../css/Categories.module.css';
import { useEffect, useState, useContext } from 'react';
import { eventModalContext } from "./events";
import { useSelector, useDispatch } from 'react-redux';
import css from './Css/view-event.module.css';
import { EditComponent } from "../../../components/EditComponent";
import Api from "../../../helperFunctions/Api2";
import { deleteAppointmentSession, deleteTimeSlot } from "../../../redux/reducers/eventReducer";
import PageNumber from '../../../components/PageNumber';
import { formatDate } from "../../../helperFunctions/calendar";
import { MenuContext } from "../DashBoard";
import Select from "../../../components/Select";
import { useNavigate } from "react-router-dom";
import { BiXCircle } from "react-icons/bi";
const NO_OF_ITEMS_PER_PAGE = 30;


const ViewAppointmentSessions = ({ setTitle }) => {

    const navigate = useNavigate();
    const { setShowLoading, selected, changeSelected } = useContext(MenuContext);
    const dispatch = useDispatch();
    const appointments = useSelector(state => state.event.appointments);
    const { setModalToggle, setModalObject, setEditSessionCon } = useContext(eventModalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(0);
    const [appointment, setAppointment] = useState(null);
    const [sessions, setSessions] = useState([]);

    function deleteTime ({id, session_id}) {
        setShowLoading(true);
        const payload = {
            id,
            appointment_id: appointment.id,
            session_id
        };
        Api.PostApi('/event/delete-timeslot', payload, true)
            .then(result => {
                // console.log(result, payload, 'server result');
                if (result.isSuccessful) {
                    dispatch(deleteTimeSlot(payload));
                }
            })
            .finally(() => {
                setShowLoading(false);
                // alert('Action failed. please   re-log in');
            });
    }
    const handleDelete = (id) => {
        if (!Api.checkNetwork()) return false;

        setShowLoading(true);
        Api.PostApi('/event/delete-session', { session_id: id, appointment_id: appointment.id }, true)
            .then(result => {

                // console.log(result, "Server result");
                setModalObject({ header: '', body: result.message, footer: '' });
                if (result.isSuccessful) {
                    dispatch(deleteAppointmentSession({ session_id: id, appointment_id: appointment.id }));
                    // setMenuIndex(null);
                }
                setModalToggle(true);
                setShowLoading(false);
            })
            .catch(error => {
                setShowLoading(false);
                console.log(error, "Server Error");
            });
    };

    const handleDeleteBtn = (id) => {
        setModalObject({
            header: <>Confirmation Message</>,
            body: <>Are you want to delete this Session. Deleting this
                This event will remove all related record to the session.
            </>,
            footer: <>
                <div>
                    <button onClick={() => setModalToggle(false)}>
                        Cancel
                    </button>
                    <button onClick={() => handleDelete(id)}>
                        Delete
                    </button>
                </div>

            </>
        });
        setModalToggle(true);
    };

    const handleEditBtn = (ev) => {
        setEditSessionCon(ev);
        navigate('../add-session');
    };

    useEffect(() => {
        setTitle('View sessions');
        changeSelected(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTitle]);


    useEffect(() => {
        appointment && setAppointment(appointments.find(app => appointment.id === app.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointments]);
    useEffect(() => {
        // console.log(appointment);
        setSessions(appointment && appointment.sessions ? appointment.sessions : []);
    }, [appointment]);

    useEffect(() => {
        if (sessions.length > NO_OF_ITEMS_PER_PAGE) {
            setPageNum((sessions.length / NO_OF_ITEMS_PER_PAGE) + ((sessions.length % NO_OF_ITEMS_PER_PAGE) ? 1 : 0));
        }
    }, [sessions.length]);

    return (
        <>
            <div className={styling.form}>
                <label className={styling.label}>
                    <span className={styling.isRequired}>Appointment</span>
                    <Select options={appointments} onChange={setAppointment} placeholder="Select Appointment" defaultValue={appointment} label='title' value="id" />
                </label>
                <div className={`${styling.viewQuestionContainer} ${css.container}`}>
                    {sessions.length > 0 ? sessions.map((date, index) =>
                        index < (currentPage * NO_OF_ITEMS_PER_PAGE) && index >= ((currentPage - 1) * NO_OF_ITEMS_PER_PAGE) &&
                        <div key={index}>
                            <EditComponent index={index} menuIndex={selected} clickDelete={() => handleDeleteBtn(date.id)} setMenuIndex={changeSelected}
                                clickEdit={() => handleEditBtn(date)}
                            />
                            <h3 className={css.date} key={date.session_date}>{date.session_date && formatDate(date.session_date)}</h3>

                            <div>{date.bookings && date.bookings.map((times, index) => <div className={css.times} key={index}>
                                <span>{times.start_time.slice(0, 5)}</span> - <span>{times.end_time.slice(0, 5)}</span>,
                                <span> {times.slots} slots</span>
                                <BiXCircle className={css.deleteTime} onClick={() => deleteTime(times)} />
                            </div>)}</div>
                        </div>
                    ) : !appointment || !appointment.id ? <p style={{ textAlign: 'center', width: '100%' }}>Select an appointment to view sessions</p> : <p style={{ textAlign: 'center', width: '100%' }}>No Session Created yet</p>}
                </div>
                {sessions.length > NO_OF_ITEMS_PER_PAGE && <PageNumber currentPage={currentPage} setCurrentPage={setCurrentPage} pageNum={pageNum} />}

            </div>
        </>
    );
};

export default ViewAppointmentSessions;